import React from 'react';
import Bar from '../comp/TopBar';
import ApiProduct from '../data/ApiProduct';
import ModalProductCreate from './modal/ModalProductCreate';
import ProductDetail from '../comp/product/ProductDetail';
import {Product} from '../type_doc';
import ProductCard from '../comp/product/ProductCard';
import {createBrowserHistory} from 'history';
import {loadUserInfo, PAGE_URLS} from './PageUtil';
import {Category, TypeUserLevel, UrlParams} from '../types';
import {ToastContainer} from 'react-toastify';
import {FaInfoCircle} from 'react-icons/fa';
import CategoryDetail from '../comp/product/CategoryDetail';
import Api from '../data/Api';

class State {
  selected?: Product;
  products: Product[] = [];
  categories: Category[] = [];

  contentType: 'pdf' | 'markdown' | 'image' = 'pdf';
  params: UrlParams = new UrlParams();
  loading = false;
  searchToken = '';
  searchKey = '';
  page = 1;
  size = 20;
  total = 0;

  viewCategory: boolean = false;

  checkPromotion: boolean = false;
  isSearchResult: boolean = false;
}

export default class PageProduct extends React.Component<any, State> {
  state = new State();

  componentDidMount() {
    const user = loadUserInfo();
    const access =
      user?.level === TypeUserLevel.admin ||
      user?.level === TypeUserLevel.operator;
    if (!access) {
      window.location.replace(PAGE_URLS.ACCESS_DENY);
    }

    const param = window.location.search;
    const urlParam = UrlParams.parse(param);
    this.loadCategories().catch(e => console.error(e));
    this.setState({params: urlParam}, () => {
      this.loadData().catch(e => console.error(e));
      const selectedProductSeq = urlParam.get('product_selected');
      if (selectedProductSeq !== undefined) {
        if (Array.isArray(selectedProductSeq)) {
          window.alert(`오류가 발생했습니다: 제품 중복`);
          return;
        }
        this.refreshSelectedProduct(parseInt(selectedProductSeq!));
      }
    });
  }

  async loadCategories() {
    const url = `/api/admin/category`;
    const result = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({categories: result.data.body});
    } else {
      window.alert(
        `데이터 로드 중 에러가 발생했습니다. ERROR CODE: ${result.data.code}`,
      );
    }
  }

  async loadData() {
    const {searchToken, checkPromotion, page, size} = this.state;
    const result = await ApiProduct.getProductList(searchToken, checkPromotion, page, size, true);
    this.setState({
      products: result.body,
      page: result.page || 1,
      total: result.total || 0,
      isSearchResult: searchToken !== ''
    });
  }
  refreshData() {
    this.loadData().catch(e => console.error(e));
    if (this.state.selected) {
      this.refreshSelectedProduct(this.state.selected.seq);
    }
  }

  refreshSelectedProduct(seq: number) {
    this.setState({loading: true});
    ApiProduct.getProduct(seq)
      .then(result => {
        const selectedProduct = Object.assign(new Product(), result.body);
        this.setState({selected: selectedProduct});
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.setState({loading: false});
      });
  }

  onClickViewCategory() {
    this.setState({viewCategory: true});
  }

  onSelectProduct(seq: number) {
    const {params} = this.state;
    params.clear();
    params.set('product_selected', `${seq}`);
    this.setState({params: params, viewCategory: false}, () => {
      const product = params.get('product_selected');
      if (Array.isArray(product)) {
        window.alert(`오류가 발생했습니다: 제품 중복.`);
        return;
      }
      this.refreshSelectedProduct(seq);
    });

    const history = createBrowserHistory();
    history.push({
      pathname: PAGE_URLS.PRODUCT,
      search: params.getUrlParam(),
    });
  }
  onChangeSearchKey(event: any) {
    this.setState({searchKey: event.target.value});
  }
  onClickSearch() {
    this.setState({searchToken: this.state.searchKey}, () => {
      this.loadData().catch(e => console.error(e));
    });
  }
  onClickSearchInit() {
    this.setState({searchToken: ''}, () => {
      this.loadData().catch(e => console.error(e));
    });
  }
  onClickPage(event: any) {
    const page = event.target.value;
    this.setState({page: page}, () => {
      this.loadData().catch(e => console.error(e));
    });
  }

  updated(seq: number) {
    this.loadData().catch(e => console.error(e));
    this.loadCategories().catch(e => console.error(e));

    this.state.products.forEach(value => {
      if (value.seq === seq) {
        this.onSelectProduct(seq);
      }
    });
  }

  onChangeSearchPromotion() {
    this.setState({checkPromotion: !this.state.checkPromotion},
        () => this.loadData().catch(e => console.error(e)));
  }

  render() {
    const {selected, products, categories, searchKey, viewCategory, isSearchResult} =
      this.state;
    let productList : JSX.Element[] = [];
    if (products && products.length > 0) {
      productList = products.map((value, index) => {
        return (
            <ProductCard
                selected={this.state.selected?.seq === value.seq}
                key={index}
                product={value}
                onClick={this.onSelectProduct.bind(this)}
            />
        );
      });
    }
    const pageCount = this.state.total / this.state.size + 1;
    const pageButtons = [];
    for (let i = 1; i <= pageCount; i++) {
      pageButtons.push(
        <button
          key={i}
          type="button"
          className={`btn ${i === this.state.page ? 'btn-secondary' : 'btn-outline-secondary'}`}
          value={i}
          onClick={this.onClickPage.bind(this)}>
          {i}
        </button>,
      );
    }

    return (
      <>
        <Bar menu={'product'} />
        <div className={'sp-body-col d-flex'}>
          <div className={'sp-product-list pt-3'}>
            <div
                className={'d-flex justify-content-between  mb-3'}
                style={{paddingLeft: 10, paddingRight: 10}}>
              <h5>제품 관리</h5>
              <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example">
                <button
                    type={'button'}
                    className={'btn btn-outline-primary btn-sm'}
                    onClick={this.onClickViewCategory.bind(this)}>
                  카테고리 관리
                </button>
                <button
                    type={'button'}
                    className={'btn btn-primary btn-sm'}
                    data-bs-toggle="modal"
                    data-bs-target="#productCreate">
                  제품 등록
                </button>
                <a
                    className="btn btn-outline-primary btn-sm"
                    type="button"
                    href={
                      'https://tree-scar-573.notion.site/5c3204c5d740420da48df1e2ed342df7?pvs=4'
                    }
                    target={'__blank'}>
                  <FaInfoCircle size={16} className={'sp-manual'}/>
                </a>
              </div>
            </div>
            <div className="input-group input-group-sm mb-3 p-1">
              <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="검색"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  value={searchKey}
                  onChange={this.onChangeSearchKey.bind(this)}
              />
              <button
                  className="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="button-addon2"
                  onClick={this.onClickSearch.bind(this)}>
                검색
              </button>
              <button
                  className="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="button-addon2"
                  disabled={!isSearchResult}
                  onClick={this.onClickSearchInit.bind(this)}>
                초기화
              </button>
            </div>
            <div className="form-check mb-3 ms-1">
              <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={this.state.checkPromotion}
                  onChange={this.onChangeSearchPromotion.bind(this)}
              />
              <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault">
                프로모션 제품 조회
              </label>
            </div>
            <div className={'mb-3'}>{productList}</div>
            <div className={'d-flex justify-content-center'}>
              <div className="btn-group" role="group" aria-label="First group">
                {pageButtons}
              </div>
            </div>
          </div>
          <div className={'sp-product-detail'}>
            {viewCategory ? (
                <CategoryDetail
                    categories={categories}
                    onChanged={this.loadCategories.bind(this)}
                />
            ) : selected ? (
                this.state.loading ? (
                    <div
                        className={'d-flex justify-content-center align-items-center'}
                        style={{height: 300}}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                ) : (
                    <ProductDetail
                        product={selected}
                  categories={categories}
                  onUpdate={this.updated.bind(this)}
                />
              )
            ) : (
              <div
                className={
                  'd-flex justify-content-center align-items-center h-100'
                }>
                <img src={'/assets/product.png'} width={600} alt={'product'} />
              </div>
            )}
          </div>
          <div className={'row'}></div>
        </div>
        <ModalProductCreate onChanged={this.refreshData.bind(this)} />
        <ToastContainer
          position="top-right" // 알람 위치 지정
          autoClose={3000} // 자동 off 시간
          hideProgressBar={false} // 진행시간바 숨김
          closeOnClick // 클릭으로 알람 닫기
          rtl={false} // 알림 좌우 반전
          pauseOnFocusLoss // 화면을 벗어나면 알람 정지
          draggable // 드래그 가능
          pauseOnHover // 마우스를 올리면 알람 정지
          theme="light"
        />
      </>
    );
  }
}
