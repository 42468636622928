import React from 'react';
import Business, {TypeBusinessStatus} from '../type_doc';
import ModalBusinessProcessQuotation from '../pages/modal/ModalBusiness/ModalBusinessProcessQuotation';
import ModalContainer, {TypeModal} from '../pages/modal/ModalContainer';
import PdfViewer from '../comp/PdfViewer';
import {numberWithCommas} from '../data/DataUtils';
import {PAGE_URLS} from '../pages/PageUtil';
import Api from '../data/Api';

interface Props {
  business: Business;
  onChanged: () => void;
}
export default class DocQuotation extends React.Component<Props, any> {
  state = {
    exist: false,
  };
  async checkDocExist() {
    const {business} = this.props;
    const quotation = business.quotation[0];

    const url = `/api/admin/biz/${business.docSeq}/quote?path=${quotation.file}`;
    try {
      await Api.head(url);
      this.setState({exist: true});
    } catch (err) {
      this.setState({exist: false});
    }
  }

  componentDidMount() {
    this.checkDocExist().catch(e => console.error(e));
  }

  async onClickRejectOrder() {
    if (this.props.business === undefined) return false;

    const result = await this.props.business.rejectOrder();
    if (result) {
      this.props.onChanged();
    }
    return result !== undefined;
  }
  renderDoc() {
    const {business} = this.props;
    const quotation = business.quotation[0];
    if (quotation === undefined) return <></>;

    const url = `/api/admin/biz/${business.docSeq}/quote?path=${quotation.file}`;
    console.log('url:  ' + url);
    switch (quotation.fileType) {
      case 'image/gif':
        return <img src={url} alt={''} />;
      case 'application/pdf':
        return <PdfViewer url={url} />;
      case 'text/html':
        if (this.state.exist) {
          return (
            <iframe
              src={'https://' + window.location.host + url}
              width={'100%'}
              height={500}
              style={{border: 1}}
            />
          );
        } else {
          return (
            <div style={{padding: 30}}>
              <strong>파일을 불러오는데 실패했습니다.</strong>
            </div>
          );
        }
      default:
        return <></>;
    }
  }
  render() {
    const {business} = this.props;
    const quota = business.quotation;
    let disabled = false;
    switch (this.props.business.bizStep) {
      case TypeBusinessStatus.initial:
      case TypeBusinessStatus.quoteReq:
        disabled = true;
        break;
      case TypeBusinessStatus.quoteRes:
        disabled = false;
        break;
      case TypeBusinessStatus.supply:
      case TypeBusinessStatus.calculate:
      case TypeBusinessStatus.close:
      case TypeBusinessStatus.done:
      case TypeBusinessStatus.cancel:
        disabled = true;
        break;
    }

    return (
      <div className={'card'}>
        <div className={'card-header d-flex justify-content-between'}>
          <span>견적서</span>
          <div className="btn-group" role="group" aria-label="Basic example">
            <a
              className={'btn btn-sm btn-outline-primary'}
              href={`${PAGE_URLS.HTML_VIEWER}?business=${business.docSeq}&doc=${business.quotation[0].file}`}
              // href={`/api/admin/biz/${business.docSeq}/quote?path=${business.quotation[0].file}`}
              target={'_blank'}
              rel="noreferrer">
              출력
            </a>
            <button
              className={'btn btn-sm btn-outline-danger'}
              disabled={disabled}
              data-bs-toggle="modal"
              data-bs-target="#processQuotation">
              견적 수정
            </button>
            <button
              className={'btn btn-sm btn-outline-danger'}
              disabled={disabled}
              data-bs-toggle="modal"
              data-bs-target="#rejectOrder">
              견적 재 발행
            </button>
          </div>
        </div>
        <div className={'card-body card-quotation'}>
          <div className={'input-group input-group-sm mb-3'}>
            <span className={'input-group-text'}>공급가</span>
            <input
              className={'form-control form-control-sm'}
              value={numberWithCommas(quota[0].priceSupply || 0)}
            />
            <span className={'input-group-text'}>세금</span>
            <input
              className={'form-control form-control-sm'}
              value={numberWithCommas(quota[0].priceTax || 0)}
            />
          </div>
          {this.renderDoc()}
        </div>
        <ModalBusinessProcessQuotation
          onChanged={() => {
            this.props.onChanged();
          }}
          business={business}
        />
        <ModalContainer
          divId={'rejectOrder'}
          title={'견적 재 발행'}
          type={TypeModal.SAVE}
          onStore={this.onClickRejectOrder.bind(this)}>
          견적을 재 발행 하시겠습니까? 저장을 클릭하시면 처음 단계부터 다시
          진행합니다.
        </ModalContainer>
      </div>
    );
  }
}
