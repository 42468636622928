import axios from "axios";
import {PAGE_URLS, removeUserInfo} from "../pages/PageUtil";

export class ApiResp<T> {
    code = -1;
    reason?: string;
    page?: number;
    total?: number;
    body: T = {} as T;
}

const instance = axios.create({
    timeout: 10000,
});

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            // console.log('Receive 401 - sign out by force');
            removeUserInfo();
            window.location.href = PAGE_URLS.SIGN_IN;
            // window.alert('권한이 없습니다.');
        }
        return Promise.reject(error);
    },
);

export default instance;
