import Business, {Product, ProductTuple, Requirements} from '../../../type_doc';
import React from 'react';
import ModalContainer, {TypeModal} from '../ModalContainer';
import {FaMinusCircle} from 'react-icons/fa';
import {FormSearchSingle} from '../../../comp/FormSearchSingle';
import ApiProduct from '../../../data/ApiProduct';
import {SearchDict, User} from '../../../types';
import InputSearchUser from '../../../comp/InputSearchUser';
import InputSearchProduct from '../../../comp/InputSearchProduct';
import {numberWithCommas} from '../../../data/DataUtils';
import InputNumberWithComma from '../../../comp/InputNumberWithComma';

interface Props {
  business?: Business;
  onChanged: () => void;
}
class State {
  requirements: Requirements = new Requirements();
  products: ProductTuple[] = [];
  note = '';
}

export default class ModalBusinessListProduct extends React.Component<
  Props,
  State
> {
  state = new State();

  fillProducts() {
    const max = 5;
    const {business} = this.props;
    const len = business?.requirements.productList.length || 0;
    const productList: ProductTuple[] = new Array<ProductTuple>(
      len > max ? len : max,
    );
    const pad = max - len;
    business?.requirements.productList.forEach((value, index) => {
      productList[index] = value;
    });
    if (pad > 0) {
      for (let i = len; i < max; i++) {
        productList[i] = new ProductTuple();
      }
    }
    this.setState({
      products: productList,
      note: business?.requirements.note || '',
    });
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const pBusiness = prevProps.business;
    const nBusiness = this.props.business;
    if (nBusiness && nBusiness.docSeq !== pBusiness?.docSeq) {
      this.fillProducts();
    }
  }
  componentDidMount() {
    this.fillProducts();
  }

  onChangeProduct(seq: number, product: (ProductTuple | string)[]) {
    const {products} = this.state;
    const p = product[0];
    if (p === undefined) {
      products[seq] = new ProductTuple();
    } else if (typeof p === 'string') {
      products[seq].title = p;
    } else {
      products[seq] = p;
    }
    this.setState({products: products});
  }
  onChangeAmount(seq: number, data: number) {
    const {products} = this.state;
    products[seq].amount = data;
    products[seq].price = data * products[seq].unitPrice;
    this.setState({products: products});
  }
  onChangeUnitPrice(seq: number, data: number) {
    const {products} = this.state;
    products[seq].unitPrice = data;
    products[seq].price = data * products[seq].amount;
    this.setState({products: products});
  }
  onChangeNote(event: any) {
    this.setState({note: event.target.value});
  }

  async onEditProductList() {
    const {products, note} = this.state;
    if (this.props.business === undefined) return false;

    const data: ProductTuple[] = [];
    products.forEach(product => {
      if (product.title !== '' && product.amount > 0) {
        data.push(product);
      }
    });
    const requirements = new Requirements();
    requirements.productList = data;
    requirements.note = note;
    const result = await this.props.business.updateProductList(requirements);
    if (result) {
      this.props.onChanged();
    }
    return result !== undefined;
  }
  cancelEdit() {
    this.setState(
      {products: this.props.business?.requirements.productList || []},
      () => {
        this.fillProducts();
      },
    );
  }

  render() {
    const {products, note} = this.state;

    const input = products.map((value, index) => {
      return (
        <div key={index} className={'row'}>
          <div className={'col'}>
            <div className={'input-group input-group-sm'}>
              <InputSearchProduct
                onChange={product => {
                  this.onChangeProduct(index, product);
                }}
                single
                value={value}
                registered
              />
              <InputNumberWithComma
                style={{maxWidth: 50}}
                onChange={value => {
                  this.onChangeAmount(index, value);
                }}
                value={value.amount}
              />
              <input
                style={{maxWidth: 100}}
                type="text"
                className={'form-control form-control-sm text-end'}
                disabled
                onChange={event => {
                  this.onChangeUnitPrice(index, event.target.valueAsNumber);
                }}
                value={numberWithCommas(value.unitPrice)}
              />
              <input
                disabled
                style={{maxWidth: 100}}
                type="text"
                className={'form-control form-control-sm text-end'}
                value={numberWithCommas(value.price)}
              />
              <div
                className={'btn btn-sm mb-1'}
                onClick={() => {
                  if (products.length !== 1) {
                    const item = products;
                    item.splice(index, 1);
                    this.setState({products: item});
                  }
                }}>
                <FaMinusCircle />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <ModalContainer
        divId={'listProduct'}
        title={'물품 목록'}
        onStore={this.onEditProductList.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        type={TypeModal.EDIT}>
        <div>
          <div className={'container-fluid'}>
            <div className={'row mb-2'}>
              <div className={'col'}>
                <div className={'d-flex justify-content-end mb-2'}>
                  <div
                    className={'btn btn-sm btn-primary'}
                    onClick={() => {
                      const item = products;
                      item.push(new ProductTuple());
                      this.setState({products: item});
                    }}>
                    물품 추가
                  </div>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col'}>
                <div className={'input-group input-group-sm'}>
                  <div
                    className={'form-control form-control-sm'}
                    style={{borderStyle: 'none'}}>
                    물품
                  </div>
                  <div
                    className={'form-control form-control-sm text-end'}
                    style={{maxWidth: 50, borderStyle: 'none'}}>
                    수량
                  </div>
                  <div
                    className={'form-control form-control-sm text-end'}
                    style={{maxWidth: 100, borderStyle: 'none'}}>
                    단가
                  </div>
                  <div
                    className={'form-control form-control-sm text-end'}
                    style={{maxWidth: 100, borderStyle: 'none'}}>
                    합계
                  </div>
                  <div className={'btn btn-sm mb-1'}>
                    <FaMinusCircle color={'white'} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{flex: 1}}>{input}</div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label">
                요청사항
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                value={note}
                onChange={this.onChangeNote.bind(this)}></textarea>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
