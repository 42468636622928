import React from "react";
import Business from "../type_doc";
import ReactMarkdown from 'react-markdown';

interface Props {
    business: Business;
}

export default class DocBizAbstract extends React.Component<Props, any>{
    render() {
        const {business} = this.props;
        return (
            <>
                <div className={'card mb-3'}>
                    <div className={'card-header'}>사업 개요</div>
                    <div className={'card-body card-quotation'}>
                        <ReactMarkdown
                            components={{img: ({node, ...props}) => <img style={{maxWidth: '100%'}}{...props} alt=""/>}}>
                            {business.bizAbstract}
                        </ReactMarkdown>
                    </div>
                </div>
                {/*<DocProceeding business={business}/>*/}
            </>
        );
    }
}
