import React from 'react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {ChartUnit} from '../../types';
import Api, {ApiResp} from '../../data/Api';
import {AxiosResponse} from 'axios';
class State {
  data: ChartUnit[] = [];
}

interface Props {
  from?: Date;
  to?: Date;
}

export default class ChartBalance extends React.Component<Props, State> {
  state = new State();

  async _loadData() {
    const url = '/api/admin/dashboard/inout-stat';
    const result: AxiosResponse<ApiResp<ChartUnit[]>> = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({data: result.data.body});
    }
  }

  componentDidMount() {
    this._loadData().catch(e => console.error('Fail to load salesman chart'));
  }

  render() {
    return (
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <ComposedChart
          width={500}
          height={400}
          data={this.state.data}
          margin={{
            top: 10,
            right: 10,
            bottom: 0,
            left: 0,
          }}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" style={{fontSize: 8}} />
          <YAxis style={{fontSize: 8}} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="v1" name={'매입'} stroke="#731033" />
          <Line type="monotone" dataKey="v2" name={'매출'} stroke="#ff7300" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
