import React, {ChangeEvent} from 'react';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';
import {FaMinusCircle, FaPlusCircle, FaTimesCircle} from 'react-icons/fa';
import {Attachment, Category, Company} from '../types';
import InputSearchCompany from './InputSearchCompany';
import {IoCloseCircle} from 'react-icons/io5';
import {toast} from 'react-toastify';
import InputNumberWithComma from './InputNumberWithComma';
import {Product} from '../type_doc';

interface Props {
  seq: number;
  disabled?: boolean;
  title?: string;
  keyName: string;
  value: string;
  origin?: string;
  onUpdated: (seq: number) => void;
  prefix?: string;
  rows?: number;
  maxLength?: number;
  type?: 'number' | 'text';
}

export class ProductInput extends React.Component<Props, any> {
  state = {
    value: this.props.value,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any,
  ) {
    if (
      prevProps.seq !== this.props.seq ||
      prevProps.value !== this.props.value
    ) {
      this.setState({value: this.props.value});
    }
  }

  onChangeValue(value: string) {
    this.setState({value: value});
  }

  onClickCancel() {
    this.setState({value: this.props.value});
  }

  onClickUpdate() {
    const formData = new FormData();
    formData.set(this.props.keyName, this.state.value);
    const url = `/api/admin/product/${this.props.seq}`;
    Api.put(url, formData)
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          this.props.onUpdated(this.props.seq);
          toast(`${this.props.title}이 변경되었습니다.`);
        } else {
          console.error('!!!!!');
          window.alert(
            `${this.props.title} 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }

  needSave() {
    const sv = JSON.stringify(this.state.value);
    const pv = JSON.stringify(this.props.value);
    const ov = JSON.stringify(this.props.origin);
    // console.log('-------------------------------------------------');
    // console.log('>>>> S ' + sv);
    // console.log('>>>> P ' + pv);
    // console.log('>>>> O ' + ov);
    if (this.props.origin !== undefined) {
      return sv !== ov;
    } else {
      return sv !== pv;
    }
  }

  render() {
    const ns = this.needSave();
    return (
      <div className={'mb-2 row'}>
        {this.props.title && (
          <div className={'col-sm-3 col-form-label'}>
            {this.props.title}
            {this.props.maxLength && (
              <span>
                <small
                  className={'text-muted'}
                  style={{
                    fontSize: '0.75rem',
                  }}>{` (최대 ${this.props.maxLength}자 입력 가능합니다.)`}</small>
              </span>
            )}
          </div>
        )}
        <div className={this.props.title ? 'col-sm-9' : 'col-sm-12'}>
          <div className="input-group input-group-sm mb-3">
            {this.props.prefix && (
              <span className="input-group-text" id="basic-addon3">
                {this.props.prefix}
              </span>
            )}
            {this.props.type === 'number' ? (
              <InputNumberWithComma
                disabled={this.props.disabled}
                className={ns ? 'is-invalid' : undefined}
                placeholder={this.props.title + '을(를) 입력하세요'}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                value={parseInt(this.state.value)}
                onChange={value => {
                  this.onChangeValue(value.toString());
                }}
              />
            ) : (
              <input
                type={this.props.type !== undefined ? this.props.type : 'text'}
                disabled={this.props.disabled}
                className={
                  'form-control form-control-sm ' + (ns ? 'is-invalid' : '')
                }
                placeholder={this.props.title + '을(를) 입력하세요'}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                value={this.state.value}
                maxLength={this.props.maxLength}
                onChange={event => {
                  this.onChangeValue(event.target.value);
                }}
              />
            )}
            <button
              className={'btn btn-outline-primary btn-sm'}
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickCancel.bind(this)}>
              취소
            </button>
            <button
              className={
                'btn btn-sm ' + (ns ? 'btn-primary' : 'btn-outline-primary')
              }
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickUpdate.bind(this)}>
              저장
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export class ProductText extends React.Component<Props, any> {
  state = {
    value: this.props.value,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any,
  ) {
    if (prevProps.seq !== this.props.seq) {
      this.setState({value: this.props.value});
    }
  }

  onChangeValue(event: any) {
    this.setState({value: event.target.value});
  }

  onClickCancel() {
    this.setState({value: this.props.value});
  }

  onClickUpdate() {
    const formData = new FormData();
    formData.set(this.props.keyName, this.state.value);
    const url = `/api/admin/product/${this.props.seq}`;
    Api.put(url, formData)
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          this.props.onUpdated(this.props.seq);
          toast(`${this.props.title}이(가) 변경되었습니다.`);
        } else {
          console.error('!!!!!');
          window.alert(
            `${this.props.title} 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }

  render() {
    const ns =
      JSON.stringify(this.state.value) !== JSON.stringify(this.props.value);
    return (
      <div className={'mb-2 row'}>
        {this.props.title && (
          <div className={'col-sm-3 col-form-label'}>
            {this.props.title}
            {this.props.maxLength && (
              <span>
                <small
                  className={'text-muted'}
                  style={{
                    fontSize: '0.75rem',
                  }}>{` (최대 ${this.props.maxLength}자 입력 가능합니다.)`}</small>
              </span>
            )}
          </div>
        )}
        <div className={this.props.title ? 'col-sm-9' : 'col-sm-12'}>
          <div className="input-group input-group-sm mb-3">
            {this.props.prefix && (
              <span className="input-group-text" id="basic-addon3">
                {this.props.prefix}
              </span>
            )}
            <textarea
              className={
                'form-control form-control-sm ' + (ns ? 'is-invalid' : '')
              }
              placeholder={this.props.title + '을(를) 입력하세요'}
              value={this.state.value}
              rows={this.props.rows}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled}
              onChange={this.onChangeValue.bind(this)}
            />
            <button
              className={'btn btn-sm btn-outline-primary'}
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickCancel.bind(this)}>
              취소
            </button>
            <button
              className={
                'btn btn-sm ' + (ns ? 'btn-primary' : 'btn-outline-primary')
              }
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickUpdate.bind(this)}>
              저장
            </button>
          </div>
        </div>
      </div>
    );
  }
}
interface KeywordProps {
  seq: number;
  disabled?: boolean;
  title?: string;
  keyName: string;
  value: string[];
  origin?: string[];
  max?: number;
  onUpdated: (seq: number) => void;
}

class KeywordState {
  values: string[] = [];
  workValue: string = '';
}

export class ProductKeywords extends React.Component<
  KeywordProps,
  KeywordState
> {
  state = new KeywordState();

  initState() {
    // FIXME: needsave 해서 적용할지말지 결정
    this.setState({
      values: [...this.props.value],
      workValue: '',
    });
  }

  componentDidUpdate(prevProps: Readonly<KeywordProps>) {
    if (
      prevProps.seq !== this.props.seq ||
      JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)
    ) {
      this.initState();
    }
  }

  onChangeValue(event: any) {
    this.setState({workValue: event.target.value});
  }

  onClickCancel() {
    if (this.props.origin !== undefined) {
      this.setState({
        values: [...this.props.origin],
        workValue: '',
      });
    } else {
      this.initState();
    }
  }

  onKeyPress(event: any) {
    const {values} = this.state;
    if (event.key === 'Tab' && this.state.workValue.length > 0) {
      event.preventDefault();
      values.push(this.state.workValue);
      this.setState({values: values, workValue: ''});
    }
  }

  onClickUpdate() {
    const {values} = this.state;
    const formData = new FormData();
    for (const value of values) {
      formData.append(this.props.keyName, value);
    }
    const url = `/api/admin/product/${this.props.seq}`;
    Api.put(url, formData)
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          this.props.onUpdated(this.props.seq);
          toast(`${this.props.title}이(가) 변경되었습니다.`);
        } else {
          console.error('!!!!!');
          window.alert(
            `${this.props.title} 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }

  removeBadge(index: number) {
    const {values} = this.state;
    if (values === undefined || this.props.disabled) return;
    values.splice(index, 1);
    this.setState({values: values});
  }

  needSave() {
    const sv = JSON.stringify(this.state.values);
    const pv = JSON.stringify(this.props.value);
    const ov = JSON.stringify(this.props.origin);
    // console.log('-------------------------------------------------');
    // console.log('>>>> S ' + sv);
    // console.log('>>>> P ' + pv);
    // console.log('>>>> O ' + ov);
    if (this.props.origin !== undefined) {
      return sv !== ov;
    } else {
      return sv !== pv;
    }
  }

  render() {
    const {values} = this.state;
    const {max} = this.props;
    const ns = this.needSave();
    const badge = values?.map((value, index) => {
      if (value.length === 0) {
        return <></>;
      }
      return (
        <h6 key={index} style={{paddingTop: 3}}>
          <span className={`badge text-bg-success me-2`}>
            <span
              className={'sp-form-badge'}
              onClick={() => {
                this.removeBadge(index);
              }}>
              <IoCloseCircle size={15} style={{marginRight: 6}} />
            </span>
            <span>{value}</span>
          </span>
        </h6>
      );
    });

    const hidden = max === undefined ? false : max <= values.length;
    return (
      <div className={'mb-2 row'}>
        {this.props.title && (
          <div className={'col-sm-3 col-form-label'}>
            {this.props.title}
            {this.props.max && (
              <span>
                <small
                  className={'text-muted'}
                  style={{
                    fontSize: '0.75rem',
                  }}>{` (최대 ${this.props.max}개 입력 가능합니다.)`}</small>
              </span>
            )}
          </div>
        )}
        <div className={this.props.title ? 'col-sm-9' : 'col-sm-12'}>
          <div className="input-group input-group-sm mb-3">
            <div
              className={
                'form-control form-control-sm d-flex ' +
                (ns ? 'is-invalid' : '')
              }
              style={{
                overflowX: 'auto',
                backgroundColor: this.props.disabled ? '#e9ecef' : undefined,
                padding: 0,
              }}>
              <div className={'d-flex'}>{badge}</div>
              <input
                hidden={hidden}
                disabled={this.props.disabled}
                type={'text'}
                style={{borderStyle: 'none'}}
                className={'form-control form-control-sm'}
                placeholder={this.props.title + '을(를) 입력하세요'}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                value={this.state.workValue}
                onKeyDown={this.onKeyPress.bind(this)}
                onChange={this.onChangeValue.bind(this)}
              />
            </div>
            <button
              className={'btn btn-sm btn-outline-primary'}
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickCancel.bind(this)}>
              취소
            </button>
            <button
              className={
                'btn btn-sm ' + (ns ? 'btn-primary' : 'btn-outline-primary')
              }
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickUpdate.bind(this)}>
              저장
            </button>
          </div>
        </div>
      </div>
    );
  }
}

interface SearchCompanyProps {
  seq: number;
  disabled?: boolean;
  title?: string;
  keyName: string;
  value: Company | string;
  onUpdated: (seq: number) => void;
  onChangeCompany?: (company?: Company) => void;
  prefix?: string;
}
class State {
  value: string = '';
  company?: Company | string;
}
export class ProductInputSearchCompany extends React.Component<
  SearchCompanyProps,
  State
> {
  state = new State();

  initState() {
    const {value} = this.props;
    if (value === undefined) {
      return;
    }
    this.setState({
      value: '',
      company:
        typeof value === 'string' ? value : Object.assign(new Company(), value),
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.seq !== this.props.seq) {
      this.initState();
    }
  }

  componentDidMount() {
    this.initState();
  }

  onClickCancel() {
    this.initState();
  }

  onClickUpdate() {
    const formData = new FormData();
    if (this.state.company === undefined) {
      formData.set(this.props.keyName, '');
    } else if (typeof this.state.company === 'string') {
      formData.set(this.props.keyName, this.state.company);
    } else {
      formData.set(this.props.keyName, (this.state.company as Company).name);
    }
    const url = `/api/admin/product/${this.props.seq}`;
    Api.put(url, formData)
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          this.props.onUpdated(this.props.seq);
          toast(`${this.props.title}이(가) 변경되었습니다.`);
        } else {
          console.error('!!!!!');
          window.alert(
            `${this.props.title} 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }

  onChangeCompany(company: Company | string | undefined) {
    this.setState({
      company: company,
      value: '',
    });
    if (this.props.onChangeCompany) {
      if (typeof company === 'string') {
        console.log('Set unregistered company : ' + company);
      } else if (company === undefined) {
        console.log('company removed ');
      } else {
        const c = Object.assign(new Company(), company);
        console.log('Company changed to ' + JSON.stringify(c));
        this.props.onChangeCompany(c);
      }
    }
  }

  needSave() {
    const sv = JSON.stringify(this.state.company);
    const pv = JSON.stringify(this.props.value);
    // console.log('-------------------------------------------------');
    // console.log('>>>> S ' + sv);
    // console.log('>>>> P ' + pv);
    return sv !== pv;
  }

  render() {
    const ns = this.needSave();
    return (
      <div className={'mb-2 row'}>
        {this.props.title && (
          <div className={'col-sm-3 col-form-label'}>{this.props.title}</div>
        )}
        <div className={this.props.title ? 'col-sm-9' : 'col-sm-12'}>
          <div className="input-group mb-3">
            <InputSearchCompany
              value={this.state.company}
              disabled={this.props.disabled}
              invalid={ns}
              onChange={company => {
                if (Array.isArray(company)) {
                  if (company.length === 1) {
                    this.onChangeCompany(company[0]);
                  } else {
                    this.onChangeCompany(undefined);
                  }
                }
              }}
              single
            />
            <button
              className={'btn btn-outline-primary'}
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickCancel.bind(this)}>
              취소
            </button>
            <button
              className={'btn ' + (ns ? 'btn-primary' : 'btn-outline-primary')}
              disabled={!ns}
              type="button"
              id="button-addon2"
              onClick={this.onClickUpdate.bind(this)}>
              저장
            </button>
          </div>
        </div>
      </div>
    );
  }
}

interface ArrayProps {
  seq: number;
  disabled?: boolean;
  title: string;
  keyName: string;
  value: string[];
  origin?: string[];
  onUpdated: (seq: number) => void;
  max?: number;
}

export class ProductArrayInput extends React.Component<ArrayProps, any> {
  state = {
    value: [''],
  };

  componentDidMount() {
    if (Array.isArray(this.props.value) && this.props.value.length > 0) {
      this.setState({value: this.props.value});
    }
  }

  onChangeData(seq: number, data: string) {
    const {value} = this.state;
    value[seq] = data;
    this.setState({value: value});
  }

  onClickUpdate() {
    const formData = new FormData();
    formData.set(this.props.keyName, JSON.stringify(this.state.value));
    const url = `/api/admin/product/${this.props.seq}`;
    Api.put(url, formData)
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          this.props.onUpdated(this.props.seq);
          toast(`${this.props.title}이(가) 변경되었습니다.`);
        } else {
          console.error('!!!!!');
          window.alert(
            `${this.props.title} 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }

  render() {
    const icon = this.state.value.map((value, index) => {
      if (index === 0) {
        if (this.props.max === undefined) {
          return (
            <div
              key={index}
              className={'btn btn-sm d-block mb-1'}
              onClick={() => {
                const item = this.state.value;
                item.push('');
                this.setState({value: item});
              }}>
              <FaPlusCircle />
            </div>
          );
        } else if (this.state.value.length <= this.props.max) {
          return (
            <div
              key={index}
              className={'btn btn-sm d-block mb-1'}
              onClick={() => {
                const item = this.state.value;
                item.push('');
                this.setState({value: item});
              }}>
              <FaPlusCircle />
            </div>
          );
        } else {
          return (
            <div key={index} className={'btn btn-sm d-block mb-1'}>
              &nbsp;
            </div>
          );
        }
      }
      return (
        <div
          key={index}
          className={'btn btn-sm d-block mb-1'}
          onClick={() => {
            const item = this.state.value;
            item.splice(index, 1);
            this.setState({value: item});
          }}>
          <FaMinusCircle />
        </div>
      );
    });

    const input = this.state.value.map((value, index) => {
      return (
        <input
          key={index}
          disabled={this.props.disabled}
          type="text"
          className={'form-control '}
          placeholder={this.props.title + '을(를) 입력하세요'}
          aria-label="Recipient's username"
          aria-describedby="button-addon2"
          onChange={event => {
            this.onChangeData(index, event.target.value);
          }}
          value={this.state.value[index]}
        />
      );
    });
    const ns =
      JSON.stringify(this.state.value) !== JSON.stringify(this.props.value);

    return (
      <div className={'mb-2 row'}>
        <div
          className={'col-sm-3 col-form-label d-flex justify-content-between'}>
          {this.props.title}
          <div>{icon}</div>
        </div>
        <div className={'col-sm-9'}>
          <div className="input-group mb-3">
            <div style={{flex: 1}}>{input}</div>
            <button
              className={'btn btn-outline-primary'}
              disabled={!ns}
              type="button"
              id="button-addon2">
              취소
            </button>
            <button
              className={'btn ' + (ns ? 'btn-primary' : 'btn-outline-primary')}
              disabled={!ns}
              onClick={this.onClickUpdate.bind(this)}
              type="button"
              id="button-addon2">
              저장
            </button>
          </div>
        </div>
      </div>
    );
  }
}

interface FileProps {
  seq: number;
  title?: string;
  keyName: string;
  value: Attachment[];
  origin?: Attachment[];
  onUpdated: (seq: number) => void;
  accept?: string;
  max?: number;
  disabled?: boolean;
  maxSize?: number;
}

export class ProductFile extends React.Component<FileProps, any> {
  state = {
    value: new Array<string>(),
  };

  componentDidMount() {
    if (Array.isArray(this.props.value) && this.props.value.length > 0) {
      this.setState({value: this.props.value});
    }
  }

  onChangeFile(event: any) {
    const selectedFile = event.target.files[0];
    const fileSizeInBytes = selectedFile.size;
    const {maxSize} = this.props;
    if (maxSize && maxSize < fileSizeInBytes) {
      window.alert('최대 허용 사이즈를 초과했습니다.');
      event.target.value = null;
      return;
    }
    console.log(event.target.files);
    const file = event.target.files[0] as File;
    const url = `/api/admin/product/${this.props.seq}/`;
    const formData = new FormData();
    formData.append(this.props.keyName, file);
    Api.put(url, formData)
      .then((result: AxiosResponse<ApiResp<boolean>>) => {
        if (result.data.code === 0) {
          this.props.onUpdated(this.props.seq);
          if (this.props.title) {
            toast(`${this.props.title}이 추가되었습니다.`);
          } else {
            toast('파일을 추가하였습니다.');
          }
        }
      })
      .catch(e => {
        console.error('FAIL to upload : ' + e);
      });
  }

  onDeleteFile(value: Attachment) {
    Api.delete(value.path)
      .then((result: AxiosResponse<ApiResp<boolean>>) => {
        if (result.data.code === 0) {
          this.props.onUpdated(this.props.seq);
          toast(`${this.props.title}이 삭제되었습니다.`);
        }
      })
      .catch(e => {
        console.error('FAIL to upload : ' + e);
      });
  }

  render() {
    const {value} = this.props;
    const files = value.map((value, index) => {
      return (
        <div key={index} className={'mb-1'}>
          <span className={'badge text-bg-secondary'}>
            <span
              className={'btn btn-sm'}
              onClick={() => {
                this.onDeleteFile(value);
              }}
              style={{margin: 0, padding: 0, lineHeight: 0}}>
              <FaTimesCircle className={'me-3'} color={'white'} />
            </span>
            {value.name}
          </span>
        </div>
      );
    });
    return (
      <div className={'mb-3 row'}>
        {this.props.title && (
          <div className={'col-sm-3 col-form-label'}>
            {this.props.title}
            {this.props.maxSize && (
              <span>
                <small
                  className={'text-muted'}
                  style={{
                    fontSize: '0.75rem',
                  }}>{` (최대 ${(this.props.maxSize / 1024 / 1024).toFixed()}MB 까지 업로드 가능합니다.)`}</small>
              </span>
            )}
          </div>
        )}
        <div className={this.props.title ? 'col-sm-9' : 'col-sm-12'}>
          <div className="input-group input-group-sm mb-1">
            <div style={{flex: 1}}>
              <input
                type="file"
                accept={this.props.accept}
                className={'form-control form-control-sm'}
                placeholder={this.props.title + ' 파일을 등록하세요'}
                onChange={this.onChangeFile.bind(this)}
                disabled={this.props.disabled}
              />
            </div>
          </div>
          {files}
        </div>
      </div>
    );
  }
}

interface SelectProps {
  seq: number;
  disabled?: boolean;
  title: string;
  keyName: string;
  value: string;
  origin: string;
  options: string[];
  onUpdated: (seq: number) => void;
}

export class ProductSelect extends React.Component<SelectProps, any> {
  state = {
    value: new Array<string>(),
  };

  componentDidMount() {
    if (Array.isArray(this.props.value) && this.props.value.length > 0) {
      this.setState({value: this.props.value});
    }
  }

  onChangeValue() {
    // this.props.onUpdated(this.props.seq);
    console.log('onChange');
  }

  render() {
    const ns =
      JSON.stringify(this.state.value) !== JSON.stringify(this.props.value);
    const options = this.props.options.map((value, index) => {
      return (
        <option key={index} value={value}>
          {value}
        </option>
      );
    });
    return (
      <div className={'mb-3 row'}>
        <div
          className={'col-sm-3 col-form-label d-flex justify-content-between'}>
          {this.props.title}
        </div>
        <div className={'col-sm-9'}>
          <div className="input-group mb-1">
            <div style={{flex: 1}}>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={this.onChangeValue.bind(this)}
                disabled={this.props.disabled}
                value={this.props.value}>
                {options}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface SelectCategoryProps {
  title: string;
  disabled?: boolean;
  product: Product;
  categories: Category[];
  value?: Category;
  onSelect: (category: Category) => void;
  onUpdated: (seq: number) => void;
}

export class ProductCategorySelect extends React.Component<
  SelectCategoryProps,
  any
> {
  state = {
    selected: this.props.value,
  };

  componentDidUpdate(
    prevProps: Readonly<SelectCategoryProps>,
    prevState: Readonly<any>,
    snapshot?: any,
  ) {
    if (prevProps.value?.seq !== this.props.value?.seq) {
      this.setState({selected: this.props.value});
    }
  }

  onChangeValue(event: ChangeEvent<HTMLSelectElement>) {
    const {categories} = this.props;
    const seq = Number(event.target.value);
    for (const cate of categories) {
      if (cate.seq === seq) {
        this.setState({selected: cate});
        return;
      }
    }
    console.error('Category not exists in loaded data : ' + seq);
  }

  async onClickUpdate() {
    if (!this.state.selected) {
      return;
    }

    const {product} = this.props;
    const url = `/api/admin/product/${product.seq}/category`;
    const formData: FormData = new FormData();
    formData.set('categorySeq', String(this.state.selected.seq));
    const result = await Api.put(url, formData);
    if (result.data.code === 0) {
      this.props.onUpdated(product.seq);
    } else {
      window.alert(`데이터 로드에 실패했습니다. CODE(${result.data.code})`);
    }
  }

  async onClickDelete() {
    if (!this.state.selected) {
      return;
    }

    const {product} = this.props;
    const url = `/api/admin/product/${product.seq}/category`;
    const result = await Api.delete(url);
    if (result.data.code === 0) {
      this.props.onUpdated(product.seq);
    } else {
      window.alert(`데이터 로드에 실패했습니다. CODE(${result.data.code})`);
    }
  }

  render() {
    const options = [];
    let i = 0;
    options.push(
      <option key={i++} value={'0'}>
        선택하세요
      </option>,
    );
    options.push(
      <option key={i++} value={'0'}>
        --------------------------------
      </option>,
    );

    for (const value of this.props.categories) {
      options.push(
        <option
          key={i++}
          value={value.seq}
          selected={value.seq === this.props.value?.seq}>
          {value.category}
        </option>,
      );
    }

    const ns = this.props.value?.seq !== this.state.selected?.seq;

    return (
      <div className={'mb-3 row'}>
        <div
          className={'col-sm-3 col-form-label d-flex justify-content-between'}>
          {this.props.title}
        </div>
        <div className={'col-sm-9'}>
          <div className="input-group input-group-sm mb-1">
            <div style={{flex: 1}}>
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                onChange={this.onChangeValue.bind(this)}
                disabled={this.props.disabled}
                value={this.state.selected?.seq}>
                {options}
              </select>
            </div>
            <button
              className={'btn btn-sm btn-outline-primary'}
              disabled={!ns}
              type="button"
              id="button-addon1">
              취소
            </button>
            <button
              className={
                'btn btn-sm ' + (ns ? 'btn-primary' : 'btn-outline-primary')
              }
              disabled={!ns}
              onClick={this.onClickUpdate.bind(this)}
              type="button"
              id="button-addon2">
              저장
            </button>
            {this.props.value && (
              <button
                className={'btn btn-sm btn-danger'}
                disabled={this.props.disabled}
                onClick={this.onClickDelete.bind(this)}
                type="button"
                id="button-addon3">
                삭제
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * 2024.05.30 [김응규]
 *  - 신규 추가
 */
interface ProductPromotionProps {
  selected: Product;
  disabled: boolean;
  onUpdated: (seq: number) => void;
  maxLength?: number;
}
/**
 * 2024.05.30 [김응규]
 *  - 신규 추가
 */
export class ProductPromotionForm extends React.Component<ProductPromotionProps, any> {
  state = {
    promotionContent: this.props.selected.promotionContent
  }

  componentDidUpdate(prevProps: Readonly<ProductPromotionProps>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.selected.promotionContent !== prevProps.selected.promotionContent) {
      this.setState({promotionContent: this.props.selected.promotionContent});
    }
  }

  onTogglePromotion() {
    const {selected} = this.props;
    const formData = new FormData();
    const enable = !selected.inPromotion;
    console.log('enable : ' + enable);
    formData.set('enable', enable ? 'true' : 'false');
    Api.put(`/api/admin/product/${selected.seq}/promotion`, formData)
        .then((result: AxiosResponse<ApiResp<void>>) => {
          if (result.data.code === 0) {
            this.props.onUpdated(selected.seq);
            toast(`프로모션이 ${enable ? '활성화' : '취소'}되었습니다.`);
          }
        })
        .catch(e => {
          console.error('FAIL to upload : ' + e);
        });
    return true;
  }

  onChangeDesc(value: string) {
    this.setState({promotionContent: value});
  }

  onClickCancelDesc() {
    const {selected} = this.props;
    const value = selected.promotionContent ? selected.promotionContent : '';
    this.setState({promotionContent: value});
  }

  onClickStoreDesc() {
    const {selected} = this.props;
    const formData = new FormData();
    const content = this.state.promotionContent ? this.state.promotionContent : '';
    formData.set('content', content);
    Api.put(`/api/admin/product/${selected.seq}/promotion`, formData)
        .then((result: AxiosResponse<ApiResp<void>>) => {
          if (result.data.code === 0) {
            this.props.onUpdated(selected.seq);
            toast(`프로모션 설명이 추가되었습니다.`);
          }
        })
        .catch(e => {
          console.error('FAIL to upload : ' + e);
        });
    return true;
  }

  render() {
    const {selected, disabled} = this.props;
    const {promotionContent} = this.state;

    const ns= selected.promotionContent !== promotionContent;
    const display = selected.inPromotion ? '' : ' d-none';
    return (
        <>
          <div className={'mb-3 row'}>
            <div
                className={'col-sm-3 col-form-label d-flex justify-content-between'}>
              프로모션
            </div>
            <div className={'col-sm-9'}>
              <div className="input-group input-group-sm mb-1">
                <div className="form-check form-switch">
                  <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={selected.inPromotion}
                      disabled={disabled}
                      onChange={this.onTogglePromotion.bind(this)}
                  />
                  <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault">
                    {selected.inPromotion ? '프로모션 활성화' : '해당 없음'}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className={'mb-2 row' + display}>
            <div className={'col-sm-3 col-form-label'}>
              프로모션 설명
              <span>
                      <small
                          className={'text-muted'}
                          style={{fontSize: '0.75rem',}}>
                        {` (최대 ${this.props.maxLength}자 입력 가능합니다.)`}
                      </small>
                    </span>
            </div>
            <div className={'col-sm-9'}>
              <div className="input-group input-group-sm mb-3">
                <input
                    type={'text'}
                    disabled={this.props.disabled}
                    className={
                        'form-control form-control-sm ' + (ns ? 'is-invalid' : '')
                    }
                    placeholder={'프로모션 설명을(를) 입력하세요'}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={this.state.promotionContent}
                    maxLength={this.props.maxLength}
                    onChange={event => {
                      this.onChangeDesc(event.target.value);
                    }}
                />
                <button
                    className={'btn btn-sm btn-outline-primary'}
                    disabled={!ns}
                    type="button"
                    id="button-addon2"
                    onClick={this.onClickCancelDesc.bind(this)}>
                  취소
                </button>
                <button
                    className={
                        'btn btn-sm ' + (ns ? 'btn-primary' : 'btn-outline-primary')
                    }
                    disabled={!ns}
                    type="button"
                    id="button-addon2"
                    onClick={this.onClickStoreDesc.bind(this)}>
                  저장
                </button>
              </div>
            </div>
          </div>
        </>
    );
  }
}
