import * as React from 'react';
import TopBar from '../comp/TopBar';
import SideBarUser from '../comp/SideBarUser';
import {Breadcrumb} from '../comp/BreadCrumb';
import DataTable, {Api} from 'datatables.net-dt';
import ModalUserManagerCreate from './modal/ModalUserManagerCreate';
import ApiUser from '../data/ApiUser';
import {TypeUserLevel, User} from '../types';
import {renderToString} from 'react-dom/server';
import ModalUserManagerEdit from './modal/ModalUserManagerEdit';
import ModalUserManagerDelete from './modal/ModalUserManagerDelete';
import ModalUserManagerInvite from './modal/ModalUserManagerInvite';
import {loadUserInfo, PAGE_URLS} from './PageUtil';

class State {
  selected?: User;
  selectedName?: string;
  users: User[] = [];
}
export default class PageUserManager extends React.Component<any, State> {
  state = new State();

  managerTable?: Api<any>;

  onSelectTableRow(event: Event) {
    const target = event.currentTarget;
    if (target !== null) {
      const row = event.currentTarget as HTMLTableRowElement;
      if (row.classList.contains('selected')) {
        row.classList.remove('selected');
        this.setState({selected: undefined});
      } else {
        this.managerTable
          ?.rows('.selected')
          .nodes()
          .each(row => row.classList.remove('selected'));
        row.classList.add('selected');
        const uid = row.cells[0].innerText;
        this.state.users.filter(value => {
          if (value.uid === uid) {
            value.company = '';
            this.setState({selected: value, selectedName: value.name});
          }
        });
      }
    }
  }
  componentDidMount() {
    const user = loadUserInfo();
    const access =
      user?.level === TypeUserLevel.admin ||
      user?.level === TypeUserLevel.operator;
    if (!access) {
      window.location.replace(PAGE_URLS.ACCESS_DENY);
    }
    this.initManagerTable();
    this.loadData().catch(e => {
      window.alert(`데이터 로드 중 문제가 발생했습니다.`);
      return;
    });
  }
  async loadData() {
    const result = await ApiUser.getUserAdminList();
    if (result.code === 0) {
      this.setState({users: result.body, selected: undefined});
    } else {
      window.alert(
        `데이터 로드 중 에러가 발생했습니다. ERROR CODE: ${result.code}`,
      );
    }

    this.loadDataTable(result.body);
  }
  refreshData() {
    this.loadData().catch(e => {
      window.alert(`데이터 로드 중 문제가 발생했습니다.`);
      return;
    });
  }

  initManagerTable() {
    const element = document.getElementById('manager_list');
    if (element === null) {
      throw Error('Manager list not exist');
    } else {
      if (this.managerTable === undefined) {
        this.managerTable = new DataTable(element as HTMLTableElement, {});
        this.managerTable?.on(
          'click',
          'tbody tr',
          this.onSelectTableRow.bind(this),
        );
      }
    }
  }

  loadDataTable(users: User[]) {
    const table = this.managerTable;
    table?.rows().clear();
    for (const item of users) {
      const value = item as User;
      let badgeClass = '';
      let levelName = '';
      switch (value.level) {
        case TypeUserLevel.admin:
          badgeClass = 'text-bg-danger';
          levelName = '관리자';
          break;
        case TypeUserLevel.operator:
          badgeClass = 'text-bg-info';
          levelName = '운용자';
          break;
        case TypeUserLevel.business:
          badgeClass = 'text-bg-success';
          levelName = '사업담당자';
          break;
      }
      const work =
        value.works > 0
          ? renderToString(
              <span className="badge text-bg-warning">{value.works}</span>,
            )
          : ' ';

      const level = (
        <td>
          <div className={`badge rounded-pill ${badgeClass}`}>{levelName}</div>
        </td>
      );

      table?.row.add([
        value.uid,
        value.name,
        work,
        renderToString(level),
        value.mail,
        value.tel,
        value.description,
      ]);
    }
    table?.draw(false);
  }

  render() {
    const {selected, selectedName} = this.state;
    return (
      <div>
        <TopBar menu={'user'} />
        <SideBarUser />
        <div className={'sp-right'}>
          <Breadcrumb path={['user', 'manager']} />
          <>
            <div
              className={
                'card-datatable-header d-flex justify-content-between'
              }>
              <span>{selected && `${selected.mail} (${selectedName})`}</span>
              <div className="btn-group btn-group-sm">
                <button
                  type={'button'}
                  data-bs-toggle="modal"
                  data-bs-target="#managerInvite"
                  className={'btn btn-sm btn-outline-primary'}>
                  초대
                </button>
                <button
                  type={'button'}
                  data-bs-toggle="modal"
                  data-bs-target="#managerCreate"
                  className={'btn btn-sm btn-outline-primary'}>
                  생성
                </button>
                {selected && (
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#managerUpdate"
                    className={'btn btn-sm btn-outline-primary'}>
                    수정
                  </button>
                )}
                {selected && (
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#managerDelete"
                    className={'btn btn-sm btn-outline-primary'}>
                    삭제
                  </button>
                )}
              </div>
            </div>
            <table id={'manager_list'} className={'datatable-table sp-table'}>
              <thead>
                <tr>
                  <th style={{maxWidth: 100}}>아이디</th>
                  <th style={{maxWidth: 100}}>이름</th>
                  <th style={{maxWidth: 50}}>대기업무</th>
                  <th style={{maxWidth: 100}}>구분</th>
                  <th style={{maxWidth: 150}}>메일</th>
                  <th>전화번호</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </>
        </div>
        <ModalUserManagerInvite onChanged={this.refreshData.bind(this)} />
        <ModalUserManagerCreate onChanged={this.refreshData.bind(this)} />
        <ModalUserManagerEdit
          onChanged={this.refreshData.bind(this)}
          user={this.state.selected}
        />
        <ModalUserManagerDelete
          onChanged={this.refreshData.bind(this)}
          user={this.state.selected}
        />
      </div>
    );
  }
}
