import React from "react";

interface Props{
    path: string[]
}
export class Breadcrumb extends React.PureComponent<Props>{
    render() {
        return (
            <div className={'sp-breadcrumb'}>
                {
                    this.props.path.map((value, idx)=>{
                        return `${idx > 0 ? ' / ':''} ${value}`;
                    })
                }
            </div>
        );
    }
}
