import React from "react";
import {Link} from "react-router-dom";
import {PAGE_URLS} from "./PageUtil";
import InputEmail from "../comp/InputEmail";
import Api, {ApiResp} from "../data/Api";
import {AxiosResponse} from "axios";

class State {
    mail: string = '';
    validMail?: boolean;
}

export default class PageFindId extends React.Component<any, State> {
    state = new State();

    onChangeEmail(value: string) {
        this.setState({mail: value});
    }

    onEmailValidation(valid?: boolean) {
        this.setState({validMail: valid});
    }

    onClickCancel(event: any) {
        event.preventDefault();
        window.location.href = PAGE_URLS.SIGN_IN;
        return true;
    }

    onClickEnter(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.requestFindId().catch(e => {
            window.alert(`일시적으로 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.`);
        });
    }

    async requestFindId() {
        const {mail} = this.state;
        const result:AxiosResponse<ApiResp<any>> = await Api.post(`/api/admin/user/find/id?email=${mail}`);
        console.log(JSON.stringify(result));
        if (result.data.code === 0) {
            window.alert('ID 를 메일로 발송하였습니다.\n메일을 확인해주세요.');
            window.location.href = PAGE_URLS.SIGN_IN;
        } else if (result.data.code === 102) {
            window.alert('등록되지 않은 메일입니다.\n메일 주소를 다시 확인해주세요.');
        } else {
            window.alert(`일시적으로 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.`);
        }
    }

    render() {
        const {mail, validMail} = this.state;
        return (
            <div className={'sign-container bg-light'}>
                <div className={'sign-box'}>
                    <div className={'sign-header text-center'}>
                        <h3 style={{fontWeight: 'bold'}}>NEO SALES ID 확인</h3>
                    </div>
                    <form
                        onSubmit={this.onClickEnter.bind(this)}
                    >
                        <div className={'sign-body'}>
                            <div className="form-floating mb-3">
                                <InputEmail
                                    onValidationChange={this.onEmailValidation.bind(this)}
                                    onChange={this.onChangeEmail.bind(this)}
                                    value={mail}
                                />
                            </div>
                        </div>
                        <div className={'sign-footer'}>
                            <button
                                type={'submit'}
                                className={'btn btn-primary mt-2 w-100'}
                                disabled={!validMail}
                            >
                                ID 확인 메일 발송
                            </button>
                            <button
                                className={'btn btn-outline-secondary mt-2 w-100'}
                                onClick={this.onClickCancel.bind(this)}
                            >
                                취소
                            </button>
                            <div className={'mt-3 d-flex justify-content-end'}>
                                <small>
                                    <Link to={PAGE_URLS.FIND_PASSWORD} className={'sp-text-decoration-none'}>
                                        비밀번호 재설정
                                    </Link>
                                </small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
