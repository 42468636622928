import React from 'react';
import ModalContainer, {TypeModal} from '../pages/modal/ModalContainer';
import Business, {TypeBusinessStatus} from '../type_doc';
import {numberWithCommas} from '../data/DataUtils';
import {loadUserInfo} from '../pages/PageUtil';
import {TypeUserLevel} from '../types';

interface Props {
  business: Business;
  onChanged: () => void;
}
export default class DocRequirements extends React.Component<Props, any> {
  async onClickRequestQuote() {
    const {business} = this.props;
    const result = await business.productListFixed();
    if (result !== null) {
      this.props.onChanged();
    }
    return result !== null;
  }
  render() {
    const user = loadUserInfo();
    if (user === undefined) {
      console.error('User session not exist');
      window.location.replace('/');
      return <></>;
    }
    const {business} = this.props;
    const biz = Object.assign(new Business(), business);
    const products = business.requirements.productList.map((value, index) => {
      console.log('product : ' + `/product?pro=${value.ref}`);
      const title =
        // value.ref >= 0 ? (
        //   <a
        //     href={`/product?pro=${value.ref}`}
        //     target={'_blank'}
        //     rel="noreferrer">
        //     {value.title}
        //   </a>
        // ) : (
        value.title;
      // );
      return (
        <tr key={index}>
          <td>{title}</td>
          <td className={'text-end'}>{numberWithCommas(value.amount)}</td>
          <td className={'text-end'}>{numberWithCommas(value.unitPrice)}</td>
          <td className={'text-end'}>{numberWithCommas(value.price)}</td>
        </tr>
      );
    });

    const disabled = !business.checkStepProcessable(
      TypeBusinessStatus.quoteReq,
    );

    let quoteReqEnable = false;
    switch (user.level) {
      case TypeUserLevel.business:
        quoteReqEnable = business.userBiz === user.uid;
        break;
      case TypeUserLevel.sales:
        quoteReqEnable = business.userSales === user.uid;
        break;
    }
    return (
      <div className={'card'}>
        <div className={'card-header d-flex justify-content-between'}>
          <span>견적 요청 목록</span>
          {quoteReqEnable && (
            <button
              className={'btn btn-sm btn-danger'}
              disabled={disabled}
              data-bs-toggle="modal"
              data-bs-target="#requestQuote">
              견적요청
            </button>
          )}
        </div>
        <div className={'card-body card-quotation'}>
          <table className={'table table-sm table-striped'}>
            <thead>
              <tr>
                <th>제품명</th>
                <th className={'text-end'}>수량</th>
                <th className={'text-end'}>단가</th>
                <th className={'text-end'}>합계</th>
              </tr>
            </thead>
            <tbody>
              {products}
              <tr>
                <th colSpan={3}>합계</th>
                <th className={'text-end'}>
                  {numberWithCommas(biz.getRequirements().getSum())}
                  <br />
                  <small>
                    VAT:{numberWithCommas(biz.getRequirements().getSum() / 10)}
                  </small>
                </th>
              </tr>
            </tbody>
          </table>
          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              요청사항
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              disabled
              rows={3}
              value={business.requirements.note}
            />
          </div>
        </div>
        <ModalContainer
          divId={'requestQuote'}
          title={'견적요청'}
          type={TypeModal.SAVE}
          onStore={this.onClickRequestQuote.bind(this)}>
          견적 요청 하시겠습니까? 요청 하신 후에는 물품 목록을 수정할 수
          없습니다.
        </ModalContainer>
      </div>
    );
  }
}
