import {User} from "./types";
import Api, {ApiResp} from "./data/Api";
import {AxiosResponse} from "axios";

export default class UserCache {
    static inst: UserCache;
    static getInstance(){
        if (UserCache.inst === undefined){
            UserCache.inst = new UserCache();
        }
        return UserCache.inst;
    }

    async getById(id: string): Promise<User|undefined>{
        const url = `/api/admin/user/info/${id}`;
        const res:AxiosResponse<ApiResp<User>> = await Api.get(url);
        if (res.data.code === 0){
            return res.data.body;
        }
        return undefined;
    }

    async getByMail(mail: string): Promise<User|undefined>{
        console.log('asdf')
        return new User();
    }
}
