import {AxiosResponse} from "axios";
import Api, {ApiResp} from "./data/Api";
import {Product} from "./type_doc";

export default class ProductCache {
    static inst: ProductCache;

    static getInstance(){
        if (ProductCache.inst === undefined){
            ProductCache.inst = new ProductCache();
        }
        return ProductCache.inst;
    }

    async getBySeq(seq: number): Promise<Product|undefined>{
        const url = `/api/admin/product/${seq}`
        const res: AxiosResponse<ApiResp<Product>> = await Api.get(url);
        if (res.data.code === 0) {
            return res.data.body;
        }
        return undefined;
    }
}
