import React from 'react';

import '../../css/step.css';
import {TypeBusiness, TypeBusinessStatus} from '../../type_doc';

interface Props {
  type: TypeBusiness;
  step: TypeBusinessStatus;
}
export default class BusinessStep extends React.Component<Props, any> {
  _renderDelivery() {
    let ss = 0;
    switch (this.props.step) {
      case TypeBusinessStatus.initial:
        break;
      case TypeBusinessStatus.quoteReq:
        ss = 1;
        break;
      case TypeBusinessStatus.quoteRes:
        ss = 2;
        break;
      case TypeBusinessStatus.supply:
        ss = 3;
        break;
      case TypeBusinessStatus.calculate:
        ss = 4;
        break;
      case TypeBusinessStatus.close:
        ss = 5;
        break;
      case TypeBusinessStatus.done:
        ss = 6;
        break;
      case TypeBusinessStatus.cancel:
        return (
          <div className={'d-flex justify-content-end'}>
            <div className={'step-item step-done'}>취소</div>
          </div>
        );
    }
    const step = (s: number) => {
      return s > ss ? 'step-ready' : 'step-done';
    };
    const bar = (s: number) => {
      return s >= ss ? 'step-bar-ready' : '';
    };
    return (
      <div className={'d-flex justify-content-between'}>
        <div className={'step-item ' + step(0)}>생성</div>
        <div className={'step-bar ' + bar(0)} />
        <div className={'step-item ' + step(1)}>견적요청</div>
        <div className={'step-bar ' + bar(1)} />
        <div className={'step-item ' + step(2)}>견적발행</div>
        <div className={'step-bar ' + bar(2)} />
        <div className={'step-item ' + step(3)}>물품공급</div>
        <div className={'step-bar ' + bar(3)} />
        <div className={'step-item ' + step(4)}>비용처리</div>
        <div className={'step-bar ' + bar(4)} />
        <div className={'step-item ' + step(5)}>마감(대기)</div>
        <div className={'step-bar ' + bar(5)} />
        <div className={'step-item ' + step(6)}>완료</div>
      </div>
    );
  }

  _renderSales() {
    let ss = 0;
    switch (this.props.step) {
      case TypeBusinessStatus.initial:
        break;
      case TypeBusinessStatus.quoteReq:
        ss = 1;
        break;
      case TypeBusinessStatus.quoteRes:
        ss = 2;
        break;
      case TypeBusinessStatus.supply:
        ss = 3;
        break;
      case TypeBusinessStatus.calculate:
        ss = 4;
        break;
      case TypeBusinessStatus.close:
        ss = 5;
        break;
      case TypeBusinessStatus.done:
        ss = 6;
        break;
      case TypeBusinessStatus.cancel:
        ss = 7;
        break;
    }
    const step = (s: number) => {
      return s > ss ? 'step-ready' : 'step-done';
    };
    const bar = (s: number) => {
      return s >= ss ? 'step-bar-ready' : '';
    };
    return (
      <div className={'d-flex justify-content-between'}>
        <div className={'step-item ' + step(0)}>대상 물품등록</div>
        <div className={'step-bar ' + bar(0)} />
        <div className={'step-item ' + step(1)}>구축, 납품진행</div>
        <div className={'step-bar ' + bar(1)} />
        <div className={'step-item ' + step(2)}>비용 처리</div>
        <div className={'step-bar ' + bar(2)} />
        <div className={'step-item ' + step(3)}>완료</div>
      </div>
    );
  }
  render() {
    return this.props.type === TypeBusiness.agency
      ? this._renderSales()
      : this._renderDelivery();
  }
}
