import React from 'react';
import {TypeBusiness, TypeBusinessStatus} from '../../type_doc';
import {generateBusinessNumber} from '../../pages/PageUtil';
import moment from 'moment';

interface Props {
  id: number;
  year: string;
  manager: string;
  state: TypeBusinessStatus;
  type: TypeBusiness;
  usePartner?: boolean;
  price: number;
  desc: string;
  timeUpdate: string;
  title: string;
  onClick?: (id: number) => void;
  selected?: boolean;
}
export default class BusinessCard extends React.Component<Props, any> {
  render() {
    let badgeClass = '';
    let color = undefined;
    let line = undefined;

    let stateName = undefined;
    badgeClass = 'text-bg-primary';
    switch (this.props.state) {
      case TypeBusinessStatus.initial:
        stateName = '생성';
        break;
      case TypeBusinessStatus.quoteReq:
        stateName = '견적요청';
        break;
      case TypeBusinessStatus.quoteRes:
        stateName = '견적발행';
        break;
      case TypeBusinessStatus.supply:
        stateName = '물품공급';
        break;
      case TypeBusinessStatus.calculate:
        stateName = '비용처리';
        break;
      case TypeBusinessStatus.close:
        badgeClass = 'text-bg-danger';
        stateName = '마감';
        break;
      case TypeBusinessStatus.done:
        stateName = '완료';
        badgeClass = 'text-bg-secondary';
        color = 'gray';
        break;
      case TypeBusinessStatus.cancel:
        stateName = '취소';
        badgeClass = 'text-bg-secondary';
        color = 'gray';
        line = 'line-through';
        break;
    }
    let type = '';
    switch (this.props.type) {
      case TypeBusiness.delivery:
        type = '제품 중개';
        break;
      case TypeBusiness.agency:
        type = '영업 대행';
        break;
    }
    const bId = generateBusinessNumber(this.props.id, this.props.year);
    return (
      <div
        className={
          'card-business ' +
          (this.props.selected ? 'card-business-selected' : '')
        }
        onClick={() => this.props.onClick?.(this.props.id)}>
        <div className={'d-flex justify-content-between'}>
          <div className="position-relative">
            <span style={{color: color, textDecoration: line}}>{bId}</span>
            {/*<span style={{color: color, textDecoration: line}}> ({this.props.manager})</span>*/}
          </div>
          <div>
            <div className={'badge rounded-pill ' + badgeClass}>
              {stateName}
            </div>
          </div>
        </div>
        <div className={'d-flex justify-content-between'}>
          <div style={{color: color}}>
            <span className={'type'}>{`[${type}] `}</span>
            {this.props.title}
          </div>
          <div>{moment(this.props.timeUpdate).format('MM/DD HH:mm')}</div>
        </div>
      </div>
    );
  }
}
