import React from 'react';
import {Refund, User} from '../../types';
import Business from '../../type_doc';
import BizCard from './BizCard';
import {AxiosResponse} from 'axios';
import Api, {ApiResp} from '../../data/Api';
import UserCache from '../../UserCache';
import ModalContainer, {TypeModal} from '../../pages/modal/ModalContainer';
import {numberWithCommas} from "../../data/DataUtils";
import InputNumberWithComma from "../InputNumberWithComma";

interface Props {
  refund: Refund;
  onChanged: () => void;
}
class State {
  business: Business[] = [];
  user: User = new User();

  isBusiness?: boolean;
  docCommission?: File;
  commissionSupply?: number;
  commissionTax?: number;
  memo = '';
}
export default class RefundDetail extends React.Component<Props, State> {
  state = new State();

  async loadRefundableBizList() {
    const {refund} = this.props;
    if (refund === undefined) {
      console.error('refund data absent.');
      return;
    }
    const user = await UserCache.getInstance().getById(refund.userRefund);
    if (user === undefined) {
      console.error('User not found');
      return;
    }
    const array = [];
    for (const s of refund.bizRel) {
      const biz = await Business.getBusiness(s);
      array.push(biz);
    }
    this.setState({
      business: array,
      user: user,
    });
  }

  componentDidMount() {
    this.loadRefundableBizList().catch(e => console.error(e));
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const pp = JSON.stringify(prevProps.refund);
    const np = JSON.stringify(this.props.refund);
    if (pp !== np) {
      this.loadRefundableBizList().catch(e => console.error('!!! ' + e));
    }
  }

  onChangeDocCommission(event: any) {
    if (event.target.files) {
      console.log(event.target.files);
      this.setState({docCommission: event.target.files[0] as File});
    }
  }
  onChangeCommisionSupply(value: number) {
    this.setState({commissionSupply: value});
  }
  onChangeCommisionTax(value: number) {
    this.setState({commissionTax: value});
  }
  onChangeMemo(event: any) {
    this.setState({memo: event.target.value});
  }

  /**
   * 환급 구분에 따라 지급액과 세금을 계산한다.
   *
   * 2024.04.11 [김응규]
   *  - 신규 추가
   * @param isBusiness 사업자 여부
   */
  onChangeBizType(isBusiness: boolean) {
    const {totalBalance} = this.props.refund;
    const rTax = isBusiness ? 0.1 : 0.033;
    const tax = totalBalance * rTax;
    const supply = totalBalance - tax;
    this.setState({
      isBusiness: isBusiness,
      commissionSupply: Number((supply).toFixed()),
      commissionTax: Number((tax).toFixed())
    });
  }

  async approveRefund(approve: boolean) {
    const {refund} = this.props;
    const {docCommission, commissionSupply, commissionTax, memo} = this.state;

    const url = `/api/admin/refund/request/${refund.refundUid}/approve`;
    const formData = new FormData();
    if (approve && docCommission) formData.set('docCommission', docCommission);
    if (approve && commissionSupply)
      formData.set('commissionSupply', `${commissionSupply}`);
    if (approve && commissionTax)
      formData.set('commissionTax', `${commissionTax}`);
    formData.set('memo', memo);
    formData.set('approve', `${approve}`);
    const result: AxiosResponse<ApiResp<boolean>> = await Api.put(
      url,
      formData,
    );
    if (result.data.code === 0) {
      this.props.onChanged();
      return true;
    } else {
      console.error('FAil to approve refund : ' + result.data.reason);
      return false;
    }
  }
  async onClickApprove() {
    return await this.approveRefund(true);
  }
  async onClickReject() {
    return await this.approveRefund(false);
  }

  render() {
    const {
      business,
      user,
      docCommission,
        isBusiness,
      commissionSupply,
      commissionTax,
      memo,
    } = this.state;
    const biz = business.map((value, index) => {
      return <BizCard key={index} biz={value} selected={false} hideCheckbox />;
    });
    return (
      <div className={'container-fluid'}>
        <div className={'row'}>
          <div className={'col-8'}>
            <div className={'row'}>
              <div className={'col'}>
                <div className={'alert alert-dark mt-3'}>
                  <span>영업사원 : @{user.uid}</span>
                </div>
              </div>
            </div>

            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>환급 은행</div>
              <div className={'col-9 col-form-label bg-light'}>
                <strong>{this.props.refund.bank}</strong>
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>환급 계좌번호</div>
              <div className={'col-9 col-form-label bg-light'}>
                <strong>{this.props.refund.account}</strong>
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>개인 식별</div>
              <div className={'col-9 col-form-label bg-light'}>
                <span className={'badge text-bg-primary'}>
                  <a
                    href={`/api/admin/refund/request/${this.props.refund.refundUid}/attachment?path=${this.props.refund.docIdentify}`}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    <strong className={'text-white'}>파일 다운로드</strong>
                  </a>
                </span>
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>통장 사본</div>
              <div className={'col-9 col-form-label bg-light'}>
                <span className={'badge text-bg-primary'}>
                  <a
                    href={`/api/admin/refund/request/${this.props.refund.refundUid}/attachment?path=${this.props.refund.docBank}`}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    <strong className={'text-white'}>파일 다운로드</strong>
                  </a>
                </span>
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>세금 계산 파일 등록</div>
              <div className={'col-9 col-form-label'}>
                <input
                  type="file"
                  className={'form-control '}
                  placeholder={
                    '세금 계산서 혹은 원천징수 영수증 파일을 등록하세요'
                  }
                  onChange={this.onChangeDocCommission.bind(this)}
                />
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>환급 구분</div>
              <div className={'col-9 col-form-label'}>
                <div className={'input-group'}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isBusiness !== undefined && isBusiness}
                      id="flexCheckDefault"
                      onClick={() => {
                        this.onChangeBizType(true);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault">
                      사업자
                    </label>
                  </div>
                  <div style={{width: 50}} />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isBusiness !== undefined && !isBusiness}
                      id="flexCheckChecked"
                      onClick={() => {
                        this.onChangeBizType(false);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked">
                      개인
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>수수료 지급액</div>
              <div className={'col-9 col-form-label'}>
                <InputNumberWithComma
                    className={'form-control '}
                    placeholder={'수수료 지급액을 등록 하세요'}
                    value={commissionSupply}
                    onChange={this.onChangeCommisionSupply.bind(this)}
                />
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>세금</div>
              <div className={'col-9 col-form-label'}>
                <InputNumberWithComma
                    className={'form-control '}
                    placeholder={'세액을 등록 하세요'}
                    value={commissionTax}
                    onChange={this.onChangeCommisionTax.bind(this)}
                />
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>메모</div>
              <div className={'col-9 col-form-label'}>
                <textarea
                  className={'form-control '}
                  placeholder={'수수료 결산 내용을 등록하세요  '}
                  value={memo}
                  onChange={this.onChangeMemo.bind(this)}
                  rows={5}
                />
              </div>
            </div>
            <div className={'mb-3 row'}>
              <div className={'col-3 col-form-label'}>완료</div>
              <div className={'col-9 col-form-label'}>
                <button
                  className={'btn btn-primary m-2'}
                  data-bs-toggle="modal"
                  data-bs-target="#approveRefund">
                  완 료
                </button>
                <button
                  className={'btn btn-danger m-2'}
                  data-bs-toggle="modal"
                  data-bs-target="#rejectRefund">
                  거 절
                </button>
              </div>
            </div>
          </div>
          <div className={'col-4 pt-3'}>
            <h4>대상 사업</h4>
            {biz}
          </div>
        </div>
        <ModalContainer
          divId={'approveRefund'}
          title={'환급 승인'}
          type={TypeModal.CONFIRM}
          onStore={this.onClickApprove.bind(this)}>
          환급을 승인하시겠습니까?
        </ModalContainer>
        <ModalContainer
          divId={'rejectRefund'}
          title={'환급 거절'}
          type={TypeModal.CONFIRM}
          onStore={this.onClickReject.bind(this)}>
          환급을 거절하시겠습니까?
        </ModalContainer>
      </div>
    );
  }
}
