import React from 'react';
import {TypeBusinessStatus} from '../../type_doc';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  bizStatus: TypeBusinessStatus[];
  size: number;
  startDate: Date;
  endDate: Date;
  onChanged: (
    bizStatus: TypeBusinessStatus[],
    size: number,
    from: Date,
    to: Date,
  ) => void;
}

class State {
  bizStatus: TypeBusinessStatus[] = [];
  size: number = 20;
  startDate: Date = new Date();
  endDate: Date = new Date();
}
export default class BusinessFilter extends React.Component<Props, State> {
  state = {
    bizStatus: this.props.bizStatus,
    size: this.props.size,
    startDate: this.props.startDate,
    endDate: this.props.endDate,
  };

  onToggleBizAll() {
    const {bizStatus} = this.state;
    if (bizStatus.length === 8) {
      this.setState({bizStatus: []});
    } else {
      const all = [
        TypeBusinessStatus.initial,
        TypeBusinessStatus.quoteReq,
        TypeBusinessStatus.quoteRes,
        TypeBusinessStatus.supply,
        TypeBusinessStatus.calculate,
        TypeBusinessStatus.close,
        TypeBusinessStatus.done,
        TypeBusinessStatus.cancel,
      ];
      this.setState({bizStatus: all});
    }
  }

  onChangeBizStatus(event: any) {
    const status = event.target.value;
    const {bizStatus, size, startDate, endDate} = this.state;
    if (bizStatus.includes(status)) {
      const index = bizStatus.indexOf(status);
      bizStatus.splice(index, 1);
    } else {
      bizStatus.push(status);
    }
    this.setState({bizStatus: bizStatus});
  }
  onChangePageSize(event: any) {
    const size = event.target.value;
    this.setState({size: size});
  }
  onSelectDuration(ago: number) {
    const now = new Date();
    const before = new Date();
    before.setDate(now.getDate() - ago);
    this.setState({startDate: before, endDate: now});
  }
  onSelectStartDate(date: Date) {
    this.setState({startDate: date});
  }
  onSelectEndDate(date: Date) {
    this.setState({endDate: date});
  }
  render() {
    const {bizStatus, size} = this.state;
    const {startDate, endDate} = this.state;
    return (
      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex={-1}
        id="businessFilterCanvas"
        aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
            사업 보기 설정
          </h5>
          <button
            type="button"
            className="btn btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close">
            X
          </button>
        </div>
        <div className="offcanvas-body">
          <div className={'mb-2 row'}>
            <div className={'col-sm-2 col-form-label'}>사업 상태</div>
            <div className={'col-sm-10'}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={bizStatus.length === 8}
                  onChange={this.onToggleBizAll.bind(this)}
                />
                <label
                  className="form-check-label text-muted"
                  onClick={() => {
                    this.onToggleBizAll();
                  }}>
                  전체
                </label>
              </div>
              <hr />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.initial}
                  id="initial"
                  checked={bizStatus.includes(TypeBusinessStatus.initial)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="initial">
                  생성
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.quoteReq}
                  id="quoteReq"
                  checked={bizStatus.includes(TypeBusinessStatus.quoteReq)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="quoteReq">
                  견적요청
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.quoteRes}
                  id="quoteRes"
                  checked={bizStatus.includes(TypeBusinessStatus.quoteRes)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="quoteRes">
                  견적발행
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.supply}
                  id="supply"
                  checked={bizStatus.includes(TypeBusinessStatus.supply)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="supply">
                  물품공급
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.calculate}
                  id="calculate"
                  checked={bizStatus.includes(TypeBusinessStatus.calculate)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="calculate">
                  비용처리
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.close}
                  id="close"
                  checked={bizStatus.includes(TypeBusinessStatus.close)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="close">
                  마감(대기)
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.done}
                  id="done"
                  checked={bizStatus.includes(TypeBusinessStatus.done)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="done">
                  완료
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={TypeBusinessStatus.cancel}
                  id="cancel"
                  checked={bizStatus.includes(TypeBusinessStatus.cancel)}
                  onChange={this.onChangeBizStatus.bind(this)}
                />
                <label className="form-check-label" htmlFor="cancel">
                  취소
                </label>
              </div>
            </div>
          </div>
          <div className={'mb-2 row'}>
            <div className={'col-sm-2 col-form-label'}>보기</div>
            <div className={'col-sm-10'}>
              <div className="input-group input-group-sm">
                <span className="input-group-text" id="basic-addon3">
                  페이지 당
                </span>
                <input
                  type="number"
                  className="form-control"
                  id="basic-url"
                  value={size}
                  aria-describedby="basic-addon3 basic-addon4"
                  onChange={this.onChangePageSize.bind(this)}
                />
                <span className="input-group-text" id="basic-addon3">
                  개
                </span>
              </div>
            </div>
          </div>
          <div className={'mb-2 row'}>
            <div className={'col-sm-2 col-form-label'}>기간</div>
            <div className={'col-sm-10'}>
              <div className="btn-group btn-group-sm">
                <button
                  type={'button'}
                  className={'btn btn-sm btn-outline-primary'}
                  onClick={() => this.onSelectDuration(9999)}>
                  전체
                </button>
                <button
                  type={'button'}
                  className={'btn btn-sm btn-outline-primary'}
                  onClick={() => this.onSelectDuration(30)}>
                  최근 1개월
                </button>
                <button
                  type={'button'}
                  className={'btn btn-sm btn-outline-primary'}
                  onClick={() => this.onSelectDuration(90)}>
                  최근 3개월
                </button>
              </div>
              <div className="input-group input-group-sm">
                <div className={'input-group'}>
                  <span className={'input-group-text'}>시작일</span>
                  <ReactDatePicker
                    className={'form-control'}
                    selected={startDate}
                    onChange={date => {
                      if (date !== null) {
                        this.onSelectStartDate(date);
                      }
                    }}
                  />
                </div>
                <div className={'input-group'}>
                  <span className={'input-group-text'}>종료일</span>
                  <ReactDatePicker
                    className={'form-control'}
                    selected={endDate}
                    onChange={date => {
                      if (date !== null) {
                        this.onSelectEndDate(date);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className={'btn btn-primary'}
              data-bs-dismiss="offcanvas"
              onClick={() => {
                const {bizStatus, size, startDate, endDate} = this.state;
                this.props.onChanged(bizStatus, size, startDate, endDate);
              }}>
              변경사항 적용
            </button>
          </div>
        </div>
      </div>
    );
  }
}
