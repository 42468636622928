import React from 'react';
import Business, {FixedDocument} from '../type_doc';
import PdfViewer from '../comp/PdfViewer';
import {numberWithCommas} from '../data/DataUtils';

interface Props {
  business: Business;
  type: 'order' | 'delivery' | 'taxPurchase' | 'taxSales' | 'taxCommission';
  title: string;
}
export default class DocViewer extends React.Component<Props, any> {
  renderDoc(docType: string, file: string) {
    const {business} = this.props;
    console.log('business: ' + JSON.stringify(business));
    switch (docType) {
      case 'image/gif':
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return (
          <img
            src={`/api/admin/biz/${business.docSeq}/order?path=${file}`}
            alt={''}
            style={{maxWidth: '100%'}}
          />
        );
      case 'application/pdf':
        return (
          <PdfViewer
            url={`/api/admin/biz/${business.docSeq}/order?path=${file}`}
          />
        );
      default:
        return <></>;
    }
  }

  render() {
    const {business, type} = this.props;
    let docType;
    let file;
    let document;
    switch (type) {
      case 'order':
        docType = business.order?.fileType;
        file = business.order?.file;
        document = business.order;
        break;
      case 'delivery':
        docType = business.delivery?.fileType;
        file = business.delivery?.file;
        document = business.delivery;
        break;
      case 'taxPurchase':
        docType = business.taxPurchase?.fileType;
        file = business.taxPurchase?.file;
        document = business.taxPurchase;
        break;
      case 'taxSales':
        docType = business.taxSales?.fileType;
        file = business.taxSales?.file;
        document = business.taxSales;
        break;
      case 'taxCommission':
        docType = business.taxCommission?.fileType;
        file = business.taxCommission?.file;
        document = business.taxCommission;
        break;
    }

    return (
      <div className={'card'}>
        <div className={'card-header'}>{this.props.title}</div>
        <div className={'card-body card-quotation'}>
          {type !== 'delivery' && (
            <div className={'input-group input-group-sm mb-3'}>
              <span className={'input-group-text'}>공급가</span>
              <input
                className={'form-control form-control-sm'}
                value={numberWithCommas(document?.priceSupply || 0)}
              />
              <span className={'input-group-text'}>세금</span>
              <input
                className={'form-control form-control-sm'}
                value={numberWithCommas(document?.priceTax || 0)}
              />
            </div>
          )}
          {docType && file && this.renderDoc(docType, file)}
        </div>
      </div>
    );
  }
}
