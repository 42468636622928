import React from 'react';
import UserCache from '../UserCache';

interface Props {
  onChange: (value: string) => void;
  value: string;
  checkDup?: boolean;
  onValidationChange?: (valid?: boolean) => void;
  disabled?: boolean;
  onBlur?: () => void;
}
export default class InputEmail extends React.Component<Props, any> {
  state = {
    workId: '',
    workDomain: '',

    validId: false,
    validDomain: false,
    duplicated: false,
  };

  _workIdValid() {
    const {workId, workDomain} = this.state;
    return workId.length >= 3;
  }

  _workDomainValid() {
    const {workId, workDomain} = this.state;
    return workDomain.includes('.');
  }

  checkValid() {
    const {workId, workDomain} = this.state;
    this.setState({
      validId: this._workIdValid(),
      validDomain: this._workDomainValid(),
    });
    if (this._workIdValid() && this._workDomainValid()) {
      if (this.props.checkDup) {
        UserCache.getInstance()
          .getByMail(this.props.value)
          .then(r => {
            this.setState({duplicated: r === undefined});
          })
          .catch(e => {
            this.setState({duplicated: false});
          });
      }
    }
  }

  updateState() {
    const {value} = this.props;
    const token = value.split('@');
    if (Array.isArray(token) && token.length === 2) {
      this.setState({workId: token[0], workDomain: token[1]});
      this.checkValid();
    } else {
      this.setState({workId: '', workDomain: ''});
    }
  }

  componentDidMount() {
    // this.updateState();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.value);
    const pp = JSON.stringify(prevProps.value);
    if (np !== pp) {
      this.updateState();
    }

    if (
      this.state.validId !== prevState.validId ||
      this.state.validDomain !== prevState.validDomain
    ) {
      if (this.props.onValidationChange) {
        if (this.props.checkDup) {
          this.props.onValidationChange(
              !this.props.value ? undefined :
            this.state.validId &&
              this.state.validDomain &&
              !this.state.duplicated,
          );
        } else {
          this.props.onValidationChange(
              !this.props.value ? undefined :
            this.state.validId && this.state.validDomain
          );
        }
      }
    }
  }

  onChangeId(event: any) {
    const {workId, workDomain} = this.state;
    this.setState({workId: event.target.value});
    const mail = `${event.target.value}@${workDomain}`;
    this.props.onChange(mail);
    this.checkValid();
  }

  onChangeDomain(event: any) {
    const {workId, workDomain} = this.state;
    this.setState({workDomain: event.target.value});
    const mail = `${workId}@${event.target.value}`;
    this.props.onChange(mail);
    this.checkValid();
  }

  render() {
    const {workId, workDomain} = this.state;
    const vcId = this.props.onValidationChange && this.props.value.length > 1
      ? this.state.validId
        ? 'is-valid'
        : 'is-invalid'
      : '';
    const vcDom = this.props.onValidationChange && this.props.value.length > 1
      ? this.state.validDomain
        ? 'is-valid'
        : 'is-invalid'
      : '';
    return (
      <div className="input-group">
        <input
          type="text"
          className={'form-control ' + vcId}
          style={{maxWidth: 300}}
          placeholder={'email'}
          value={workId}
          disabled={this.props.disabled}
          onChange={this.onChangeId.bind(this)}
        />
        <span className="input-group-text">@</span>
        <input
          type="text"
          className={'form-control ' + vcDom}
          placeholder={'user-email.com'}
          disabled={this.props.disabled}
          value={workDomain}
          onChange={this.onChangeDomain.bind(this)}
          onBlur={this.props.onBlur ? this.props.onBlur.bind(this) : undefined}
        />
      </div>
    );
  }
}
