export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function numberWithCompanyRegNum(input: string) {
  const cleaned = ('' + input).replace(/\D/g, '');
  return cleaned.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
}

export function convertUtcToLocal(time: string) {
  const utcDate = new Date(time);
  return utcDate.toLocaleString();
}
