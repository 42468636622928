import React, {CSSProperties} from "react";
import Avatar from "react-avatar";
import {User} from "../types";
import {PAGE_URLS} from "../pages/PageUtil";

interface Props {
    user?: User;
    size?: number;
    click?: boolean;
    style?: CSSProperties;
}
export class ManagerIcon extends React.Component<Props, any>{
    onClick(){
        if (this.props.click){
            window.location.href = PAGE_URLS.PROFILE + `/${this.props.user?.uid}`
        }
    }
    render() {
        return (
            <Avatar
                name={this.props.user?.uid}
                size={this.props.size ? this.props.size.toFixed() : '30'}
                color={'#ff5b5b'}
                round
                style={this.props.style}
                onClick={this.props.click ? this.onClick.bind(this) : undefined} />
        );
    }
}
