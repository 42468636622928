export enum TypeScope {
  na,
  manager,
  sales,
}

export class MenuNode {
  key: string;
  title: string;
  children: MenuNode[] = [];

  constructor(key: string, title: string) {
    this.key = key;
    this.title = title;
  }

  addChild(node: MenuNode) {
    this.children.push(node);
  }
}

interface dict<T> {
  [key: string]: T;
}
export class UrlParams {
  params: dict<string[]> = {};

  static parse(param: string) {
    const p = new UrlParams();
    if (param === undefined) return p;
    if (param.length <= 1) return p;
    const line = param.startsWith('?') ? param.substring(1) : param;
    const tokens = line.split('&');
    for (const token of tokens) {
      const pair = token.split('=');
      if (pair[1] === undefined || pair[1] === '') continue;
      if (p.params[pair[0]] === undefined) {
        p.params[pair[0]] = [pair[1]];
      } else {
        p.params[pair[0]].push(pair[1]);
      }
    }
    return p;
  }

  set(k: string, v: string) {
    if (this.params[k] === undefined) {
      this.params[k] = [v];
    } else {
      this.params[k].push(v);
    }
  }

  get(k: string, array?: boolean): string | string[] | undefined {
    if (k === undefined) return undefined;
    const value = this.params[k];
    if (value === undefined) return undefined;
    if (!array) {
      if (value.length >= 1) return value[0];
      else return undefined;
    }
    return this.params[k];
  }

  clear(key?: string) {
    if (key !== undefined) {
      delete this.params[key];
    } else {
      this.params = {};
    }
  }

  stringify() {
    let line = '';
    const keys = Object.keys(this.params);
    for (const key of keys) {
      const values = this.params[key];
      for (const value of values) {
        line = line + `&${key}=${value}`;
      }
    }
    return line;
  }

  getUrlParam() {
    return `?${this.stringify()}`;
  }
}
export enum TypeUserLevel {
  na = 'na',
  admin = 'admin',
  operator = 'operator',
  business = 'business',
  purchase = 'purchase',
  sales = 'sales',
}
export enum TypeProductContent {
  na = 'na',
  IMAGE = 'IMAGE',
  TEXT_MARKDOWN = 'TEXT_MARKDOWN',
  APPLICATION_PDF = 'APPLICATION_PDF',
}

export enum TypeRefundStatus {
  APPLY = 'APPLY',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  na = 'na',
}

export enum TypeHistoryTarget {
  na = 'na',
  USER = 'USER',
  PRODUCT = 'PRODUCT',
  BUSINESS = 'BUSINESS',
}

export enum TypeHistoryAction {
  na = 'na',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export class User {
  uid = '';
  mail = '';
  name = '';
  level: TypeUserLevel = TypeUserLevel.na;
  tel = '';
  description = '';
  works = 0;
  point = 0;
  company = '';
  managed = false;
  expire: string = new Date().toISOString();
}
export class Privacy extends User {
  isBusiness = false;
  registration = '';
  bankName = '';
  bankAccount = '';
  bankCopy = '';
}

export class Company {
  seq = 0;
  products = 0;
  name = '';
  manager: string[] = [];
  tel = '';
  email = '';
  corpRegNum = '';
  location1 = '';
  location2 = '';
  homepage = '';
  description = '';
}

export class Attachment {
  name = '';
  path = '';
  type = '';
  token = '';
}
export class ProductPromotion {
  inPromotion?: boolean;
  promotionContent?: string;
}
export class ProductAbstract extends ProductPromotion{
  seq = 0;
  title = '';
  titleDesc = '';
  titleImage: Attachment[] = [];
  draft = true;
}
export class ProductDetail extends ProductAbstract {
  infoSupply = '';
  infoInstall = '';
  infoKeyword: string[] = [];
  infoApplication = '';
  infoHomepage = '';
  infoSupplyLocation: string[] = [];
  infoSupplyContact: string[] = [];
  infoKeywordRel: string[] = [];
  infoGuide: Attachment[] = [];

  priceUnit: number = 0;
  pricePurchase: number = 0;

  contentType: TypeProductContent = TypeProductContent.TEXT_MARKDOWN;
  content = '';

  contentPdf?: Attachment;

  timeCreate = '';
}
export class UserInvite {
  seq = 0;
  mail = '';
  level: TypeUserLevel = TypeUserLevel.sales;
  token = '';
  timeCreate = '';
  timeSent = '';
}

export interface SearchDict<K, V> {
  key: K;
  value: V;
}

export class Activity {
  seq: number = -1;
  type: TypeHistoryTarget = TypeHistoryTarget.na;
  action: TypeHistoryAction = TypeHistoryAction.na;
  userUid: string = '';
  targetSeq: number = -1;
  what: string = '';
  from: string = '';
  to: string = '';
  timeCreate: string = '';

  getCode() {
    return '';
  }

  getDate() {
    if (this.timeCreate !== undefined) {
      return new Date(this.timeCreate);
    }
    console.error('Time not specified.');
    return new Date();
  }
}

export class Refund {
  refundUid: number = -1;
  userRefund: string = '';
  stepApply: TypeRefundStatus = TypeRefundStatus.na;
  totalBalance: number = 0;
  bank: string = '';
  account: string = '';
  docIdentify: string = '';
  docBank: string = '';
  docResultTransfer: string = '';
  refundSupply: number = 0;
  refundTax: number = 0;
  refundNote: string = '';
  bizRel: number[] = [];
  timeCreate: string = '';
  timeUpdate: string = '';
}

export class ChartUnit {
  name: string = '';
  v1: number = 0;
  v2?: number;
  v3?: number;
}

export class Category {
  seq: number = 0;
  category: string = '';
  parent?: number;
  count: number = 0;
}

export class BizMinute {
    minuteSeq: number = 0;
    bizUid: number = 0;
    userCreator: string = '';
    timeMeeting: string = '';
    attendee: string = '';
    minutes: string = '';
    timeCreate: string = '';
    timeUpdate: string = '';
    creator?: User;
}
