import React, {JSX} from 'react';

export enum TypeModal {
  CREATE = 'CREATE',
  USER_EDIT = 'USER_EDIT',
  DELETE = 'DELETE',
  SAVE = 'SAVE',
  DETAIL = 'DETAIL',
  EDIT = 'EDIT',
  APPROVAL = 'APPROVAL',
  INVITE = 'INVITE',
  INVITE_EDIT = 'INVITE_EDIT',
  CONFIRM = 'CONFIRM',
  STOP = 'STOP',
}
interface Props {
  title: string;
  divId: string;
  itemId?: string;
  children: JSX.Element | string;
  onStore?: () => Promise<boolean>;
  onReject?: () => Promise<boolean>;
  onCancel?: () => void;
  type: TypeModal;
  disableConfirm?: boolean;
  hideCancel?: boolean;
}
export default class ModalContainer extends React.Component<Props, any> {
  onChangePassword() {
    console.log('onChangePassword');
  }
  onReject() {
    const did = '#' + this.props.divId;
    const closeModal = (divId: string) => {
      $(`${divId}`).modal('hide');
    };
    if (this.props.onReject) {
      this.props
        .onReject()
        .then(result => {
          if (result) {
            closeModal(did);
          }
        })
        .catch(e => console.error(e));
    } else {
      closeModal(did);
    }
  }

  onStore() {
    const did = '#' + this.props.divId;
    const closeModal = (divId: string) => {
      $(`${divId}`).modal('hide');
    };
    if (this.props.onStore) {
      this.props
        .onStore()
        .then(result => {
          if (result) {
            closeModal(did);
            // } else {
            //     alert('Something wrong');
          }
        })
        .catch(e => {
          console.error(`Fail to ${this.props.type} : ` + JSON.stringify(e));
          window.alert(`에러가 발생했습니다.`);
        });
    } else {
      closeModal(did);
    }
  }
  onCancel() {
    const did = '#' + this.props.divId;
    const closeModal = (divId: string) => {
      $(`${divId}`).modal('hide');
    };
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    closeModal(did);
  }

  render() {
    const confirmButton = () => {
      switch (this.props.type) {
        case TypeModal.CREATE:
          return '생성';
        case TypeModal.USER_EDIT:
          return '수정';
        case TypeModal.EDIT:
          return '수정';
        case TypeModal.DELETE:
          return '삭제';
        case TypeModal.SAVE:
          return '저장';
        case TypeModal.DETAIL:
          return '상세보기';
        case TypeModal.APPROVAL:
          return '승인';
        case TypeModal.INVITE:
          return '초대';
        case TypeModal.INVITE_EDIT:
          return '재전송';
        case TypeModal.CONFIRM:
          return '확인';
        case TypeModal.STOP:
          return '중단';
      }
    };
    const rejectButton = () => {
      switch (this.props.type) {
        case TypeModal.APPROVAL:
          return '거절';
        case TypeModal.INVITE_EDIT:
          return '초대취소';
      }
    };
    return (
      <div
        className="modal fade "
        id={this.props.divId}
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">{this.props.title}</h1>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                aria-label="Close">
                X
              </button>
            </div>
            <div className="modal-body">{this.props.children}</div>
            <div
              // className={`modal-footer d-flex ${this.props.type === TypeModal.USER_EDIT ? 'justify-content-between' : 'justify-content-end'}`}>
              className={`modal-footer d-flex justify-content-end`}>
              {/*{this.props.type === TypeModal.USER_EDIT && (*/}
              {/*  <button*/}
              {/*    type="button"*/}
              {/*    className={`btn btn-outline-primary`}*/}
              {/*    onClick={this.onChangePassword.bind(this)}>*/}
              {/*    비밀번호 변경*/}
              {/*  </button>*/}
              {/*)}*/}
              <div>
                {!this.props.hideCancel && (
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    onClick={this.onCancel.bind(this)}>
                    취소
                  </button>
                )}
                {(this.props.type === TypeModal.APPROVAL ||
                  this.props.type === TypeModal.INVITE_EDIT) && (
                  <button
                    type="button"
                    className={`btn btn-danger me-2`}
                    onClick={this.onReject.bind(this)}>
                    {rejectButton()}
                  </button>
                )}
                <button
                  type="button"
                  className={`btn ${this.props.type === TypeModal.DELETE || this.props.type === TypeModal.STOP ? 'btn-danger' : 'btn-primary'}`}
                  disabled={this.props.disableConfirm}
                  onClick={this.onStore.bind(this)}>
                  {confirmButton()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
