import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import ApiProduct from "../../data/ApiProduct";

interface Props {
    onChanged: () => void;
}
class State {
    title: string = '';
}

export default class ModalProductCreate extends React.Component<Props, State> {
    state = new State();


    onChangeTitle(event: any) {
        this.setState({title: event.target.value});
    }

    async onCreateProduct() {
        if (!this.validData()) return false;
        const {title} = this.state;
        const result = await ApiProduct.createProduct(title);
        if (result.code === 0) {
            this.props.onChanged();
        }
        return result.code === 0;
    }
    cancelCreate(){
        this.setState({title: ''});
    }

    validData() {
        const {title} = this.state;
        // 입력내용 누락 확인
        if (title === '') {
            alert('제품명을 입력해주세요.')
            return false;
        }
        return true;
    }

    render() {
        const {title} = this.state;
        return (
            <ModalContainer
                divId={'productCreate'}
                title={'제품 생성'}
                onStore={this.onCreateProduct.bind(this)}
                onCancel={this.cancelCreate.bind(this)}
                type={TypeModal.CREATE}>
                <div>
                    <div className={'container-fluid'}>
                        <div className={'mb-2 row'}>
                            <div className={'col-sm-4 col-form-label'}>
                                제품명
                            </div>
                            <div className={'col-sm-8'}>
                                <textarea
                                    className={'form-control'}
                                    onChange={this.onChangeTitle.bind(this)}
                                    rows={2}
                                    value={title || ''}/>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        )
    }
}
