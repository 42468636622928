import React, {ChangeEvent} from 'react';
import ModalContainer, {TypeModal} from '../ModalContainer';
import {Category, TypeUserLevel, User} from '../../../types';
import Api from '../../../data/Api';

interface Props {
  category: Category[];
  onChanged: () => void;
}
class State {
  category: string = '';
  parent?: number;
}

export default class ModalCategoryCreate extends React.Component<Props, State> {
  state = new State();

  onChangeCategory(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length <= 32) {
      this.setState({category: event.target.value});
    } else {
      window.alert('카테고리명은 최대 32자까지 허용됩니다.');
    }
  }
  onChangeParent(value: number) {
    this.setState({parent: value});
  }

  async onCreateCategory() {
    const {category, parent} = this.state;
    const url = `/api/admin/category`;
    const formData: FormData = new FormData();
    formData.set('category', category);
    if (parent !== undefined) {
      formData.set('parent', parent.toString());
    }
    const result = await Api.post(url, formData);
    if (result.data.code === 0) {
      this.props.onChanged();
    } else if (result.data.code === 103) {
      window.alert('이미 존재하는 카테고리 이름입니다.');
    } else {
      window.alert(`에러가 발생하였습니다. ERROR CODE: ${result.data.code}`);
    }
    return result.data.code === 0;
  }
  onCancel() {
    this.setState({category: '', parent: undefined});
  }

  disabledButton() {
    const {category} = this.state;
    // 입력내용 누락 확인
    if (category === '') {
      return true;
    }
    return false;
  }

  render() {
    const {category, parent} = this.state;
    return (
      <ModalContainer
        divId={'categoryCreate'}
        title={'카테고리 생성'}
        disableConfirm={this.disabledButton()}
        onStore={this.onCreateCategory.bind(this)}
        onCancel={this.onCancel.bind(this)}
        type={TypeModal.CREATE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>카테고리 이름</div>
              <div className={'col-sm-8'}>
                <input
                  className="form-control"
                  placeholder={
                    '카테고리 이름을 입력하세요. 최대 32자 까지 가능합니다. '
                  }
                  value={category}
                  onChange={this.onChangeCategory.bind(this)}
                />
              </div>
            </div>
            {/*<div className={'mb-2 row'}>*/}
            {/*  <div className={'col-sm-4 col-form-label'}>전화번호</div>*/}
            {/*  <div className={'col-sm-8'}>*/}
            {/*    <input*/}
            {/*        onChange={this.onChangeParent.bind(this)}*/}
            {/*        value={parent} />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </ModalContainer>
    );
  }
}
