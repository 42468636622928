import React from 'react';
import TopBar from '../comp/TopBar';
import {Refund, TypeRefundStatus, TypeUserLevel} from '../types';
import RefundCard from '../comp/point/RefundCard';
import RefundDetail from '../comp/point/RefundDetail';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';
import {loadUserInfo, PAGE_URLS} from './PageUtil';
import {FaInfoCircle} from 'react-icons/fa';

const initialDate = new Date();

class State {
  refund: Refund[] = [];
  page = 1;
  size = 20;
  total = 0;
  selected?: Refund;
  loading = false;
  startDate = initialDate;
  endDate = new Date();
}

export default class PageRefundRequests extends React.Component<any, any> {
  state = new State();
  async loadRefundList() {
    const url = `/api/admin/refund/request?status=${TypeRefundStatus.APPLY}`;
    const result: AxiosResponse<ApiResp<Refund[]>> = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({refund: result.data.body});
    } else {
      window.alert(
        `데이터 로드 중 에러가 발생했습니다. ERROR CODE: ${result.data.code}`,
      );
    }
  }
  refreshData() {
    this.loadRefundList().catch(e => {
      window.alert(`데이터 로드 중 문제가 발생했습니다.`);
      return;
    });
    this.setState({selected: undefined});
  }

  onSelectItem(seq: number) {
    for (const r of this.state.refund) {
      if (r.refundUid === seq) {
        this.setState({selected: r});
        break;
      }
    }
  }

  componentDidMount() {
    const user = loadUserInfo();
    const access = user?.level !== TypeUserLevel.sales;
    if (!access) {
      window.location.replace(PAGE_URLS.ACCESS_DENY);
    }
    this.loadRefundList().catch(e => {
      window.alert(`데이터 로드 중 문제가 발생했습니다.`);
      return;
    });
  }

  onClickPage(event: any) {
    const page = event.target.value;
    this.setState({page: page}, () => {
      this.loadRefundList().catch(e => {
        window.alert(`데이터 로드 중 문제가 발생했습니다.`);
        return;
      });
    });
  }

  _renderRefundCard() {
    const {selected} = this.state;
    return this.state.refund.map((value, index) => {
      return (
        <RefundCard
          key={index}
          refund={value}
          selected={selected?.refundUid === value.refundUid}
          onClick={this.onSelectItem.bind(this)}
        />
      );
    });
  }

  render() {
    const pageButtons = [];
    const pageCount = this.state.total / this.state.size + 1;

    for (let i = 1; i <= pageCount; i++) {
      pageButtons.push(
        <button
          key={i}
          type="button"
          className={`btn ${i === this.state.page ? 'btn-secondary' : 'btn-outline-secondary'}`}
          value={i}
          onClick={this.onClickPage.bind(this)}>
          {i}
        </button>,
      );
    }

    return (
      <>
        <TopBar menu={'refund'} />
        <div className={'sp-body-col d-flex'}>
          <div className={'sp-business-list pt-3'}>
            <div
              className={'d-flex justify-content-between'}
              style={{paddingLeft: 10, paddingRight: 10}}>
              <h5>환급요청 목록</h5>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example">
                <a
                  className="btn btn-outline-primary btn-sm"
                  type="button"
                  href={
                    'https://tree-scar-573.notion.site/d3322349184e4e1a98a807082d8457d8?pvs=4'
                  }
                  target={'__blank'}>
                  <FaInfoCircle size={16} className={'sp-manual'} />
                </a>
              </div>
            </div>
            <div className={'mb-3'}>{this._renderRefundCard()}</div>
            <div className={'d-flex justify-content-center'}>
              <div className="btn-group" role="group" aria-label="First group">
                {pageButtons}
              </div>
            </div>
          </div>
          <div className={'sp-business-detail'}>
            {this.state.selected === undefined ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center h-100'
                }>
                <img src={'/assets/money.png'} width={600} alt={'business'} />
              </div>
            ) : (
              <RefundDetail
                refund={this.state.selected}
                onChanged={this.refreshData.bind(this)}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
