import Business from '../../../type_doc';
import React from 'react';
import ModalContainer, {TypeModal} from '../ModalContainer';
import {SearchDict, TypeUserLevel, User} from '../../../types';
import InputSearchUser from '../../../comp/InputSearchUser';

export enum TypeProcessUser {
  SALES = 'processUserSales',
  BIZ = 'processUserManager',
  SUPPLY = 'processUserSupply',
}
interface Props {
  business?: Business;
  type: TypeProcessUser;
  onChanged: () => void;
}
class State {
  user?: string;
  searchResult?: SearchDict<string, User>[];
}

export default class ModalBusinessProcessUser extends React.Component<
  Props,
  State
> {
  state = new State();

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const pBusiness = prevProps.business;
    const nBusiness = this.props.business;
    if (nBusiness && nBusiness.docSeq !== pBusiness?.docSeq) {
      switch (this.props.type) {
        case TypeProcessUser.SALES:
          this.setState({user: nBusiness.userSales});
          break;
        case TypeProcessUser.BIZ:
          this.setState({user: nBusiness.userBiz});
          break;
        case TypeProcessUser.SUPPLY:
          this.setState({user: nBusiness.userSupply});
          break;
      }
    }
  }

  async onEditUser() {
    if (!this.validData()) return false;

    const {user} = this.state;
    let type: 'biz' | 'sales' | 'supply' = 'biz';
    switch (this.props.type) {
      case TypeProcessUser.SALES:
        type = 'sales';
        break;
      case TypeProcessUser.BIZ:
        type = 'biz';
        break;
      case TypeProcessUser.SUPPLY:
        type = 'supply';
        break;
    }
    const result = await this.props.business?.updateManager(type, user!);
    if (result) {
      this.props.onChanged();
    }
    return result !== undefined;
  }
  cancelEdit() {
    switch (this.props.type) {
      case TypeProcessUser.SALES:
        console.log('cancelEdit');
        this.setState({
          user: this.props.business?.userSales,
          searchResult: undefined,
        });
        break;
      case TypeProcessUser.BIZ:
        this.setState({
          user: this.props.business?.userBiz,
          searchResult: undefined,
        });
        break;
      case TypeProcessUser.SUPPLY:
        this.setState({
          user: this.props.business?.userSupply,
          searchResult: undefined,
        });
        break;
    }
  }

  validData() {
    const {user} = this.state;
    if (user === undefined) {
      alert('담당자를 선택해주세요.');
      return false;
    }
    return true;
  }

  onChangeUser(user: (User | string)[]) {
    if (user.length === 0) {
      this.setState({user: undefined});
    } else {
      const u = user[0] as User;
      this.setState({user: u.uid});
    }
    // const uidList = [];
    // for (const u of user) {
    //     if (typeof u !== 'string') {
    //         uidList.push(u.uid);
    //     }
    // }
  }

  render() {
    const {user, searchResult} = this.state;

    const ns =
      JSON.stringify(document) !==
      JSON.stringify(this.props.business?.requirements);
    const title = () => {
      switch (this.props.type) {
        case TypeProcessUser.SALES:
          return '영업담당자 등록';
        case TypeProcessUser.BIZ:
          return '사업담당자 등록';
        case TypeProcessUser.SUPPLY:
          return '물품담당자 등록';
      }
    };
    const level = () => {
      switch (this.props.type) {
        case TypeProcessUser.SALES:
          return TypeUserLevel.sales;
        case TypeProcessUser.BIZ:
          return TypeUserLevel.business;
        case TypeProcessUser.SUPPLY:
          return undefined;
      }
    };
    return (
      <ModalContainer
        divId={this.props.type}
        title={title()}
        onStore={this.onEditUser.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        type={TypeModal.EDIT}>
        <div>
          <div className={'container-fluid'}>
            <div className={'row'} style={{marginBottom: 200}}>
              <div className={'col-sm-4 col-form-label'}>담당자</div>
              <div className={'col-sm-8'}>
                <InputSearchUser
                  onChange={this.onChangeUser.bind(this)}
                  level={level()}
                  single
                  registered
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
