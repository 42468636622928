import React from 'react';
import Business, {TypeBusiness, TypeBusinessStatus} from '../../type_doc';
import {generateBusinessNumber} from '../../pages/PageUtil';
import moment from 'moment';
import {numberWithCommas} from '../../data/DataUtils';

interface Props {
  biz: Business;
  selected: boolean;
  hideCheckbox?: boolean;
  onClick?: (id: number) => void;
}
export default class BizCard extends React.Component<Props, any> {
  render() {
    const {selected} = this.props;
    let type = '';
    switch (this.props.biz.bizType) {
      case TypeBusiness.delivery:
        type = '제품 중개';
        break;
      case TypeBusiness.agency:
        type = '영업 대행';
        break;
    }
    const bId = generateBusinessNumber(this.props.biz.docSeq, '');
    const sum =
      this.props.biz.result.commission.supply +
      this.props.biz.result.commission.tax;
    return (
      <div
        className={'card-business d-flex'}
        onClick={() => this.props.onClick?.(this.props.biz.docSeq)}>
        <div className={'d-flex align-items-center me-2'}>
          <input
            hidden={this.props.hideCheckbox}
            className="form-check-input"
            type="checkbox"
            checked={selected}
          />
        </div>
        <div className={'flex-grow-1'}>
          <div className={'d-flex justify-content-between'}>
            <div className="position-relative">
              <span>{bId}</span>
            </div>
            <div>{numberWithCommas(sum)}포인트</div>
          </div>
          <div className={'d-flex justify-content-between'}>
            <div>
              <span className={'type'}>{`[${type}] `}</span>
              {this.props.biz.bizAbstract}
            </div>
            <div>{moment(this.props.biz.timeUpdate).format('MM/DD HH:mm')}</div>
          </div>
        </div>
      </div>
    );
  }
}
