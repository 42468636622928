import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {TypeUserLevel, User} from '../../types';
import {validationEmail, validationId} from '../PageUtil';
import ApiUser from '../../data/ApiUser';
import InputEmail from '../../comp/InputEmail';
import InputId from '../../comp/InputId';

interface Props {
  onChanged: () => void;
}
class State {
  userId: string = '';
}

export default class ModalUserSalesTempAccount extends React.Component<
  Props,
  State
> {
  state = new State();

  onChangeId(value: string) {
    this.setState({userId: value});
  }

  async onCreateTempAccount() {
    if (!this.validData()) return false;

    const {userId} = this.state;
    const result = await ApiUser.createTempAccount(userId);
    if (result.code === 0) {
      this.props.onChanged();
      window.alert('임시 영업사원 생성을 성공했습니다.');
    } else {
      if (result.code === 106) {
        window.alert('ID 형식이 잘못되었습니다. ')
      } else if (result.code === 104) {
        window.alert('ID 가 이미 존재 합니다.');
      } else {
        window.alert(`초대 중 에러가 발생했습니다. ERROR CODE: ${result.code}`);
      }
    }
    return result.code === 0;
  }
  cancelInvite() {
    this.setState({userId: ''});
  }

  validData() {
    const {userId} = this.state;
    if (userId === '') {
      alert('사용자 ID를 입력해주세요.');
      return false;
    }
    // ID 형식 확인
    else if (!validationId(userId)) {
      alert('ID를 확인해주세요. (영문대소문자, 숫자, _, - 만 허용됩니다)');
      return false;
    }
    return true;
  }

  disableConfirm() {
    const {userId} = this.state;
    if (userId === '' || !validationId(userId)) {
      return true;
    }
    return false;
  }

  render() {
    const {userId} = this.state;
    return (
      <ModalContainer
        divId={'salesTempAccount'}
        title={'임시 영업사원 계정 생성'}
        disableConfirm={this.disableConfirm()}
        onStore={this.onCreateTempAccount.bind(this)}
        onCancel={this.cancelInvite.bind(this)}
        type={TypeModal.INVITE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>임시 ID</div>
              <div className={'col-sm-8'}>
                <InputId onChange={this.onChangeId.bind(this)} value={userId} />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
