import React from 'react';
import {FaBookAtlas} from 'react-icons/fa6';
import {Link} from 'react-router-dom';
import {PAGE_URLS} from '../pages/PageUtil';
import {FaInfoCircle} from 'react-icons/fa';

export default class SideBarUser extends React.Component<any, any> {
  render() {
    const currentPage = window.location.pathname.split('/')[2];
    const buttonClass = (page: 'manager' | 'sales' | 'company' | 'invite') => {
      if (page === currentPage) {
        return 'menu-wrapper menu-wrapper-active';
      } else {
        return 'menu-wrapper';
      }
    };
    return (
      <div className={'sp-left'}>
        <div
          className={'d-flex justify-content-between'}
          style={{paddingLeft: 10, paddingRight: 10}}>
          <div className={'title-wrapper'}>
            <span className={'text'}>사용자 목록</span>
          </div>
          <div className={'title-wrapper'}>
            <div className="btn-group" role="group" aria-label="Basic example">
              <a
                className="btn btn-outline-primary btn-sm"
                type="button"
                href={
                  'https://tree-scar-573.notion.site/1991c64949a04578925722a6d6328036?pvs=4'
                }
                target={'__blank'}>
                <FaInfoCircle size={16} className={'sp-manual'} />
              </a>
            </div>
          </div>
        </div>
        <Link to={PAGE_URLS.USER_MANAGER} className={buttonClass('manager')}>
          <FaBookAtlas size={18} className={'icon'} />
          <span className={'text'}>운용/담당자</span>
        </Link>
        <Link to={PAGE_URLS.USER_SALES} className={buttonClass('sales')}>
          <FaBookAtlas size={18} className={'icon'} />
          <span className={'text'}>영업사원</span>
        </Link>
        <Link to={PAGE_URLS.USER_COMPANY} className={buttonClass('company')}>
          <FaBookAtlas size={18} className={'icon'} />
          <span className={'text'}>파트너 회사</span>
        </Link>
        <Link to={PAGE_URLS.USER_INVITE} className={buttonClass('invite')}>
          <FaBookAtlas size={18} className={'icon'} />
          <span className={'text'}>가입 대기중</span>
        </Link>
      </div>
    );
  }
}
