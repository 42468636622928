import {User, UserInvite} from '../types';
import Api, {ApiResp} from './Api';

export default class ApiUser {
  // static async getSearchUserList(text: string): Promise<ApiResp<dataDict<string, User>[]>> {
  //     const url = `/api/admin/user/info/-/search?text=${text}`;
  //     const res = await Api.get(url);
  //     return res.data;
  // }
  static async getUserAdminList(): Promise<ApiResp<User[]>> {
    const url = `/api/admin/user/list/admin`;
    const res = await Api.get(url);
    return res.data;
  }
  static async getUserSalesList(companySeq?: number): Promise<ApiResp<User[]>> {
    const url = `/api/admin/user/list/sales`;
    let query = '';
    if (companySeq) query += `?company=${companySeq}`;
    const res = await Api.get(url + query);
    return res.data;
  }
  static async getUserInviteList(): Promise<ApiResp<UserInvite[]>> {
    const url = `/api/admin/user/invite`;
    const res = await Api.get(url);
    return res.data;
  }
  static async getInviteRegister(
    mail: string,
    level: string,
    inviteId: string,
  ): Promise<ApiResp<boolean>> {
    const query = `?mail=${mail}&level=${level}&inviteId=${inviteId}`;
    const url = `/api/admin/user/invite/register`;
    const res = await Api.get(url + query);
    return res.data;
  }
  static async createInviteRegister(
    user: User,
    password: string,
    inviteId: string,
  ): Promise<ApiResp<boolean>> {
    const formData = new FormData();
    formData.set('id', user.uid);
    formData.set('mail', user.mail);
    formData.set('password', password);
    formData.set('name', user.name);
    formData.set('tel', user.tel);
    formData.set('level', user.level);
    formData.set('inviteId', inviteId);
    const url = `/api/admin/user/invite/register`;
    const res = await Api.post(url, formData);
    return res.data;
  }
  static async getUser(userUid: string): Promise<ApiResp<User>> {
    const url = `/api/admin/user/info/${userUid}`;
    const res = await Api.get(url);
    return res.data;
  }
  static async createUser(
    user: User,
    password: string,
  ): Promise<ApiResp<boolean>> {
    const formData = new FormData();
    formData.set('id', user.uid);
    formData.set('mail', user.mail);
    formData.set('password', password);
    formData.set('name', user.name);
    formData.set('tel', user.tel);
    formData.set('description', user.description);
    formData.set('level', user.level);
    const url = `/api/admin/user/info`;
    const res = await Api.post(url, formData);
    return res.data;
  }
  static async editUser(user: User): Promise<ApiResp<boolean>> {
    const formData = new FormData();
    formData.set('mail', user.mail);
    formData.set('name', user.name);
    formData.set('tel', user.tel);
    formData.set('description', user.description);
    formData.set('level', user.level);
    const url = `/api/admin/user/info/${user.uid}`;
    const res = await Api.put(url, formData);
    return res.data;
  }
  static async deleteUser(userUid: string): Promise<ApiResp<boolean>> {
    const url = `/api/admin/user/info/${userUid}`;
    const res = await Api.delete(url);
    return res.data;
  }
  static async inviteUser(
    mail: string,
    level: string,
  ): Promise<ApiResp<boolean>> {
    const formData = new FormData();
    formData.set('mail', mail);
    formData.set('level', level);
    const url = `/api/admin/user/invite`;
    const res = await Api.post(url, formData);
    return res.data;
  }
  static async createTempAccount(id: string): Promise<ApiResp<boolean>> {
    const formData = new FormData();
    formData.set('id', id);
    const url = `/api/admin/user/managed`;
    const res = await Api.post(url, formData);
    return res.data;
  }
  static async resendInvite(mail: string): Promise<ApiResp<boolean>> {
    const url = `/api/admin/user/invite/${mail}`;
    const res = await Api.put(url);
    return res.data;
  }
  static async deleteInviteUser(mail: string): Promise<ApiResp<boolean>> {
    const url = `/api/admin/user/invite/${mail}`;
    const res = await Api.delete(url);
    return res.data;
  }
}
