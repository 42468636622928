import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {Company, TypeUserLevel, User} from '../../types';
import ApiCompany from '../../data/ApiCompany';
import {loadUserInfo, validationEmail, validationId, validationPassword, validationTel} from '../PageUtil';
import InputSearchUser from '../../comp/InputSearchUser';
import UserCache from '../../UserCache';
import InputEmail from '../../comp/InputEmail';
import InputE164 from '../../comp/InputE164';
interface Props {
  company?: Company;
  onChanged: () => void;
}
class State {
  workSalesMan: User[] = [];
  workCompany: Company = new Company();
}

export default class ModalUserCompanyEdit extends React.Component<
  Props,
  State
> {
  state = new State();

  async updateWorkSalesMan() {
    const {manager} = this.state.workCompany;
    const salesMan: User[] = [];
    if (manager.length > 0) {
      const users = [];
      for (const m of manager) {
        const user = await UserCache.getInstance().getById(m);
        if (user === undefined) {
          console.error('User not found : ' + m);
        } else {
          salesMan.push(user);
        }
      }
      this.setState({workSalesMan: salesMan});
    }
  }
  componentDidMount() {
    this.updateState();
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.company);
    const pp = JSON.stringify(prevProps.company);
    if (np !== pp) {
      this.updateState();
    }
  }

  updateState() {
    const {company} = this.props;
    console.log('COMPANY: ' + JSON.stringify(company));
    if (company) {
      const newCompany = Object.assign(new Company(), company);
      this.setState({workCompany: newCompany, workSalesMan: []}, () => {
        this.updateWorkSalesMan().catch(e =>
          console.error('FAIL to update MANAGER : ' + e),
        );
      });
    }
  }

  onChangeName(event: any) {
    const {workCompany} = this.state;
    workCompany.name = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeTel(value: string) {
    const {workCompany} = this.state;
    workCompany.tel = value;
    this.setState({workCompany: workCompany});
  }

  onChangeCorpRegNum(event: any) {
    const {workCompany} = this.state;
    workCompany.corpRegNum = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeDescription(event: any) {
    const {workCompany} = this.state;
    workCompany.description = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeEmail(mail: string) {
    const {workCompany} = this.state;
    workCompany.email = mail;
    this.setState({workCompany: workCompany});
  }

  onChangeLocation1(event: any) {
    const {workCompany} = this.state;
    workCompany.location1 = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeLocation2(event: any) {
    const {workCompany} = this.state;
    workCompany.location2 = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeHomepage(event: any) {
    const {workCompany} = this.state;
    workCompany.homepage = event.target.value;
    this.setState({workCompany: workCompany});
  }

  async onEditUser() {
    if (!this.validData()) return false;

    const {workCompany} = this.state;
    const result = await ApiCompany.editCompany(workCompany);
    if (result.code === 0) {
      this.props.onChanged();
    } else if (result.code === 101) {
      window.alert(`입력값을 다시 확인해주세요. CODE(${result.code})`)
    }
    return result.code === 0;
  }
  cancelEdit() {
    this.setState({workCompany: this.props.company!});
  }

  validData() {
    const {workCompany} = this.state;
    // 입력내용 누락 확인
    if (workCompany.name === '') {
      alert('이름을 입력해주세요.');
      return false;
    } else if (workCompany.tel === '') {
      alert('전화번호를 입력해주세요.');
      return false;
    } else if (workCompany.corpRegNum === '') {
      alert('사업자번호를 입력해주세요.');
      return false;
    } else if (workCompany.email === '') {
      alert('이메일 주소를 입력해주세요.');
      return false;
    }
    if (!validationEmail(workCompany.email)) {
      alert('이메일 주소를 확인해주세요.');
      return false;
    }
    if (!validationTel(workCompany.tel)) {
      alert('전화번호를 확인해주세요.');
      return false;
    }
    return true;
  }

  disableConfirm() {
    const {workCompany} = this.state;
    if (workCompany.email === '') {
      return true;
    } else if (workCompany.tel === '') {
      return true;
    } else if (!validationEmail(workCompany.email)) {
      return true;
    } else if (!validationTel(workCompany.tel)) {
      return true;
    }
    console.log('props: ' + JSON.stringify(this.props.company));
    console.log('state: ' + JSON.stringify(workCompany));
    return JSON.stringify(this.props.company) === JSON.stringify(workCompany);
  }

  onChangeUser(user: (User | string)[]) {
    const uidList = [];
    for (const u of user) {
      if (typeof u !== 'string') {
        uidList.push(u.uid);
      }
    }
    const {workCompany} = this.state;
    workCompany.manager = uidList;
    this.setState({workCompany: workCompany});
  }

  render() {
    const myInfo = loadUserInfo();
    if (myInfo === undefined) {
      throw Error('Sign information needed.');
    }
    const {workCompany} = this.state;
    return (
      <ModalContainer
        divId={'companyUpdate'}
        title={'파트너 회사 수정'}
        disableConfirm={this.disableConfirm()}
        onStore={this.onEditUser.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        type={TypeModal.EDIT}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>번호</div>
              <div className={'col-sm-8'}>
                <input
                  disabled
                  type={'text'}
                  className={'form-control'}
                  value={workCompany.seq}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>이름</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeName.bind(this)}
                  value={workCompany.name || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>전화번호</div>
              <div className={'col-sm-8'}>
                <InputE164
                  onChange={this.onChangeTel.bind(this)}
                  value={workCompany.tel}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>사업자 번호</div>
              <div className={'col-sm-8'}>
                <input
                  disabled
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeCorpRegNum.bind(this)}
                  value={workCompany.corpRegNum || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>대표 이메일</div>
              <div className={'col-sm-8'}>
                <InputEmail
                  onChange={this.onChangeEmail.bind(this)}
                  value={workCompany.email}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>회사 위치 1</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeLocation1.bind(this)}
                  value={workCompany.location1 || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>회사 위치 2</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeLocation2.bind(this)}
                  value={workCompany.location2 || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>홈페이지</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeHomepage.bind(this)}
                  value={workCompany.homepage || ''}
                />
              </div>
            </div>
            {myInfo?.level !== TypeUserLevel.sales && (
              <div className={'mb-2 row'}>
                <div className={'col-sm-4 col-form-label'}>담당자</div>
                <div className={'col-sm-8'}>
                  <InputSearchUser
                    onChange={this.onChangeUser.bind(this)}
                    value={this.state.workSalesMan}
                    level={TypeUserLevel.sales}
                    registered
                  />
                </div>
              </div>
            )}
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>메모</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeDescription.bind(this)}
                  value={workCompany.description || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
