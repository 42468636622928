import * as React from 'react';
import TopBar from '../comp/TopBar';
import SideBarUser from '../comp/SideBarUser';
import {Breadcrumb} from '../comp/BreadCrumb';
import DataTable, {Api} from 'datatables.net-dt';
import ApiUser from '../data/ApiUser';
import {TypeUserLevel, UserInvite} from '../types';
import {renderToString} from 'react-dom/server';
import ModalUserInviteEdit from './modal/ModalUserInviteEdit';
import moment from 'moment/moment';

class State {
  selected?: UserInvite;
  users: UserInvite[] = [];
}
export default class PageUserInvite extends React.Component<any, State> {
  state = new State();

  managerTable?: Api<any>;

  onSelectTableRow(event: Event) {
    const target = event.currentTarget;
    if (target !== null) {
      const row = event.currentTarget as HTMLTableRowElement;
      if (row.classList.contains('selected')) {
        row.classList.remove('selected');
        this.setState({selected: undefined});
      } else {
        this.managerTable
          ?.rows('.selected')
          .nodes()
          .each(row => row.classList.remove('selected'));
        row.classList.add('selected');
        const mail = row.cells[0].innerText;
        this.state.users.filter(value => {
          if (value.mail === mail) {
            this.setState({selected: value});
          }
        });
      }
    }
  }

  componentDidMount() {
    this.initManagerTable();
    this.loadData().catch(e => console.error(e));
  }
  async loadData() {
    const result = await ApiUser.getUserInviteList();
    this.setState({users: result.body, selected: undefined});

    this.loadDataTable(result.body);
  }
  refreshData() {
    this.loadData().catch(e => console.error(e));
  }

  initManagerTable() {
    const element = document.getElementById('invite_list');
    if (element === null) {
      throw Error('Manager list not exist');
    } else {
      if (this.managerTable === undefined) {
        this.managerTable = new DataTable(element as HTMLTableElement, {});
        this.managerTable?.on(
          'click',
          'tbody tr',
          this.onSelectTableRow.bind(this),
        );
      }
    }
  }

  loadDataTable(users: UserInvite[]) {
    const table = this.managerTable;
    table?.rows().clear();
    console.log('users: ' + JSON.stringify(users));
    for (const item of users) {
      const value = item as UserInvite;
      let badgeClass = '';
      let levelName = '';
      switch (value.level) {
        case TypeUserLevel.admin:
          badgeClass = 'text-bg-danger';
          levelName = '관리자';
          break;
        case TypeUserLevel.operator:
          badgeClass = 'text-bg-info';
          levelName = '운용자';
          break;
        case TypeUserLevel.business:
          badgeClass = 'text-bg-success';
          levelName = '사업담당자';
          break;
        case TypeUserLevel.purchase:
          badgeClass = 'text-bg-warning';
          levelName = '구매담당자';
          break;
        case TypeUserLevel.sales:
          badgeClass = 'text-bg-warning';
          levelName = '영업사원';
          break;
      }

      const level = (
        <td>
          <div className={`badge rounded-pill ${badgeClass}`}>{levelName}</div>
        </td>
      );

      const timeCreate = moment(value.timeCreate).format('YYYY-MM-DD HH:mm:ss');
      const timeSent = moment(value.timeSent).format('YYYY-MM-DD HH:mm:ss');

      table?.row.add([value.mail, renderToString(level), timeCreate, timeSent]);
    }
    table?.draw(false);
    if (this.state.selected !== undefined) {
      console.log(table?.row(this.state.selected.mail));
    }
  }

  render() {
    const {selected} = this.state;
    return (
      <div>
        <TopBar menu={'user'} />
        <SideBarUser />
        <div className={'sp-right'}>
          <Breadcrumb path={['user', 'pending']} />
          <>
            <div
              className={
                'card-datatable-header d-flex justify-content-between'
              }>
              <span>{selected && `${selected.mail}`}</span>
              <div className="btn-group btn-group-sm">
                <button
                  type={'button'}
                  disabled={selected === undefined}
                  data-bs-toggle="modal"
                  data-bs-target="#inviteEdit"
                  className={'btn btn-sm btn-outline-primary'}>
                  수정
                </button>
              </div>
            </div>
            <table id={'invite_list'} className={'datatable-table sp-table'}>
              <thead>
                <tr>
                  <th style={{maxWidth: 150}}>메일</th>
                  <th style={{maxWidth: 100}}>구분</th>
                  <th>최초 초대시각</th>
                  <th>최종 초대시각</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </>
        </div>
        <ModalUserInviteEdit
          onChanged={this.refreshData.bind(this)}
          user={this.state.selected}
        />
      </div>
    );
  }
}
