import React, {CSSProperties} from 'react';
import {numberWithCommas} from '../data/DataUtils';

interface Props {
  disabled?: boolean;
  style?: CSSProperties | undefined;
  className?: string;
  value?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
}
export default class InputNumberWithComma extends React.Component<Props, any> {
  onChange(value: string) {
    const {onChange} = this.props;
    const result = value.replace(/\D/g, '');
    const number = parseInt(result);
    if (onChange) onChange(number);
  }
  render() {
    const {disabled, style, className, value, placeholder} = this.props;
    return (
      <input
        disabled={disabled}
        style={style}
        type="text"
        placeholder={placeholder}
        className={'form-control form-control-sm text-end ' + className}
        onChange={event => {
          this.onChange(event.target.value);
        }}
        value={numberWithCommas(value || 0)}
      />
    );
  }
}
