import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {TypeUserLevel, User} from '../../types';
import {validationEmail} from '../PageUtil';
import ApiUser from '../../data/ApiUser';
import InputEmail from '../../comp/InputEmail';

interface Props {
  onChanged: () => void;
}
class State {
  mail: string = '';
}

export default class ModalUserSalesInvite extends React.Component<
  Props,
  State
> {
  state = new State();

  onChangeEmail(value: string) {
    this.setState({mail: value});
  }

  async onInviteUser() {
    if (!this.validData()) return false;

    const {mail} = this.state;
    const result = await ApiUser.inviteUser(mail, TypeUserLevel.sales);
    if (result.code === 0) {
      this.props.onChanged();
      window.alert('초대를 성공했습니다.');
    } else {
      if (result.code === 102) {
        window.alert('이미 등록된 사용자입니다.');
      } else if (result.code === 103) {
        window.alert('이미 초대된 사용자입니다.');
      } else {
        window.alert(`초대 중 에러가 발생했습니다. ERROR CODE: ${result.code}`);
      }
    }
    return result.code === 0;
  }
  cancelInvite() {
    this.setState({mail: ''});
  }

  validData() {
    const {mail} = this.state;
    if (mail === '') {
      alert('이메일 주소를 입력해주세요.');
      return false;
    }
    if (!validationEmail(mail)) {
      alert('이메일 주소를 확인해주세요.');
      return false;
    }
    return true;
  }

  disableConfirm() {
    const {mail} = this.state;
    if (mail === '') {
      return true;
    }
    return false;
  }

  render() {
    const {mail} = this.state;
    return (
      <ModalContainer
        divId={'salesInvite'}
        title={'영업사원 초대'}
        disableConfirm={this.disableConfirm()}
        onStore={this.onInviteUser.bind(this)}
        onCancel={this.cancelInvite.bind(this)}
        type={TypeModal.INVITE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>이메일 주소</div>
              <div className={'col-sm-8'}>
                <InputEmail
                  onChange={this.onChangeEmail.bind(this)}
                  value={mail}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
