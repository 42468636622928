import Business, {ProductTuple} from '../../../type_doc';
import React from 'react';
import ModalContainer, {TypeModal} from '../ModalContainer';
import InputNumberWithComma from '../../../comp/InputNumberWithComma';

interface Props {
  business?: Business;
  onChanged: () => void;
}
class State {
  products?: ProductTuple[];
}

export default class ModalBusinessDeliveryList extends React.Component<
  Props,
  State
> {
  state = new State();

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.business);
    const pp = JSON.stringify(prevProps.business);
    if (np !== pp) {
      this.updateState();
    }
  }

  updateState() {
    const {business} = this.props;
    if (business) {
      const newBiz = Object.assign(new Business(), business);
      this.setState({products: newBiz.deliveryList});
    }
  }

  async onEditDeliveryList() {
    if (!this.validData()) return false;

    const {products} = this.state;
    if (this.props.business === undefined) return false;
    if (products === undefined) return false;

    const result = await this.props.business.updateDeliveryList(products);
    if (result) {
      this.props.onChanged();
    }
    return result !== undefined;
  }
  cancelEdit() {
    this.setState({
      products: this.props.business?.deliveryList,
    });
  }

  validData() {
    return true;
  }

  onChangeProduct(seq: number, value: string) {
    const {products} = this.state;
    if (products === undefined) return;
    products[seq].title = value;
    this.setState({products: products});
  }

  onChangeAmount(seq: number, value: number) {
    const {products} = this.state;
    if (products === undefined) return;
    products[seq].amount = value;
    this.setState({products: products});
  }

  render() {
    const {products} = this.state;

    const ns =
      JSON.stringify(document) !==
      JSON.stringify(this.props.business?.requirements);

    const input = products?.map((value, index) => {
      return (
        <div key={index} className={'row'}>
          <div className={'col'}>
            <div className={'input-group '}>
              <textarea
                className={'form-control form-control-sm'}
                onChange={(event: any) => {
                  this.onChangeProduct(index, event.target.value);
                }}
                value={value.title}
              />
              <InputNumberWithComma
                disabled
                style={{maxWidth: 50}}
                onChange={value => {
                  this.onChangeAmount(index, value);
                }}
                value={value.amount}
              />
            </div>
          </div>
        </div>
      );
    });
    return (
      <ModalContainer
        divId={'updateDeliveryList'}
        title={'납품 목록'}
        onStore={this.onEditDeliveryList.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        type={TypeModal.EDIT}>
        <div>
          <div className={'container-fluid'}>
            <div className="input-group mb-1">
              <div style={{flex: 1}}>{input}</div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
