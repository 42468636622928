import React from 'react';
import Business, {TypeBusiness, TypeBusinessStatus} from '../../type_doc';
import {generateBusinessNumber, loadUserInfo} from '../../pages/PageUtil';
import {numberWithCommas} from '../../data/DataUtils';
import BusinessInfoItem from './BusinessInfoItem';
import {TypeView} from '../../pages/PageBusiness';
import DocBizAbstract from '../../doc/DocBizAbstract';
import DocRequirements from '../../doc/DocRequirements';
import DocViewer from '../../doc/DocViewer';
import ModalBusinessAbstractEdit from '../../pages/modal/ModalBusiness/ModalBusinessAbstractEdit';
import ModalBusinessListProduct from '../../pages/modal/ModalBusiness/ModalBusinessListProduct';
import ModalBusinessProcessQuotation from '../../pages/modal/ModalBusiness/ModalBusinessProcessQuotation';
import ModalBusinessProcessOrder from '../../pages/modal/ModalBusiness/ModalBusinessProcessOrder';
import ModalBusinessProcessTax, {
  TypeProcessTax,
} from '../../pages/modal/ModalBusiness/ModalBusinessProcessTax';
import ModalBusinessProcessUser, {
  TypeProcessUser,
} from '../../pages/modal/ModalBusiness/ModalBusinessProcessUser';
import BusinessStep from './BusinessStep';
import {BizMinute, TypeUserLevel, User} from '../../types';
import UserCache from '../../UserCache';
import DocQuotation from '../../doc/DocQuotation';
import Api, {ApiResp} from '../../data/Api';
import {AxiosResponse} from 'axios';
import ModalContainer, {TypeModal} from '../../pages/modal/ModalContainer';
import DocBizMinute from '../../doc/DocBizMinute';
import DocDelivery from '../../doc/DocDelivery';

interface Props {
  business: Business;
  onChanged: () => void;
}
class State {
  view: TypeView = TypeView.bizAbstract;
  userBiz?: User;
  userSales?: User;
  userSupply?: User;
  bizMinutes?: BizMinute[];
}
export default class BusinessDetail extends React.Component<Props, State> {
  state = new State();

  refModalTaxPurchase: any;
  refModalTaxSales: any;

  refreshData() {
    this.props.onChanged();
  }

  async loadBusinessMinutes(seq: number) {
    const url = `/api/admin/biz/${seq}/minutes`;
    const result = await Api.get(url);
    if (result.data.code === 0) {
      for (const d of result.data.body) {
        d.creator = await UserCache.getInstance().getById(d.userCreator);
      }
      return result.data.body;
    } else {
      window.alert(
        `회의록 데이터 로드에 실패 했습니다. CODE(${result.data.code})`,
      );
      return [];
    }
  }

  onClickType(type: TypeBusiness) {
    const {business} = this.props;
    if (business.bizType === type) return;
    business
      .updateBizType(type)
      .then(result => {
        this.props.onChanged();
      })
      .catch(e => {
        console.error(e);
        window.alert(e);
      });
  }

  componentDidMount() {
    this.refreshDetail().catch(e =>
      console.error('fail to get user data : ' + JSON.stringify(e)),
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.business);
    const pp = JSON.stringify(prevProps.business);
    if (np !== pp) {
      this.refreshDetail().catch(e =>
        console.error('fail to get user data : ' + JSON.stringify(e)),
      );
    }
  }

  async refreshDetail() {
    const {business} = this.props;
    let biz, supply, sales;
    if (business.userBiz !== undefined) {
      biz = await UserCache.getInstance().getById(business.userBiz);
    }
    if (business.userSupply !== undefined) {
      supply = await UserCache.getInstance().getById(business.userSupply);
    }
    if (business.userSales !== undefined) {
      sales = await UserCache.getInstance().getById(business.userSales);
    }

    const bizMinutes = await this.loadBusinessMinutes(business.docSeq);

    this.setState({
      userBiz: biz,
      userSales: sales,
      userSupply: supply,
      bizMinutes: bizMinutes,
    });
  }

  checkClosed() {
    const biz = this.props.business;
    const sess = loadUserInfo();
    if (sess === undefined) return false;
    switch (sess.level) {
      case TypeUserLevel.admin:
        break;
      case TypeUserLevel.business:
        if (biz.userBiz === sess.uid) break;
        else return false;
      default:
        return false;
    }
    if (biz.bizStep !== TypeBusinessStatus.calculate) return false;
    if (biz.commissionRate === undefined) return false;
    if (biz.taxSales === undefined) return false;
    if (biz.taxPurchase === undefined) return false;
    if (biz.delivery === undefined) return false;
    if (biz.userSales === undefined) return false;
    if (biz.userBiz === undefined) return false;
    if (biz.order === undefined) return false;
    return true;
  }

  async onCloseBiz() {
    const biz = this.props.business;
    const url = `/api/admin/biz/${biz.docSeq}/close`;
    const result: AxiosResponse<ApiResp<boolean>> = await Api.put(url, {});
    if (result.data.code === 0) {
      this.refreshData();
      return true;
    } else {
      console.error('FAil to close business : ' + result.data.reason);
      return false;
    }
  }

  render() {
    const {business} = this.props;
    const {view, bizMinutes} = this.state;
    const year = business.timeCreate.substring(0, 4);
    const isDelivery = business.bizType === TypeBusiness.delivery;

    let detailView = <></>;
    const seq = business.docSeq;
    switch (view) {
      case TypeView.bizAbstract:
        detailView = <DocBizAbstract business={business} />;
        break;
      case TypeView.listProduct:
        detailView = (
          <DocRequirements
            business={business}
            onChanged={this.props.onChanged.bind(this)}
          />
        );
        break;
      case TypeView.docQuotation:
        detailView = (
          <DocQuotation
            business={business}
            onChanged={this.props.onChanged.bind(this)}
          />
        );
        break;
      case TypeView.docOrder:
        detailView = (
          <DocViewer type={'order'} business={business} title={'발주서'} />
        );
        break;
      case TypeView.docDelivery:
        detailView = (
          <DocDelivery
            business={business}
            onChanged={this.props.onChanged.bind(this)}
          />
        );
        break;
      case TypeView.docTaxPurchase:
        detailView = (
          <DocViewer
            type={'taxPurchase'}
            business={business}
            title={'매입 세금계산서'}
          />
        );
        break;
      case TypeView.docTaxSales:
        detailView = (
          <DocViewer
            type={'taxSales'}
            business={business}
            title={'매출 세금계산서'}
          />
        );
        break;
      case TypeView.listMinute:
        detailView = (
          <DocBizMinute
            business={business}
            bizMinutes={bizMinutes}
            onChanged={this.props.onChanged.bind(this)}
          />
        );
        break;
    }

    const bizClosed = this.checkClosed();

    const result = business.result;
    const incomeSupply = result.sales.supply - result.purchase.supply;
    const cRate =
      business.commissionRate === undefined ? 0 : business.commissionRate / 100;
    const sumCommisionSupply = incomeSupply > 0 ? incomeSupply * cRate : 0;
    const eNetIncomeSupply = incomeSupply - sumCommisionSupply;

    // ----------------------------------------------------------------
    // Calculate commission price for sales man  -- BEGIN --
    const biz = this.props.business;
    const totalSales = biz.getRequirements().getSum();
    const totalPurchase = biz.getRequirements().getPurchaseSum();
    const totalIncome = totalSales - totalPurchase;
    let commissionEstimate = 0;

    switch (this.props.business.bizStep) {
      case TypeBusinessStatus.initial:
      case TypeBusinessStatus.quoteReq:
        break;
      case TypeBusinessStatus.quoteRes:
      case TypeBusinessStatus.supply:
      case TypeBusinessStatus.calculate:
      case TypeBusinessStatus.close:
      case TypeBusinessStatus.done:
        if (biz.commissionRate === undefined) {
          console.log('Commission rate not assigned.');
        } else {
          commissionEstimate = (totalIncome * biz.commissionRate) / 100;
        }
        break;
      case TypeBusinessStatus.cancel:
        break;
    }
    // Calculate commission price for sales man  -- END --
    // ----------------------------------------------------------------

    return (
      <div className={'p-3'}>
        <div style={{height: 10}} />
        <div className={'business-header d-flex justify-content-between mb-3'}>
          <div className={'btn btn-sm fw-bold'}>
            {generateBusinessNumber(business.docSeq, year)}
          </div>
          <div className={'gap-2'}>
            <div className="btn-group" role="group" aria-label="Basic example">
              {business.checkEditPossible('cancel') && (
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#processCancel"
                  className="btn btn-sm btn-outline-danger">
                  사업 중단
                </button>
              )}
            </div>
          </div>
        </div>
        {business.userBiz === undefined && (
          <div className="alert alert-danger" role="alert">
            사업담당자 지정이 필요합니다.
          </div>
        )}
        {business.userSales === undefined && (
          <div className="alert alert-danger" role="alert">
            영업담당자 지정이 필요합니다.
          </div>
        )}
        <div className={'container-fluid'}>
          <div className={'row biz-detail'}>
            <div className="row">
              <label className="col-sm-2 col-form-label">단계</label>
              <div className="col-sm-10">
                <BusinessStep type={business.bizType} step={business.bizStep} />
              </div>
            </div>
          </div>
          <div className={'row biz-detail'}>
            <div className={'col-4'}>
              <div className={'row'}>
                <hr />
              </div>
              <div className="row">
                <label className="col-sm-4 col-form-label">사업 타입</label>
                <div className="col-sm-8 col-form-label">
                  <label>
                    {business.bizType === TypeBusiness.delivery
                      ? '제품 중개'
                      : '영업 대행'}
                  </label>
                </div>
              </div>
              <BusinessInfoItem
                title={'사업개요'}
                onClickTitle={() => {
                  this.setState({view: TypeView.bizAbstract});
                }}
                editModalId={
                  business.checkEditPossible('abstract')
                    ? 'bizAbstract'
                    : undefined
                }
                act={view === TypeView.bizAbstract}>
                {business.getTitle()}
              </BusinessInfoItem>
              <BusinessInfoItem
                title={'영업 담당자'}
                editModalId={
                  business.checkEditPossible('salesMgr')
                    ? 'processUserSales'
                    : undefined
                }>
                {this.state.userSales !== undefined
                  ? this.state.userSales.name
                  : ''}
              </BusinessInfoItem>
              <BusinessInfoItem
                title={'사업 담당자'}
                editModalId={
                  business.checkEditPossible('bizMgr')
                    ? 'processUserManager'
                    : undefined
                }>
                {this.state.userBiz !== undefined
                  ? this.state.userBiz.name
                  : ''}
              </BusinessInfoItem>
              {/*<BusinessInfoItem*/}
              {/*  title={'물품 담당자'}*/}
              {/*  editModalId={business.checkEditPossible('productMgr') ? 'processUserSupply' : undefined}*/}
              {/*  >*/}
              {/*    {this.state.userSupply !== undefined ? this.state.userSupply.name : ''}*/}
              {/*</BusinessInfoItem>*/}
              <BusinessInfoItem title={'영업 수수료'}>
                {`₩${numberWithCommas(commissionEstimate)}`}
              </BusinessInfoItem>
              <BusinessInfoItem
                title={'물품목록'}
                editModalId={
                  business.checkEditPossible('quoteReg')
                    ? 'listProduct'
                    : undefined
                }
                disabled={!business.checkViewPossible('quoteReg')}
                act={view === TypeView.listProduct}
                onClickTitle={() => {
                  this.setState({view: TypeView.listProduct});
                }}>
                {`${numberWithCommas(business.requirements.productList.length)} 건`}
              </BusinessInfoItem>
              {isDelivery && (
                <>
                  <BusinessInfoItem
                    title={'견적 승인 (견적서)'}
                    editModalId={
                      business.checkEditPossible('quoteRes')
                        ? 'processQuotation'
                        : undefined
                    }
                    disabled={!business.checkViewPossible('quoteRes')}
                    act={view === TypeView.docQuotation}
                    onClickTitle={() => {
                      this.setState({view: TypeView.docQuotation});
                    }}>
                    {business.quotation.length > 0 &&
                    business.bizStep !== TypeBusinessStatus.initial
                      ? '등록'
                      : ''}
                  </BusinessInfoItem>
                  <BusinessInfoItem
                    title={'발주 정보 (발주서)'}
                    editModalId={
                      business.checkEditPossible('orderInfo')
                        ? 'processOrder'
                        : undefined
                    }
                    disabled={!business.checkViewPossible('orderInfo')}
                    act={view === TypeView.docOrder}
                    onClickTitle={() => {
                      this.setState({view: TypeView.docOrder});
                    }}>
                    {business.order !== undefined ? (
                      <span>등록</span>
                    ) : (
                      <span>미등록</span>
                    )}
                  </BusinessInfoItem>
                  <BusinessInfoItem
                    title={'납품 확인서'}
                    disabled={!business.checkViewPossible('delivery')}
                    act={view === TypeView.docDelivery}
                    onClickTitle={() => {
                      this.setState({view: TypeView.docDelivery});
                    }}>
                    {business.delivery !== undefined ? (
                      <span>등록</span>
                    ) : (
                      <span>미등록</span>
                    )}
                  </BusinessInfoItem>
                  <BusinessInfoItem
                    title={'세금계산서-매입'}
                    onClickEdit={() => {
                      this.refModalTaxPurchase.initState();
                    }}
                    editModalId={
                      business.checkEditPossible('taxPurchase')
                        ? 'processTaxPurchase'
                        : undefined
                    }
                    disabled={!business.checkViewPossible('taxPurchase')}
                    act={view === TypeView.docTaxPurchase}
                    onClickTitle={() => {
                      this.setState({view: TypeView.docTaxPurchase});
                    }}>
                    {business.taxPurchase !== undefined ? (
                      <span>등록</span>
                    ) : (
                      <span>미등록</span>
                    )}
                  </BusinessInfoItem>
                  <BusinessInfoItem
                    title={'세금계산서-매출'}
                    onClickEdit={() => {
                      this.refModalTaxSales.initState();
                    }}
                    act={view === TypeView.docTaxSales}
                    editModalId={
                      business.checkEditPossible('taxSale')
                        ? 'processTaxSales'
                        : undefined
                    }
                    disabled={!business.checkViewPossible('taxSale')}
                    onClickTitle={() => {
                      this.setState({view: TypeView.docTaxSales});
                    }}>
                    {business.taxSales !== undefined ? (
                      <span>등록</span>
                    ) : (
                      <span>미등록</span>
                    )}
                  </BusinessInfoItem>
                </>
              )}
              <BusinessInfoItem
                title={'회의록'}
                onClickTitle={() => {
                  this.setState({view: TypeView.listMinute});
                }}>
                {''}
              </BusinessInfoItem>

              {/* 2024.04.04 [김동욱] 하기 코드 확인 필요. */}
              {!isDelivery && (
                <>
                  <BusinessInfoItem
                    title={'세금계산서-수수료 매출'}
                    editModalId={
                      business.checkEditPossible('taxSale')
                        ? 'processTaxCharge'
                        : undefined
                    }>
                    <>
                      <input
                        type="text"
                        className="form-control-plaintext"
                        id="inputSales"
                        // value={'2024.03.03 12:12:12'}
                      />
                      <input
                        type="text"
                        className="form-control-plaintext"
                        id="inputSales"
                        // value={'&#8361;123,123,123'}
                      />
                    </>
                  </BusinessInfoItem>
                </>
              )}
              {bizClosed && (
                <>
                  <div className={'row'}>
                    <div className={'col'}>
                      <hr />
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col d-flex'}>
                      <button
                        className={'btn btn-primary'}
                        data-bs-toggle="modal"
                        data-bs-target="#closeBizConfirm"
                        style={{flex: 1}}>
                        마감
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={'col-8 mt-3'} style={{paddingLeft: 20}}>
              {detailView}
            </div>
          </div>
        </div>
        <ModalBusinessAbstractEdit
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        <ModalBusinessListProduct
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        <ModalBusinessProcessQuotation
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        <ModalBusinessProcessOrder
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        <ModalBusinessProcessTax
          ref={comp => {
            if (comp !== null) {
              this.refModalTaxPurchase = comp;
            }
          }}
          type={TypeProcessTax.PURCHASE}
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        <ModalBusinessProcessTax
          ref={comp => {
            if (comp !== null) {
              this.refModalTaxSales = comp;
            }
          }}
          type={TypeProcessTax.SALES}
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        <ModalBusinessProcessUser
          type={TypeProcessUser.SALES}
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        <ModalBusinessProcessUser
          type={TypeProcessUser.BIZ}
          onChanged={this.refreshData.bind(this)}
          business={business}
        />
        {/*<ModalBusinessProcessUser*/}
        {/*  type={TypeProcessUser.SUPPLY}*/}
        {/*  onChanged={this.refreshData.bind(this)}*/}
        {/*  business={business}*/}
        {/*/>*/}
        {/*<ModalBusinessCommissionRate*/}
        {/*  onChanged={this.refreshData.bind(this)}*/}
        {/*  business={business}*/}
        {/*/>*/}
        <ModalContainer
          divId={'closeBizConfirm'}
          title={'사업 마감'}
          type={TypeModal.CONFIRM}
          onStore={this.onCloseBiz.bind(this)}>
          <>
            <table className={'table table-sm table-striped table-bordered'}>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>공급가</th>
                  <th>세금</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>견적금액</th>
                  <td className={'text-end'}>
                    {numberWithCommas(business.quotation[0]?.priceSupply || 0)}
                  </td>
                  <td className={'text-end'}>
                    {numberWithCommas(business.quotation[0]?.priceTax || 0)}
                  </td>
                </tr>
                <tr>
                  <th>발주금액</th>
                  <td className={'text-end'}>
                    {numberWithCommas(business.result.order.supply)}
                  </td>
                  <td className={'text-end'}>
                    {numberWithCommas(business.result.order.tax)}
                  </td>
                </tr>
                <tr>
                  <th>매입금액</th>
                  <td className={'text-end'}>
                    {numberWithCommas(business.result.purchase.supply)}
                  </td>
                  <td className={'text-end'}>
                    {numberWithCommas(business.result.purchase.tax)}
                  </td>
                </tr>
                <tr>
                  <th>매출금액</th>
                  <td className={'text-end'}>
                    {numberWithCommas(business.result.sales.supply)}
                  </td>
                  <td className={'text-end'}>
                    {numberWithCommas(business.result.sales.tax)}
                  </td>
                </tr>
                <tr>
                  <th>예상 순이익</th>
                  <th
                    className={'text-end'}
                    style={{color: eNetIncomeSupply < 0 ? 'red' : 'black'}}>
                    {numberWithCommas(eNetIncomeSupply)}
                  </th>
                  <td className={'text-end'}>-</td>
                </tr>
                <tr>
                  <th>예상 수수료 ({business.commissionRate}%)</th>
                  <td
                    className={'text-end'}
                    style={{color: eNetIncomeSupply < 0 ? 'red' : 'black'}}>
                    {numberWithCommas(sumCommisionSupply)}
                  </td>
                  <td className={'text-end'}>-</td>
                </tr>
              </tbody>
            </table>
            <span>마감을 하시면, 더 이상 수정을 할 수 없습니다.</span>
          </>
        </ModalContainer>
      </div>
    );
  }
}
