import Api, {ApiResp} from './Api';
import {Activity, Privacy, TypeUserLevel} from '../types';
import {loadUserInfo} from '../pages/PageUtil';
import {AxiosResponse} from 'axios';

export default class ApiSettings {
  static async getUserPrivacy(uid: string): Promise<ApiResp<Privacy>> {
    const url = `/api/admin/privacy/${uid}`;
    const res = await Api.get(url);
    console.log('res: ' + JSON.stringify(res));
    return res.data;
  }

  static async activityRecent(uid?: string) {
    const me = loadUserInfo();
    let url = '';
    switch (me?.level) {
      case TypeUserLevel.admin:
      case TypeUserLevel.operator:
        if (uid === undefined) {
          url = `/api/admin/activity?all=true&size=50`;
        } else {
          url = `/api/admin/activity?all=true&size=50&userUid=${uid}`;
        }
        break;
      default:
        url = `/api/admin/activity?&size=50`;
    }

    const res: AxiosResponse<ApiResp<Activity[]>> = await Api.get(url);
    if (res.data.code === 0) {
      return res.data.body.map(value => {
        return Object.assign(new Activity(), value);
      });
    }
    window.alert(
      `활동 이력을 조회하는데 오류가 발생했습니다. ERROR CODE: ${res.data.code}`,
    );
    throw Error(`${res.data.code}`);
  }
}
