import * as React from 'react';
import TopBar from '../comp/TopBar';
import SideBarUser from '../comp/SideBarUser';
import {Breadcrumb} from '../comp/BreadCrumb';
import DataTable, {Api} from 'datatables.net-dt';
import {convertUtcToLocal, numberWithCommas} from '../data/DataUtils';
import {User} from '../types';
import ApiUser from '../data/ApiUser';
import {renderToString} from 'react-dom/server';
import ModalUserSalesCreate from './modal/ModalUserSalesCreate';
import ModalUserSalesEdit from './modal/ModalUserSalesEdit';
import ModalUserSalesDelete from './modal/ModalUserSalesDelete';
import ModalUserSalesInvite from './modal/ModalUserSalesInvite';
import ModalUserSalesTempAccount from './modal/ModalUserSalesTempAccount';

class State {
  selected?: User;
  selectedName?: string;
  users: User[] = [];
}
export default class PageUserSales extends React.Component<any, State> {
  state = new State();

  salesTable?: Api<any>;

  onSelectTableRow(event: Event) {
    const target = event.currentTarget;
    if (target !== null) {
      const row = event.currentTarget as HTMLTableRowElement;
      if (row.classList.contains('selected')) {
        row.classList.remove('selected');
        this.setState({selected: undefined});
      } else {
        this.salesTable
          ?.rows('.selected')
          .nodes()
          .each(row => row.classList.remove('selected'));
        row.classList.add('selected');
        const uid = row.cells[0].innerText;
        this.state.users.filter(value => {
          if (value.uid === uid) {
            if (value.company === undefined) {
              value.company = '';
            }
            this.setState({selected: value, selectedName: value.name});
          }
        });
      }
    }
  }

  componentDidMount() {
    this.initSalesTable();
    this.loadData().catch(e => console.error(e));
  }
  async loadData() {
    const result = await ApiUser.getUserSalesList();
    this.setState({users: result.body, selected: undefined});
    this.loadDataTable(result.body);
  }
  refreshData() {
    this.loadData().catch(e => console.error(e));
  }

  initSalesTable() {
    const element = document.getElementById('sales_list');
    if (element === null) {
      throw Error('Sales list not exist');
    } else {
      if (this.salesTable === undefined) {
        this.salesTable = new DataTable(element as HTMLTableElement, {});
        this.salesTable?.on(
          'click',
          'tbody tr',
          this.onSelectTableRow.bind(this),
        );
      }
    }
  }

  loadDataTable(users: User[]) {
    const table = this.salesTable;
    table?.rows().clear();
    for (const item of users) {
      const value = item;
      const work =
        value.works > 0 ? (
          <span className="badge text-bg-danger">{value.works}</span>
        ) : (
          <span>&nbsp;</span>
        );
      const userDesc = value.managed ? (
        <>
          <span>{value.description} </span>
          <span className="badge text-bg-primary">
            만료: {convertUtcToLocal(value.expire)}
          </span>
        </>
      ) : (
        <span>{value.description} </span>
      );
      table?.row.add([
        value.uid,
        value.name,
        renderToString(work),
        numberWithCommas(value.point),
        value.mail,
        value.tel,
        renderToString(userDesc),
      ]);
    }
    table?.draw(false);
  }

  render() {
    const {selected, selectedName} = this.state;
    return (
      <div>
        <TopBar menu={'user'} />
        <SideBarUser />
        <div className={'sp-right'}>
          <Breadcrumb path={['user', 'sales']} />
          <>
            <div
              className={
                'card-datatable-header d-flex justify-content-between'
              }>
              <span>{selected && `${selected.mail} (${selectedName})`}</span>
              <div className="btn-group btn-group-sm">
                <button
                  type={'button'}
                  data-bs-toggle="modal"
                  data-bs-target="#salesTempAccount"
                  className={'btn btn-sm btn-outline-primary'}>
                  임시계정 생성
                </button>
                <button
                  type={'button'}
                  data-bs-toggle="modal"
                  data-bs-target="#salesInvite"
                  className={'btn btn-sm btn-outline-primary'}>
                  초대
                </button>
                <button
                  type={'button'}
                  data-bs-toggle="modal"
                  data-bs-target="#salesCreate"
                  className={'btn btn-sm btn-outline-primary'}>
                  생성
                </button>
                {selected && (
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#salesUpdate"
                    className={'btn btn-sm btn-outline-primary'}>
                    수정
                  </button>
                )}
                {selected && (
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#salesDelete"
                    className={'btn btn-sm btn-outline-primary'}>
                    삭제
                  </button>
                )}
              </div>
            </div>
            <table id={'sales_list'} className={'datatable-table sp-table'}>
              <thead>
                <tr>
                  <th style={{maxWidth: 100}}>아이디</th>
                  <th style={{maxWidth: 100}}>이름</th>
                  <th style={{maxWidth: 50}}>대기업무</th>
                  <th style={{maxWidth: 80}}>포인트</th>
                  <th style={{maxWidth: 150}}>메일</th>
                  <th>전화번호</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </>
        </div>
        <ModalUserSalesTempAccount onChanged={this.refreshData.bind(this)} />
        <ModalUserSalesInvite onChanged={this.refreshData.bind(this)} />
        <ModalUserSalesCreate onChanged={this.refreshData.bind(this)} />
        <ModalUserSalesEdit
          onChanged={this.refreshData.bind(this)}
          user={this.state.selected}
        />
        <ModalUserSalesDelete
          onChanged={this.refreshData.bind(this)}
          user={this.state.selected}
        />
      </div>
    );
  }
}
