import React from 'react';
import TopBar from '../comp/TopBar';
import ReactDatePicker from 'react-datepicker';
import RefundHistoryCard from '../comp/point/RefundHistoryCard';
import {Refund, TypeUserLevel} from '../types';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';
import {loadUserInfo, PAGE_URLS} from './PageUtil';

const initialDate = new Date();
initialDate.setDate(initialDate.getDate() - 30);
class State {
  startDate = initialDate;
  endDate: Date = new Date();
  refundHistory: Refund[] = [];
  page: number = 1;
  size: number = 20;
  total: number = 0;
}

export default class PageRefundHistory extends React.Component<any, State> {
  state = new State();

  async loadRefundHistory() {
    const {startDate, endDate} = this.state;
    const url = `/api/admin/refund/history?from=${startDate.toISOString()}&to=${endDate.toISOString()}`;
    const result: AxiosResponse<ApiResp<Refund[]>> = await Api.get(url);
    console.log('result: ' + JSON.stringify(result));
    if (result.data.code === 0) {
      this.setState({refundHistory: result.data.body});
    } else {
      window.alert(
        `환급 이력을 조회 중 오류가 발생했습니다. ERROR CODE: ${result.data.code}`,
      );
    }
  }

  componentDidMount() {
    const user = loadUserInfo();
    const access = user?.level === TypeUserLevel.sales;
    if (!access) {
      window.location.replace(PAGE_URLS.ACCESS_DENY);
    }
    this.loadRefundHistory().catch(e => console.error('Fail to load history'));
  }

  onSelectDuration(ago: number) {
    const now = new Date();
    const before = new Date();
    before.setDate(now.getDate() - ago);
    this.setState({startDate: before, endDate: now}, () => {
      this.loadRefundHistory().catch(e =>
        console.error('Fail to load history'),
      );
    });
  }

  onSelectStartDate(date: Date) {
    this.setState({startDate: date}, () => {
      this.loadRefundHistory().catch(e =>
        console.error('Fail to load history'),
      );
    });
  }
  onSelectEndDate(date: Date) {
    this.setState({endDate: date}, () => {
      this.loadRefundHistory().catch(e =>
        console.error('Fail to load history'),
      );
    });
  }

  render() {
    const {startDate, endDate, refundHistory, page, size, total} = this.state;
    const histories = refundHistory.map((value, index) => {
      return <RefundHistoryCard key={index} refund={value} />;
    });

    const pageLast = total % size > 0 ? 1 : 0;
    const pageCount = total / size + pageLast;
    const pageButtons = [];
    for (let i = 1; i <= pageCount; i++) {
      pageButtons.push(
        <button
          key={i}
          type="button"
          className={`btn ${i === page ? 'btn-secondary' : 'btn-outline-secondary'}`}
          // onClick={this.onClickPage.bind(this)}
          value={i}>
          {i}
        </button>,
      );
    }
    return (
      <>
        <TopBar menu={'na'} />
        <div
          className={'container h-100'}
          style={{marginTop: 60, paddingTop: 30, overflow: 'auto'}}>
          <div className={'row mb-3'}>
            <div className={'col-6'}>
              <div className="btn-group btn-group-sm">
                <button
                  type={'button'}
                  className={'btn btn-sm btn-outline-primary'}
                  onClick={() => this.onSelectDuration(9999)}>
                  전체
                </button>
                <button
                  type={'button'}
                  className={'btn btn-sm btn-outline-primary'}
                  onClick={() => this.onSelectDuration(30)}>
                  최근 1개월
                </button>
                <button
                  type={'button'}
                  className={'btn btn-sm btn-outline-primary'}
                  onClick={() => this.onSelectDuration(90)}>
                  최근 3개월
                </button>
              </div>
            </div>
            <div className={'col-6'}>
              <div className={'input-group'}>
                <span className={'input-group-text'}>시작일</span>
                <div className={'form-control'}>
                  <ReactDatePicker
                    className={'sp-form-hidden'}
                    selected={startDate}
                    onChange={date => {
                      if (date !== null) {
                        this.onSelectStartDate(date);
                      }
                    }}
                  />
                </div>
                <span className={'input-group-text'}>종료일</span>
                <div className={'form-control'}>
                  <ReactDatePicker
                    className={'sp-form-hidden'}
                    selected={endDate}
                    onChange={date => {
                      if (date !== null) {
                        this.onSelectEndDate(date);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>{histories}</div>
          </div>
          <div className={'d-flex justify-content-center'}>
            <div className="btn-group" role="group" aria-label="First group">
              {pageButtons}
            </div>
          </div>
        </div>
      </>
    );
  }
}
