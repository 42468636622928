import Api, {ApiResp} from "./Api";
import {User} from "../types";

export default class ApiSign {
    static async signIn(id: string, pass: string): Promise<ApiResp<User>> {
        const formData = new FormData();
        formData.set('id', id);
        formData.set('pass', pass);
        const url = `/api/admin/auth/user/signin`;
        const res = await Api.put(url, formData);
        return res.data;
    }
    static async signOut(): Promise<ApiResp<boolean>> {
        const url = `/api/admin/auth/user/signout`;
        const res = await Api.put(url);
        return res.data;
    }
}
