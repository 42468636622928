import React from 'react';
import TopBar from '../comp/TopBar';
import Business, {TypeBusinessStatus} from '../type_doc';
import BizCard from '../comp/point/BizCard';
import ModalContainer, {TypeModal} from './modal/ModalContainer';
import {numberWithCommas} from '../data/DataUtils';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';
import {loadUserInfo, PAGE_URLS} from './PageUtil';
import {TypeUserLevel} from '../types';

const initialDate = new Date();
initialDate.setDate(1);
const bizStatus = [
  TypeBusinessStatus.initial,
  TypeBusinessStatus.quoteReq,
  TypeBusinessStatus.quoteRes,
  TypeBusinessStatus.supply,
  TypeBusinessStatus.calculate,
  TypeBusinessStatus.close,
  TypeBusinessStatus.done,
  TypeBusinessStatus.cancel,
];
const bankNames = [
  'KDB산업은행',
  'IBK기업은행',
  'KB국민은행',
  'Sh수협은행',
  'NH농협은행',
  '단위농협(지역농축협)',
  '우리은행',
  'SC제일은행',
  '씨티은행',
  'DGB대구은행',
  '부산은행',
  '광주은행',
  '제주은행',
  '전북은행',
  '경남은행',
  '새마을금고',
  '신협',
  '저축은행중앙회',
  '홍콩상하이은행',
  '산림조합',
  '우체국예금보험',
  '하나은행',
  '신한은행',
  '케이뱅크',
  '카카오뱅크',
  '토스뱅크',
];
class State {
  business: Business[] = [];
  page = 1;
  size = 10;
  total = 0;
  startDate = initialDate;
  endDate = new Date();
  selected: Business[] = [];
  isBusiness = false;
  certFile?: File;
  bankCopy?: File;
  bankName: string = '';
  bankAccount: string = '';
}
export default class PagePoints extends React.Component<any, State> {
  state = new State();
  maxSize = 3 * 1024 * 1024;

  async loadRefundableBizList() {
    const {page, size} = this.state;
    const url = `/api/admin/refund?page=${page}&size=${size}`;
    const result: AxiosResponse<ApiResp<Business[]>> = await Api.get(url);
    console.log('list : ' + JSON.stringify(result.data));
    if (result.data.code === 0) {
      this.setState({
        business: result.data.body,
        page: result.data.page || 1,
        total: result.data.total || 0,
      });
    }
  }
  componentDidMount() {
    const user = loadUserInfo();
    const access = user?.level === TypeUserLevel.sales;
    if (!access) {
      window.location.replace(PAGE_URLS.ACCESS_DENY);
    }
    this.loadRefundableBizList().catch(e => console.error(e));
  }
  onSelectBusiness(value: Business) {
    const {selected} = this.state;
    if (selected.includes(value)) {
      const index = selected.indexOf(value);
      selected.splice(index, 1);
    } else {
      selected.push(value);
    }
    this.setState({selected: selected});
  }
  onChangeCertFile(event: any) {
    const selectedFile = event.target.files[0];
    const fileSizeInBytes = selectedFile.size;
    if (this.maxSize < fileSizeInBytes) {
      window.alert('최대 허용 사이즈를 초과했습니다.');
      event.target.value = null;
      return;
    }
    if (event.target.files) {
      console.log(event.target.files);
      this.setState({certFile: event.target.files[0] as File});
    }
  }
  onChangeBankName(event: any) {
    this.setState({bankName: event.target.value});
  }
  onChangeBankAccount(event: any) {
    this.setState({bankAccount: event.target.value});
  }
  onChangeBankCopyFile(event: any) {
    const selectedFile = event.target.files[0];
    const fileSizeInBytes = selectedFile.size;
    if (this.maxSize < fileSizeInBytes) {
      window.alert('최대 허용 사이즈를 초과했습니다.');
      event.target.value = null;
      return;
    }
    if (event.target.files) {
      console.log(event.target.files);
      this.setState({bankCopy: event.target.files[0] as File});
    }
  }
  async onRefund() {
    const {selected, certFile, bankAccount, bankName, bankCopy} = this.state;
    const formData = new FormData();
    let totalBalance = 0;
    selected.forEach(value => {
      formData.append('bizUid', `${value.docSeq}`);
      totalBalance +=
        value.result.commission.supply + value.result.commission.tax;
    });
    formData.set('bank', bankName);
    formData.set('account', bankAccount);
    formData.set('totalBalance', `${totalBalance}`);
    formData.set('docIdentify', certFile!);
    formData.set('docBank', bankCopy!);
    const url = `/api/admin/refund/request`;
    const result: AxiosResponse<ApiResp<boolean>> = await Api.post(
      url,
      formData,
    );
    if (result.data.code === 0) {
      console.log('success to request refund');
      window.location.href = PAGE_URLS.POINT_HISTORY;
      return true;
    } else {
      window.alert(
        `환급 신청 중 에러가 발생했습니다. ERROR CODE: ${result.data.code}`,
      );
      return false;
    }
  }

  onChangeTypeRefund(isBusiness: boolean) {
    this.setState({isBusiness: isBusiness});
  }

  renderBankNameOptions() {
    return bankNames.map((value: string, index: number) => {
      return <option key={index} value={value}>{value}</option>;
    })
  }

  onClickPage(event: any) {
    const page = event.target.value;
    this.setState({page: page}, () => {
      this.loadRefundableBizList().catch(e => console.error(e));
    });
  }

  render() {
    const {selected, isBusiness, certFile, bankAccount, bankName, bankCopy} =
      this.state;
    const biz = this.state.business.map((value, index) => {
      return (
        <BizCard
          key={index}
          selected={selected.includes(value)}
          biz={value}
          onClick={() => {
            this.onSelectBusiness(value);
          }}
        />
      );
    });
    let sum = 0;
    selected.forEach(value => {
      sum += value.result.commission.supply + value.result.commission.tax;
    });
    const disabled =
      certFile === undefined ||
      bankCopy === undefined ||
      bankName === '' ||
      bankAccount === '' ||
      sum === 0;

    const pageCount = (this.state.total - 1) / this.state.size + 1;
    const pageButtons = [];
    for (let i = 1; i <= pageCount; i++) {
      pageButtons.push(
          <button
              key={i}
              type="button"
              className={`btn ${i === this.state.page ? 'btn-secondary' : 'btn-outline-secondary'}`}
              value={i}
              onClick={this.onClickPage.bind(this)}>
            {i}
          </button>,
      );
    }

    return (
      <>
        <TopBar menu={'na'} />
        <div className={'container h-10'} style={{marginTop: 60}}>
          <div className={'row h-100'}>
            <div className={'col-4 h-100 pt-5'} style={{overflowY: 'auto'}}>
              <h4 className="mt-0">환급가능 사업목록</h4>
              <div className={'mb-3'}>{biz}</div>
              <div className={'d-flex justify-content-center'}>
                <div className="btn-group" role="group" aria-label="First group">
                  {pageButtons}
                </div>
              </div>
            </div>
            <div className={'col-8'}>
              <div className={'row mb-3 pt-5'}>
                <div className={'col'}>
                  <div className={'alert alert-secondary'}>
                    <span>
                      사업자 혹은 개인으로 환급이 가능하며, 사업자등록증 혹은
                      주민등록등본이 필요합니다.
                    </span>
                  </div>
                </div>
              </div>
              <div className={'row mb-3'}>
                {/*<div className={'col'}>합계: {numberWithCommas(sum)}포인트</div>*/}
                <div className={'col'}>
                  <legend className="col-form-label pf-sub-title pt-0">
                    환급신청 포인트
                  </legend>
                  <div className="input-group mb-3">
                    <span className="input-group-text">₩</span>
                    <input
                      type="text"
                      className={'form-control '}
                      readOnly
                      disabled
                      aria-label="bank account number"
                      value={numberWithCommas(sum)}
                    />
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className="col">
                  <legend className="col-form-label pf-sub-title pt-0">
                    환급 구분
                  </legend>
                  <div className={'input-group mb-3'}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isBusiness}
                        id="flexCheckDefault"
                        onClick={() => {
                          this.onChangeTypeRefund(true);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault">
                        사업자
                      </label>
                    </div>
                    <div style={{width: 50}} />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!isBusiness}
                        id="flexCheckChecked"
                        onClick={() => {
                          this.onChangeTypeRefund(false);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked">
                        개인
                      </label>
                    </div>
                  </div>

                  {/*<div*/}
                  {/*  className="btn-group mb-3"*/}
                  {/*  role="group"*/}
                  {/*  aria-label="Basic radio toggle button group">*/}
                  {/*  <input*/}
                  {/*    type="radio"*/}
                  {/*    className="btn-check"*/}
                  {/*    name="btnradio"*/}
                  {/*    id="btnradio1"*/}
                  {/*    autoComplete="off"*/}
                  {/*    checked={isBusiness}*/}
                  {/*    readOnly*/}
                  {/*  />*/}
                  {/*  <label*/}
                  {/*    className="btn btn-outline-primary"*/}
                  {/*    onClick={() => {*/}
                  {/*      this.setState({isBusiness: true});*/}
                  {/*    }}*/}
                  {/*    htmlFor="btnradio1">*/}
                  {/*    사업자*/}
                  {/*  </label>*/}

                  {/*  <input*/}
                  {/*    type="radio"*/}
                  {/*    className="btn-check"*/}
                  {/*    name="btnradio"*/}
                  {/*    id="btnradio2"*/}
                  {/*    autoComplete="off"*/}
                  {/*    checked={!isBusiness}*/}
                  {/*    readOnly*/}
                  {/*  />*/}
                  {/*  <label*/}
                  {/*    className="btn btn-outline-primary"*/}
                  {/*    onClick={() => {*/}
                  {/*      this.setState({isBusiness: false});*/}
                  {/*    }}*/}
                  {/*    htmlFor="btnradio2">*/}
                  {/*    개인*/}
                  {/*  </label>*/}
                  {/*</div>*/}
                  <div>
                    <legend className="col-form-label pf-sub-title pt-0">
                      본인 확인 정보
                      <span>
                        <small
                          className={'text-muted'}
                          style={{
                            fontSize: '0.75rem',
                          }}>{` (최대 ${(this.maxSize / 1024 / 1024).toFixed()}MB 까지 업로드 가능합니다.)`}</small>
                      </span>
                    </legend>
                    <div className="input-group mb-3">
                      <div style={{flex: 1}}>
                        <input
                          type="file"
                          className={'form-control '}
                          placeholder={'파일을 등록하세요'}
                          onChange={this.onChangeCertFile.bind(this)}
                          aria-label="certificate"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <legend className="col-form-label pf-sub-title pt-0">
                      계좌정보
                    </legend>
                    <div className="input-group mb-3">
                      <span className="input-group-text">은행</span>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={this.onChangeBankName.bind(this)}
                        value={bankName}>
                        <option value={''}>선택하세요</option>
                        <option value={''}>
                          -------------------------------------
                        </option>
                        {this.renderBankNameOptions()}
                      </select>
                      <span className="input-group-text">계좌번호</span>
                      <input
                        type="text"
                        className={'form-control '}
                        placeholder={'계좌번호를 입력하세요'}
                        aria-label="bank account number"
                        onChange={this.onChangeBankAccount.bind(this)}
                      />
                    </div>
                  </div>
                  <div>
                    <legend className="col-form-label pf-sub-title pt-0">
                      통장사본
                      <span>
                        <small
                          className={'text-muted'}
                          style={{
                            fontSize: '0.75rem',
                          }}>{` (최대 ${(this.maxSize / 1024 / 1024).toFixed()}MB 까지 업로드 가능합니다.)`}</small>
                      </span>
                    </legend>
                    <div className="input-group mb-3">
                      <div style={{flex: 1}}>
                        <input
                          type="file"
                          className={'form-control '}
                          placeholder={'파일을 등록하세요'}
                          aria-label="bank account copy"
                          onChange={this.onChangeBankCopyFile.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button
                      disabled={disabled}
                      className={'btn btn-primary'}
                      data-bs-toggle="modal"
                      data-bs-target="#requestPoint">
                      신청
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalContainer
          divId={'requestPoint'}
          title={'환급신청'}
          onStore={this.onRefund.bind(this)}
          type={TypeModal.SAVE}>
          <div>
            총 {selected.length}건, {numberWithCommas(sum)}포인트를 환급
            신청하시겠습니까?
          </div>
        </ModalContainer>
      </>
    );
  }
}
