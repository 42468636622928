import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {TypeUserLevel, User} from '../../types';
import ApiUser from '../../data/ApiUser';
import {validationEmail, validationId, validationPassword, validationTel} from '../PageUtil';
import InputEmail from '../../comp/InputEmail';
import InputE164 from '../../comp/InputE164';
import InputId from "../../comp/InputId";

interface Props {
  onChanged: () => void;
}
class State {
  mail: string = '';
  tel: string = '';
  user: User = new User();
  password = '';

  showDescId: boolean = false;
  showDescEmail: boolean = false;
  showDescPassword: boolean = false;
  showDescName: boolean = false;
  showDescTel: boolean = false;
}

export default class ModalUserSalesCreate extends React.Component<
  Props,
  State
> {
  state = new State();

  onChangeEmail(value: string) {
    this.setState({mail: value});
  }
  onChangeID(value: string) {
    const {user} = this.state;
    user.uid = value;
    this.setState({user: user});
  }

  onChangePassword(event: any) {
    this.setState({password: event.target.value});
  }

  onChangeName(event: any) {
    const {user} = this.state;
    user.name = event.target.value;
    this.setState({user: user});
  }

  onChangeTel(value: string) {
    this.setState({tel: value});
  }

  validateID() {
    const {user} = this.state;
    this.setState({showDescId: !validationId(user.uid)});
  }

  validateEmail() {
    const {mail} = this.state;
    this.setState({showDescEmail: !validationEmail(mail)});
  }

  validatePassword() {
    const {password} = this.state;
    this.setState({showDescPassword: !validationPassword(password)});
  }

  validateName() {
    const {user} = this.state;
    this.setState({showDescName: user.name === ''});
  }

  validateTel() {
    const {tel} = this.state;
    this.setState({showDescTel: !validationTel(tel)});
  }

  async onCreateUser() {
    if (!this.validData()) return false;

    const {user, password, mail, tel} = this.state;
    user.mail = mail;
    user.tel = tel;
    user.level = TypeUserLevel.sales;
    const result = await ApiUser.createUser(user, password);
    if (result.code === 0) {
      this.props.onChanged();
    } else if (result.code === 104) {
      window.alert(`이미 존재하는 아이디 입니다.`);
    } else if (result.code === 105) {
      window.alert(`이미 존재하는 이메일 입니다.`);
    } else if (result.code === 106) {
      window.alert(`ID 를 확인해주세요.`);
    } else {
      window.alert(`에러가 발생하였습니다. ERROR CODE: ${result.code}`);
    }
    return result.code === 0;
  }
  cancelCreate() {
    this.setState({user: new User(), password: '', mail: '', tel: ''});
  }

  validData() {
    const {user, password, tel, mail} = this.state;
    // 입력내용 누락 확인
    if (user.uid === '') {
      alert('ID를 입력해주세요.');
      return false;
    } else if (mail === '') {
      alert('이메일 주소를 입력해주세요.');
      return false;
    } else if (password === '') {
      alert('비밀번호를 입력해주세요.');
      return false;
    } else if (user.name === '') {
      alert('이름을 입력해주세요.');
      return false;
    } else if (tel === '') {
      alert('전화번호를 입력해주세요.');
      return false;
    }
    // ID 형식 확인
    if (!validationId(user.uid)) {
      alert('ID를 확인해주세요. (영문대소문자, 숫자, _, - 만 허용됩니다)');
      return false;
    }
    // 이메일 형식 확인
    if (!validationEmail(mail)) {
      alert('이메일 주소를 확인해주세요.');
      return false;
    }
    if (!validationTel(tel)) {
      alert('전화번호를 확인 해 주세요.');
      return false;
    }

    // 비밀번호 복잡도 확인
    if (!validationPassword(password)) {
      alert('비밀번호는 최소 8자 이상의 숫자,영어를 조합하여 입력해주세요.');
      return false;
    }
    return true;
  }
  disabledButton() {
    const {user, password, tel, mail} = this.state;
    // 입력내용 누락 확인
    if (user.uid === '') {
      return true;
    } else if (mail === '') {
      return true;
    } else if (password === '') {
      return true;
    } else if (user.name === '') {
      return true;
    } else if (!validationTel(tel)) {
      return true;
    } else if (!validationId(user.uid)) {
      return true;
    } else if (!validationEmail(mail)) {
      return true;
    } else if (!validationPassword(password)) {
      return true;
    }
    return false;
  }

  render() {
    const {user, password, mail, tel, showDescId, showDescName, showDescEmail, showDescTel, showDescPassword} = this.state;
    return (
      <ModalContainer
        divId={'salesCreate'}
        title={'영업사원 생성'}
        disableConfirm={this.disabledButton()}
        onStore={this.onCreateUser.bind(this)}
        onCancel={this.cancelCreate.bind(this)}
        type={TypeModal.CREATE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>ID</div>
              <div className={'col-sm-8'}>
                <InputId
                    placeholder={'ID 를 입력하세요. (영문대소문자, 숫자, _, - 만 허용)'}
                    value={user.uid}
                    onChange={this.onChangeID.bind(this)}
                    onBlur={this.validateID.bind(this)}
                />
                  <div className="invalid-feedback" style={{display: showDescId ? 'block' : undefined}}>
                      ID 형식을 확인해주세요. 영문대소문자, 숫자, _, - 만 허용하며, 최소 5자, 최대 24자까지 가능합니다.
                  </div>
            </div>
            </div>
            <div className={'mb-2 row'}>
            <div className={'col-sm-4 col-form-label'}>이메일 주소</div>
              <div className={'col-sm-8'}>
                <InputEmail
                  onChange={this.onChangeEmail.bind(this)}
                  value={mail}
                  onBlur={this.validateEmail.bind(this)}
                />
                <div className="invalid-feedback" style={{display: showDescEmail ? 'block' : undefined}}>
                    이메일 형식을 확인해주세요.
                </div>
              </div>
            </div>
              <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>비밀번호</div>
              <div className={'col-sm-8'}>
                <input
                  type={'password'}
                  className={'form-control'}
                  onChange={this.onChangePassword.bind(this)}
                  value={password}
                  onBlur={this.validatePassword.bind(this)}
                />
                <div className="invalid-feedback" style={{display: showDescPassword ? 'block' : undefined}}>
                    패스워드를 확인해주세요. 영문대소문자와 숫자만 허용되며, 최소 8자리 이상이어야 합니다.
                </div>
              </div>
              </div>
              <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>이름</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeName.bind(this)}
                  value={user.name}
                  onBlur={this.validateName.bind(this)}
                />
                <div className="invalid-feedback" style={{display: showDescName ? 'block' : undefined}}>
                    수수료 환급, 본인확인 등에 사용되니, 실명을 사용해 주세요.
                </div>
              </div>
              </div>
              <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>전화번호</div>
              <div className={'col-sm-8'}>
                <InputE164
                    onChange={this.onChangeTel.bind(this)}
                    value={tel}
                    onBlur={this.validateTel.bind(this)}
                />
                  <div className="invalid-feedback" style={{display: showDescTel ? 'block' : undefined}}>
                      전화번호 형식을 확인해주세요. 가운데 3 또는 4자리, 뒷번호 4자리이어야 합니다.
                  </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
