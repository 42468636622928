import React from 'react';
import {ManagerIcon} from '../Manager';
import {numberWithCommas} from '../../data/DataUtils';
import moment from 'moment/moment';
import {Refund, User} from '../../types';
import UserCache from '../../UserCache';

interface Props {
  refund: Refund;
  selected?: boolean;
  onClick?: (seq: number) => void;
}

class State {
  user?: User;
}
export default class RefundCard extends React.Component<Props, State> {
  state = new State();

  async loadInfo() {
    const {refund} = this.props;
    const user = await UserCache.getInstance().getById(refund.userRefund);
    if (user === undefined) {
      console.error('USER not found');
      throw Error('USER not found');
    }
    this.setState({user: user});
  }

  componentDidMount() {
    this.loadInfo().catch(e => console.error('Fail to get misc info  ' + e));
  }

  render() {
    const {refund} = this.props;
    const {user} = this.state;
    return (
      <div
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick(refund.refundUid);
          }
        }}
        className={
          'card-business d-flex ' +
          (this.props.selected ? 'card-business-selected' : '')
        }>
        <div className={'d-flex align-items-center me-2'}>
          <ManagerIcon />
        </div>
        <div className={'flex-grow-1'}>
          <div className={'d-flex justify-content-between'}>
            <span>{user?.mail}</span>
            <div>{numberWithCommas(refund.totalBalance)}포인트</div>
          </div>
          <div className={'d-flex justify-content-between'}>
            <span>
              (@{user?.uid}) {user?.name}
            </span>
            <div>
              {moment(new Date(refund.timeCreate)).format('MM/DD HH:mm')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
