import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {User} from '../../types';
import ApiUser from '../../data/ApiUser';
import {validationEmail, validationTel} from '../PageUtil';
import InputEmail from '../../comp/InputEmail';
import InputE164 from '../../comp/InputE164';
interface Props {
  user?: User;
  onChanged: () => void;
}
class State {
  user: User = new User();
}

export default class ModalUserSalesEdit extends React.Component<Props, State> {
  state = new State();

  componentDidMount() {
    this.updateState();
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.user);
    const pp = JSON.stringify(prevProps.user);
    if (np !== pp) {
      this.updateState();
    }
  }

  updateState() {
    const {user} = this.props;
    console.log('USER: ' + JSON.stringify(user));
    if (user) {
      const newUser = Object.assign(new User(), user);
      this.setState({user: newUser});
    }
  }

  onChangeEmail(mail: string) {
    const {user} = this.state;
    user.mail = mail;
    this.setState({user: user});
  }

  onChangeName(event: any) {
    const {user} = this.state;
    user.name = event.target.value;
    this.setState({user: user});
  }

  onChangeTel(value: string) {
    const {user} = this.state;
    user.tel = value;
    this.setState({user: user});
  }

  onChangeDescription(event: any) {
    const {user} = this.state;
    user.description = event.target.value;
    this.setState({user: user});
  }
  async onEditUser() {
    if (!this.validData()) return false;

    const {user} = this.state;
    const result = await ApiUser.editUser(user);
    if (result.code === 0) {
      this.props.onChanged();
    }
    return result.code === 0;
  }
  cancelEdit() {
    this.setState({user: this.props.user!});
  }

  validData() {
    const {user} = this.state;
    // 입력내용 누락 확인
    if (user.mail === '') {
      alert('이메일 주소를 입력해주세요.');
      return false;
    } else if (user.name === '') {
      alert('이름을 입력해주세요.');
      return false;
    } else if (!validationTel(user.tel)) {
      alert('전화번호를 입력해주세요.');
      return false;
    }
    // 이메일 형식 확인
    if (!validationEmail(user.mail)) {
      alert('이메일 주소를 확인해주세요.');
      return false;
    }
    return true;
  }

  disableConfirm() {
    const {user} = this.state;
    return JSON.stringify(this.props.user) === JSON.stringify(user);
  }

  render() {
    const {user} = this.state;
    return (
      <ModalContainer
        divId={'salesUpdate'}
        title={'영업사원 수정'}
        disableConfirm={this.disableConfirm()}
        onStore={this.onEditUser.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        type={TypeModal.USER_EDIT}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>ID</div>
              <div className={'col-sm-8'}>
                <input
                  disabled
                  type={'text'}
                  className={'form-control'}
                  value={user.uid}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>이메일 주소</div>
              <div className={'col-sm-8'}>
                <InputEmail
                  onChange={this.onChangeEmail.bind(this)}
                  value={user.mail}
                  disabled
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>이름</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeName.bind(this)}
                  value={user.name || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>전화번호</div>
              <div className={'col-sm-8'}>
                <InputE164
                  onChange={this.onChangeTel.bind(this)}
                  value={user.tel}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>메모</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeDescription.bind(this)}
                  disabled={user.managed}
                  value={user.description || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
