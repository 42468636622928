import React, {JSX} from "react";
import Avatar from "react-avatar";
import {FaEdit} from "react-icons/fa";

interface Props {
    title: string;
    userId?: string;
    editModalId?: string;
    children?: JSX.Element | string;
    onClickTitle?:()=>void;
    onClickEdit?:()=>void;
    act?: boolean;
    disabled?: boolean;
}
export default class BusinessInfoItem extends React.Component<Props, any> {
    onClickTitle() {
        if (this.props.onClickTitle) {
            this.props.onClickTitle();
        }
    }
    onClickEdit() {
        if (this.props.onClickEdit) {
            this.props.onClickEdit();
        }
    }
    render() {
        const {title, userId, children, editModalId} = this.props;
        let bc = '';
        if (this.props.onClickTitle === undefined || this.props.disabled) {
            bc = 'col-md-4 col-form-label';
        } else {
            if (this.props.act){
                bc = 'btn col-md-4 col-form-label sp-button-light-act';
            } else {
                bc = 'btn col-md-4 col-form-label sp-button-light';
            }
        }
        return (
            <>
                <div className={'row'}>
                    <hr />
                </div>
                <div className="row">
                    <label
                        className={bc}
                        onClick={this.props.disabled ? undefined : this.onClickTitle.bind(this)}>
                        {title}
                    </label>
                    <div className="col-md-5">
                        <label className="form-control-plaintext">
                            {children}
                        </label>
                    </div>
                    <div className={'col-md-3 d-flex justify-content-end'}>
                        {editModalId && (
                            <div
                                className={'btn btn-sm'}
                                data-bs-toggle="modal"
                                data-bs-target={`#${this.props.editModalId}`}
                                onClick={this.onClickEdit.bind(this)}>
                                <FaEdit size={15} color={'gray'}/>
                            </div>
                        )}
                        {userId &&
                            <Avatar color={'#ff5b5b'} name={userId} size={'36'} round/>
                        }
                    </div>
                </div>
            </>
        );
    }
}
