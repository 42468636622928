import * as React from 'react';
import TopBar from '../comp/TopBar';
import SideBarUser from '../comp/SideBarUser';
import {Breadcrumb} from '../comp/BreadCrumb';
import DataTable, {Api} from 'datatables.net-dt';
import {Company} from '../types';
import {renderToString} from 'react-dom/server';
import ModalUserCompanyCreate from './modal/ModalUserCompanyCreate';
import ModalUserCompanyEdit from './modal/ModalUserCompanyEdit';
import ModalUserCompanyDelete from './modal/ModalUserCompanyDelete';
import ApiCompany from '../data/ApiCompany';
import ModalUserCompanySalesList from './modal/ModalUserCompanySalesList';
import {numberWithCompanyRegNum} from '../data/DataUtils';

// class ManagedCompany extends Company{
//     user = new User();
// }
class State {
  selected?: Company;
  selectedName?: string;
  companies: Company[] = [];
}
export default class PageUserCompany extends React.Component<any, State> {
  state = new State();

  companyTable?: Api<any>;

  onSelectTableRow(event: Event) {
    const target = event.currentTarget;
    if (target !== null) {
      const row = event.currentTarget as HTMLTableRowElement;
      if (row.classList.contains('selected')) {
        row.classList.remove('selected');
        this.setState({selected: undefined});
      } else {
        this.companyTable
          ?.rows('.selected')
          .nodes()
          .each(row => row.classList.remove('selected'));
        row.classList.add('selected');
        const seq = row.cells[0].innerText;
        this.state.companies.filter(value => {
          if (value.seq === parseInt(seq)) {
            if (value.description === undefined) {
              value.description = '';
            }
            this.setState({selected: value, selectedName: value.name});
          }
        });
      }
    }
  }

  componentDidMount() {
    this.initCompanyTable();
    this.loadData().catch(e => console.error(e));
  }
  async loadData() {
    const result = await ApiCompany.getCompanyList();
    console.log('result: ' + JSON.stringify(result));
    this.setState({companies: result.body, selected: undefined});

    this.loadDataTable(result.body);
  }
  refreshData() {
    this.loadData().catch(e => console.error(e));
  }

  initCompanyTable() {
    const element = document.getElementById('company_list');
    if (element === null) {
      throw Error('Company list not exist');
    } else {
      if (this.companyTable === undefined) {
        this.companyTable = new DataTable(element as HTMLTableElement, {});
        this.companyTable?.on(
          'click',
          'tbody tr',
          this.onSelectTableRow.bind(this),
        );
      }
    }
  }

  loadDataTable(companies: Company[]) {
    const table = this.companyTable;
    table?.rows().clear();
    for (const value of companies) {
      const product =
        value.products > 0
          ? renderToString(
              <span className="badge text-bg-warning">{value.products}</span>,
            )
          : ' ';
      table?.row.add([
        value.seq,
        value.name,
        product,
        value.tel,
        numberWithCompanyRegNum(value.corpRegNum),
        value.email || '',
        value.location1 || '',
        value.homepage || '',
        value.description || '',
      ]);
    }
    table?.draw(false);
  }

  render() {
    const {selected, selectedName} = this.state;
    return (
      <div>
        <TopBar menu={'user'} />
        <SideBarUser />
        <div className={'sp-right'}>
          <Breadcrumb path={['user', 'company']} />
          <>
            <div
              className={
                'card-datatable-header d-flex justify-content-between'
              }>
              {/*<span>{selected && `${selectedName} (${selected.user.name})`}</span>*/}
              <span>{selected && `${selectedName}`}</span>
              <div className="btn-group btn-group-sm">
                <button
                  type={'button'}
                  data-bs-toggle="modal"
                  data-bs-target="#companyCreate"
                  className={'btn btn-sm btn-outline-primary'}>
                  생성
                </button>
                {selected && (
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#companyUpdate"
                    className={'btn btn-sm btn-outline-primary'}>
                    수정
                  </button>
                )}
                {selected && (
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#companySales"
                    className={'btn btn-sm btn-outline-primary'}>
                    영업사원보기
                  </button>
                )}
                {selected && (
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#companyDelete"
                    className={'btn btn-sm btn-outline-primary'}>
                    삭제
                  </button>
                )}
              </div>
            </div>
            <table id={'company_list'} className={'datatable-table sp-table'}>
              <thead>
                <tr>
                  <th style={{maxWidth: 50}}>번호</th>
                  <th style={{maxWidth: 150}}>회사명</th>
                  <th style={{maxWidth: 100}}>제품</th>
                  <th>전화번호</th>
                  <th>사업자번호</th>
                  <th>대표 이메일</th>
                  <th>위치</th>
                  <th>홈페이지</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </>
        </div>
        <ModalUserCompanyCreate onChanged={this.refreshData.bind(this)} />
        <ModalUserCompanyEdit
          onChanged={this.refreshData.bind(this)}
          company={this.state.selected}
        />
        <ModalUserCompanyDelete
          onChanged={this.refreshData.bind(this)}
          company={this.state.selected}
        />
        <ModalUserCompanySalesList companySeq={this.state.selected?.seq} />
      </div>
    );
  }
}
