import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {User} from '../../types';
import ApiUser from '../../data/ApiUser';
interface Props {
  user?: User;
  onChanged: () => void;
}
class State {
  mail = '';
}

export default class ModalUserSalesDelete extends React.Component<
  Props,
  State
> {
  state = new State();

  onChangeEmail(event: any) {
    this.setState({mail: event.target.value});
  }
  async onDeleteUser() {
    if (!this.validData()) return false;

    const {user} = this.props;
    if (user === undefined) return false;
    const result = await ApiUser.deleteUser(user.uid);
    if (result.code === 0) {
      this.props.onChanged();
      this.setState({mail: ''});
    }
    return result.code === 0;
  }
  cancelDelete() {
    this.setState({mail: ''});
  }

  validData() {
    const {user} = this.props;
    const {mail} = this.state;
    if (user === undefined) return false;
    // 입력내용 누락 확인
    if (user?.mail !== mail) {
      alert('삭제할 영업사원의 메일주소를 정확하게 입력해주세요.');
      return false;
    }
    return true;
  }

  disableConfirm() {
    const {user} = this.props;
    const {mail} = this.state;
    if (user === undefined) return false;
    if (user?.mail !== mail) {
      return true;
    }
    return false;
  }

  render() {
    const {user} = this.props;
    const {mail} = this.state;
    return (
      <ModalContainer
        divId={'salesDelete'}
        title={'영업사원 삭제'}
        disableConfirm={this.disableConfirm()}
        onStore={this.onDeleteUser.bind(this)}
        onCancel={this.cancelDelete.bind(this)}
        type={TypeModal.DELETE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2'}>
              <div className={'col-12 col-form-label'}>
                삭제할 영업사원의 메일주소를 입력해주세요.
              </div>
              <div className={'col-12 col-form-label'}>{user?.mail}</div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>이메일 주소</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control delete-confirm'}
                  placeholder={user?.mail}
                  onChange={this.onChangeEmail.bind(this)}
                  value={mail || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
