import Api, {ApiResp} from './data/Api';
import {AxiosResponse} from 'axios';
import {ProductDetail, TypeUserLevel} from './types';
import {loadUserInfo} from './pages/PageUtil';

type ViewItem =
  | 'abstract'
  | 'bizType'
  | 'salesMgr'
  | 'bizMgr'
  | 'productMgr'
  | 'commissionRate'
  | 'quoteReg'
  | 'quoteRes'
  | 'orderInfo'
  | 'delivery'
  | 'taxPurchase'
  | 'taxSale'
  | 'bizMinute'
  | 'cancel';

export enum TypeBusinessStatus {
  initial = 'initial',
  quoteReq = 'quoteReq',
  quoteRes = 'quoteRes',
  supply = 'supply',
  calculate = 'calculate',
  close = 'close',
  done = 'done',
  cancel = 'cancel',
}
export enum TypeBusiness {
  delivery = 'delivery',
  agency = 'agency',
}
export class ProductTuple {
  title: string = '';
  unitPrice: number = 0;
  purchasePrice: number = 0;
  amount: number = 0;
  price: number = 0;
  unit: 'EA' | 'MM' = 'EA';
  ref: number = -1; // 등록된 제품 번호
}

export class FixedDocument {
  file: string = '';
  fileType: string = 'text/plain';
  userRegister: string = '';
  timeRegister: Date = new Date();
  taxId?: string; // 사업자등록번호 혹은 주민번호 등
  priceSupply?: number;
  priceTax?: number;
}
export class Requirements {
  productList: ProductTuple[] = [];
  note: string = '';
  getSum() {
    let sum = 0;
    for (const product of this.productList) {
      if (product === undefined) continue;
      sum += product.unitPrice * product.amount;
    }
    return sum;
  }
  getPurchaseSum() {
    let sum = 0;
    for (const product of this.productList) {
      if (product === undefined) continue;
      sum += product.purchasePrice * product.amount;
    }
    return sum;
  }
}

export class Price {
  supply: number = 0;
  tax: number = 0;
}

export class BusinessResult {
  purchase: Price = new Price();
  sales: Price = new Price();
  order: Price = new Price();
  commission: Price = new Price();
}

/**
 * Do not update object field directly, use class method only.
 * All method returning object data must have synchronized with
 * server data. Therefore, if an APIs which exist in method, receives
 * non-successful response, such method should have return null.
 *
 * Typically, attempts to update on a terminated business should have failed.
 */
export default class Business {
  docSeq: number = -1; // 사업 번호
  bizStep: TypeBusinessStatus = TypeBusinessStatus.initial; // 사업단계
  bizType: TypeBusiness = TypeBusiness.delivery; // 사업 타입
  userCreate: string = ''; // 사업 생성자, doc 내 저장하지 않음.

  bizAbstract: string = ''; // 사업 개요

  requirements: Requirements = new Requirements(); // 물품 목록

  quotation: FixedDocument[] = []; // 견적서 (변경이력 전체 유지)
  order?: FixedDocument; // 발주서
  deliveryList?: ProductTuple[]; // 납품 목록
  delivery?: FixedDocument; // 납품 확인서

  taxPurchase?: FixedDocument; // 매입 세금계산서
  taxSales?: FixedDocument; // 매출 세금계산서
  taxCommission?: FixedDocument; // 영업사원 비용처리 서류

  userBiz?: string; // 사업 담당자, doc 내 저장하지 않음.
  userSales?: string; // 영업 담당자, doc 내 저장하지 않음.
  userSupply?: string; // 공급(물품) 담당자, doc 내 저장하지 않음.

  commissionRate?: number; // 영업사원 수수료 율, doc 내 저장하지 않음.

  timeCreate: string = ''; // 사업생성일
  timeUpdate: string = ''; // 수정일

  result: BusinessResult = new BusinessResult();

  /**
   * Create a new business information.
   * business step will {@link TypeBusinessStatus.initial}
   *
   * @param biz_abstract abstract of business
   * @param type business type
   */
  static async createBusiness(biz_abstract: string, type: TypeBusiness) {
    const formData = new FormData();
    formData.set('bizType', type);
    formData.set('bizAbstract', biz_abstract);
    const url = `/api/admin/biz`;
    const res: AxiosResponse<ApiResp<Business>> = await Api.post(url, formData);

    if (res.data.code === 0) {
      const biz = res.data.body;
      return await Business.getBusiness(biz.docSeq);
    }
    throw Error(
      `Fail to create business : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Get business document (object)
   * business step will not be changed.
   *
   * @param seq business sequence.
   */
  static async getBusiness(seq: number) {
    const url = `/api/admin/biz/${seq}`;
    const res: AxiosResponse<ApiResp<Business>> = await Api.get(url);
    if (res.data.code === 0) {
      const biz = new Business();
      Object.assign(biz, res.data.body);
      return biz;
    }
    throw Error(`Fail to get business : ${res.data.code} : ${res.data.reason}`);
  }

  static async getBusinessList(
    bizStatus?: TypeBusinessStatus[],
    page?: number,
    size?: number,
    from?: Date,
    to?: Date,
  ): Promise<ApiResp<Business[]>> {
    let query = '';
    if (bizStatus) {
      bizStatus.forEach(value => {
        query += `&bizStatus=${value}`;
      });
    }
    if (page) query += `&page=${page}`;
    if (size) query += `&size=${size}`;
    if (from) query += `&from=${from.toISOString()}`;
    // if (to) query += `&to=${to.toISOString()}`;
    const url = `/api/admin/biz?` + query;
    const res: AxiosResponse<ApiResp<Business[]>> = await Api.get(url);
    const {data} = res;
    const result = new Array<Business>();
    for (const d of data.body) {
      const b = await Business.getBusiness(d.docSeq);
      result.push(b);
    }
    data.body = result;
    return data;
  }

  getTitle() {
    const desc = this.bizAbstract.substring(0, 100);
    const line = desc.trim().split(/[\n\r]/g)[0];
    let result = '';
    if (line.startsWith('######')) {
      result = line.replace('######', '');
    } else if (line.startsWith('#####')) {
      result = line.replace('#####', '');
    } else if (line.startsWith('####')) {
      result = line.replace('####', '');
    } else if (line.startsWith('###')) {
      result = line.replace('###', '');
    } else if (line.startsWith('##')) {
      result = line.replace('##', '');
    } else if (line.startsWith('#')) {
      result = line.replace('#', '');
    }
    if (line.startsWith('*')) {
      result = line.replace('*', '');
    }
    if (line.startsWith('-')) {
      result = line.replace('-', '');
    }
    if (line.startsWith('+')) {
      result = line.replace('+', '');
    } else {
      result = line;
    }
    result = result.length > 20 ? result.substring(0, 20) : result;
    return result;
  }

  /**
   * Update userBiz, userSales of userSupply.
   *
   * Business step will not be changed.
   * Call terminated business will be fail.
   *
   * @param type 'biz'|'sales'|'supply'
   * @param manager User uid.
   */
  async updateManager(type: 'biz' | 'sales' | 'supply', manager: string) {
    const url = `/api/admin/biz/${this.docSeq}/manager`;
    const formData = new FormData();
    formData.set('type', type);
    formData.set('userUid', manager);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    } else {
      window.alert(
        `담당자 등록 중 에러가 발생했습니다. ERROR CODE: ${res.data.code}`,
      );
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Update sales commission rate.
   * The rate value should have between 0 and 1
   *
   * business step will not be changed.
   *
   *
   * @param rate
   */
  async updateCommissionRate(rate: number) {
    const url = `/api/admin/biz/${this.docSeq}/commission`;
    const formData = new FormData();
    formData.set('rate', `${rate}`);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  async updateBizType(type: TypeBusiness) {
    const url = `/api/admin/biz/${this.docSeq}/type`;
    const formData = new FormData();
    formData.set('type', type);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      const biz = res.data.body;
      return await Business.getBusiness(biz.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }
  /**
   * Update server data of business abstract.
   * and returns updated business object.
   * business step will not be changed.
   *
   * @param biz_abstract abstract of business
   */
  async updateBizAbstract(biz_abstract: string) {
    const url = `/api/admin/biz/${this.docSeq}/abstract`;
    const formData = new FormData();
    formData.set('bizAbstract', biz_abstract);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      const biz = res.data.body;
      return await Business.getBusiness(biz.docSeq);
    }
    throw Error(
      `Fail to update business abstract : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Update server data of quotation product information
   * and returns updated business object.
   *
   * business step will not be changed.
   * Update attempt will fail if business step is {@link TypeBusinessStatus.order} or more.
   *
   * @param data product info.
   */
  async updateProductList(data: Requirements) {
    const url = `/api/admin/biz/${this.docSeq}/product-list`;
    const formData = new FormData();
    formData.append('requirements', JSON.stringify(data));
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      const biz = res.data.body;
      return await Business.getBusiness(biz.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Fix updated product list and request quotation publish
   *
   * Current business step will forward to {@link TypeBusinessStatus.quoteReq}
   * Update attempt will fail if business step is {@link TypeBusinessStatus.order} or more.
   *
   * @returns updated business document.
   */
  async productListFixed() {
    const url = `/api/admin/biz/${this.docSeq}/req-quote`;
    const formData = new FormData();
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * A quotation will be generated, when current method processes successfully.
   * Logged user who call current method, should be same with {@link Business.userBiz}. or else it will be rejected.
   *
   * Business step will forward to {@link TypeBusinessStatus.quoteRes}
   * Update attempt will fail if business step is {@link TypeBusinessStatus.order} or more.
   */
  async approveQuotation(
    items: ProductTuple[],
    memo: string,
    to: string,
    cc: string,
    commissionRate: number,
  ) {
    const url = `/api/admin/biz/${this.docSeq}/publish-quote`;
    const requirements = new Requirements();
    requirements.productList = items;
    const formData = new FormData();
    formData.append('requirements', JSON.stringify(requirements));
    formData.set('to', to);
    formData.set('cc', cc);
    formData.set('commission', commissionRate.toFixed());
    formData.set('memo', memo);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  async rejectQuotation() {
    const url = `/api/admin/biz/${this.docSeq}/reject-quote`;
    const formData = new FormData();
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Add an order sheet
   * Logged user who call current method, should be same with {@link Business.userBiz}.
   * or else it will be rejected.
   *
   * Business step will forward to {@link TypeBusinessStatus.supply}
   * Update attempt will fail if business step is not {@link TypeBusinessStatus.quoteRes}
   *
   * @param file PDF or image data
   *        FIXME: changing any to else.
   */
  async processOrder(file: any, supply: number, tax: number) {
    const url = `/api/admin/biz/${this.docSeq}/order`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('supply', `${supply}`);
    formData.append('tax', `${tax}`);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }
  async rejectOrder() {
    const url = `/api/admin/biz/${this.docSeq}/reject-order`;
    const formData = new FormData();
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  async updateDeliveryList(deliveryList: ProductTuple[]) {
    const url = `/api/admin/biz/${this.docSeq}/delivery-list`;
    const formData = new FormData();
    formData.set('deliveryList', JSON.stringify(deliveryList));
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Add an delivery completion sheet
   * Logged user who call current method, should be either
   * {@link Business.userSupply} or {@link Business.userBiz}.
   * or else it will be rejected.
   *
   * Business step will forward to {@link TypeBusinessStatus.calculate}
   * Update attempt will fail if business step is not {@link TypeBusinessStatus.supply}
   *
   * @param file PDF or image data
   *        FIXME: changing any to else.
   */
  async processDeliveryCompletion(file: any) {
    const url = `/api/admin/biz/${this.docSeq}/delivery`;
    const formData = new FormData();
    formData.append('file', file);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Add a purchase of sales tax
   * Logged user who call current method, should be same with {@link Business.userBiz}.
   * The step of current business should have {@link TypeBusinessStatus.order} or more
   *
   * @param type purchase or sales
   * @param file PDF of image data
   * @param priceSupply total price without tax
   * @param priceTax tax
   * @param taxId id for tax (cooperate registration number)
   */
  async processTax(
    type: 'purchase' | 'sales',
    file: any,
    priceSupply: number,
    priceTax: number,
    taxId: string,
  ) {
    const url = `/api/admin/biz/${this.docSeq}/tax/`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('priceSupp', `${priceSupply}`);
    formData.append('priceTax', `${priceTax}`);
    formData.append('taxId', `${taxId}`);
    formData.append('purchase', `${type === 'purchase'}`);
    const res = await Api.put(url, formData);
    if (res.data.code === 0) {
      return await Business.getBusiness(this.docSeq);
    }
    throw Error(
      `Fail to update business type : ${res.data.code} : ${res.data.reason}`,
    );
  }

  /**
   * Cancel current business.
   * Logged user who call current method, should be same with {@link Business.userBiz}.
   *
   * Business step will forward to {@link TypeBusinessStatus.cancel}
   */
  async cancelBusiness() {
    return this;
  }

  /**
   * Add a commission pay document which to salesman.
   * tax price calculation can be either VAT or 3.3 (and more)
   *
   * Business step will forward to {@link TypeBusinessStatus.done}
   *
   * @param file
   * @param priceSupply
   * @param priceTax
   */
  async processCommission(file: any, priceSupply: number, priceTax: number) {
    return this;
  }

  checkViewPossible(item: ViewItem) {
    switch (item) {
      case 'abstract':
      case 'bizType':
      case 'salesMgr':
      case 'bizMgr':
      case 'productMgr':
      case 'commissionRate':
        return true;
      case 'quoteReg':
        return this.requirements.productList.length > 0;
      case 'quoteRes':
        switch (this.bizStep) {
          case TypeBusinessStatus.initial:
          case TypeBusinessStatus.quoteReq:
            return false;
          case TypeBusinessStatus.quoteRes:
          case TypeBusinessStatus.supply:
          case TypeBusinessStatus.calculate:
          case TypeBusinessStatus.close:
          case TypeBusinessStatus.done:
            return true;
          case TypeBusinessStatus.cancel:
            return this.quotation.length > 0;
        }
        break;
      case 'orderInfo':
        return this.order !== undefined;
      case 'delivery':
        switch (this.bizStep) {
          case TypeBusinessStatus.initial:
          case TypeBusinessStatus.quoteReq:
          case TypeBusinessStatus.quoteRes:
            return false;
          case TypeBusinessStatus.supply:
          case TypeBusinessStatus.calculate:
          case TypeBusinessStatus.close:
          case TypeBusinessStatus.done:
            return true;
          case TypeBusinessStatus.cancel:
            return this.quotation.length > 0;
          default:
            return false;
        }
      case 'taxPurchase':
        return this.taxPurchase !== undefined;
      case 'taxSale':
        return this.taxSales !== undefined;
    }
  }

  checkEditPossible(item: ViewItem) {
    const sess = loadUserInfo();
    if (sess === undefined) return false;

    if (
      this.bizStep === TypeBusinessStatus.close ||
      this.bizStep === TypeBusinessStatus.cancel ||
      this.bizStep === TypeBusinessStatus.done
    )
      return false;

    switch (item) {
      case 'bizType':
        switch (this.bizStep) {
          case TypeBusinessStatus.initial:
          case TypeBusinessStatus.quoteReq:
            return true;
          case TypeBusinessStatus.quoteRes:
          case TypeBusinessStatus.supply:
          case TypeBusinessStatus.calculate:
            break;
        }
        break;
      case 'abstract':
      case 'bizMinute':
        // 관리자, 사업담당자, 영업사원만 수정할 수 있다.
        // 사업담당자와 영업사원은 자신이 지정된 사업만 수정할 수 있다.
        // 마감 단계 전까지 수정할 수 있다.
        switch (sess.level) {
          case TypeUserLevel.admin:
            break;
          case TypeUserLevel.business:
            if (this.userBiz === sess.uid) break;
            else return false;
          case TypeUserLevel.sales:
            if (this.userSales === sess.uid) break;
            else return false;
          default:
            return false;
        }
        return true;
      case 'salesMgr':
        // 1회 지정 후 수정이 불가능 하다.
        // 관리자, 사업담당자 수정할 수 있다.
        if (this.userSales !== undefined) return false;
        switch (sess.level) {
          case TypeUserLevel.admin:
          case TypeUserLevel.business:
            return true;
        }
        return false;
      case 'bizMgr':
        // 관리자와 운용자만 수정할 수 있다.
        // 발주 이후 수정이 불가능 하다.
        switch (sess.level) {
          case TypeUserLevel.admin:
          case TypeUserLevel.operator:
            switch (this.bizStep) {
              case TypeBusinessStatus.initial:
              case TypeBusinessStatus.quoteReq:
              case TypeBusinessStatus.quoteRes:
                return true;
            }
            return false;
          default:
            return false;
        }
      case 'productMgr':
        return true;
      case 'commissionRate':
        switch (this.bizStep) {
          case TypeBusinessStatus.initial:
          case TypeBusinessStatus.quoteReq:
          case TypeBusinessStatus.quoteRes:
            return true;
          case TypeBusinessStatus.supply:
          case TypeBusinessStatus.calculate:
            break;
        }
        return false;
      case 'quoteReg':
        if (this.userBiz === undefined || this.userSales === undefined) {
          return false;
        }
        switch (sess.level) {
          case TypeUserLevel.admin:
            break;
          case TypeUserLevel.business:
            if (this.userBiz === sess.uid) break;
            else return false;
          case TypeUserLevel.sales:
            if (this.userSales === sess.uid) break;
            else return false;
          default:
            return false;
        }
        return this.bizStep === TypeBusinessStatus.initial;
      case 'quoteRes':
        switch (sess.level) {
          case TypeUserLevel.admin:
            break;
          case TypeUserLevel.business:
            if (this.userBiz === sess.uid) break;
            else return false;
          default:
            return false;
        }
        return this.bizStep === TypeBusinessStatus.quoteReq;
      case 'orderInfo':
        // 관리자, 지정된 사업담당자, 지정된 영업사원만 수정할 수 있다.
        // 견적발행 단계부터 수정할 수 있다.
        // 납품확인서, 매입/매출 세금계산서 중 하나라도 등록되면 변경할 수 없다.
        switch (this.bizStep) {
          case TypeBusinessStatus.quoteRes:
          case TypeBusinessStatus.supply:
            if (this.taxPurchase !== undefined) return false;
            if (this.taxSales !== undefined) return false;
            switch (sess.level) {
              case TypeUserLevel.admin:
                return true;
              case TypeUserLevel.business:
                return this.userBiz === sess.uid;
              case TypeUserLevel.sales:
                return this.userSales === sess.uid;
              default:
                return false;
            }
        }
        return false;
      case 'delivery':
      case 'taxPurchase':
      case 'taxSale':
        // 납품 단계이거나 비용처리 단계에서만 수정할 수 있다.
        // 관리자와 지정된 사업담당자만 수정할 수 있다.
        if (
          this.bizStep === TypeBusinessStatus.supply ||
          this.bizStep === TypeBusinessStatus.calculate
        ) {
          switch (sess.level) {
            case TypeUserLevel.admin:
              return true;
            case TypeUserLevel.business:
              return this.userBiz === sess.uid;
            default:
              return false;
          }
        }
        return false;
      case 'cancel':
        // 마감상태 전까지 수정할 수 있다.
        // 관리자와 지정된 사업당담자만 수정할 수 있다.
        switch (sess.level) {
          case TypeUserLevel.admin:
            return true;
          case TypeUserLevel.business:
            return this.userBiz === sess.uid;
          default:
            return false;
        }
    }
    return false;
  }
  checkStepProcessable(next: TypeBusinessStatus) {
    switch (next) {
      case TypeBusinessStatus.initial:
        break;
      case TypeBusinessStatus.quoteReq:
        switch (this.bizStep) {
          case TypeBusinessStatus.initial:
            if (this.requirements.productList.length === 0) return false;
            if (this.userBiz === undefined || this.userSales === undefined)
              return false;
            return true;
          case TypeBusinessStatus.quoteReq:
          case TypeBusinessStatus.quoteRes:
          case TypeBusinessStatus.supply:
          case TypeBusinessStatus.calculate:
          case TypeBusinessStatus.done:
          case TypeBusinessStatus.cancel:
            return false;
        }
        break;
      case TypeBusinessStatus.quoteRes:
        break;
      case TypeBusinessStatus.supply:
        break;
      case TypeBusinessStatus.calculate:
        break;
      case TypeBusinessStatus.close:
        break;
      case TypeBusinessStatus.done:
        break;
      case TypeBusinessStatus.cancel:
        break;
    }
    return false;
  }

  getRequirements() {
    const {requirements} = this;
    return Object.assign(new Requirements(), requirements);
  }
}

export class Product extends ProductDetail {
  getTuple(): ProductTuple {
    const tup = new ProductTuple();
    tup.unitPrice = this.priceUnit;
    tup.purchasePrice = this.pricePurchase;
    tup.title = this.title;
    tup.ref = this.seq;
    tup.unit = 'EA';
    return tup;
  }
}
