import Api, {ApiResp} from './Api';
import {Product} from '../type_doc';

export default class ApiProduct {
  static async getProductList(
    searchKey?: string,
    promotion?: boolean,
    page?: number,
    size?: number,
    draft?: boolean,
  ): Promise<ApiResp<Product[]>> {
    let query = '';
    if (searchKey) query += `&searchKey=${searchKey}`;
    if (promotion) query += `&promotion=${promotion}`;
    if (page) query += `&page=${page}`;
    if (size) query += `&size=${size}`;
    if (draft) query += `&draft=${draft}`;
    const url = `/api/admin/product/-/list?` + query;
    const res = await Api.get(url);
    return res.data;
  }
  // static async getSearchProductList(text: string): Promise<ApiResp<dataDict<number, string>[]>> {
  //     const url = `/api/admin/product/-/search?text=${text}`;
  //     const res = await Api.get(url);
  //     return res.data;
  // }
  static async getProduct(productSeq: number): Promise<ApiResp<Product>> {
    const url = `/api/admin/product/${productSeq}`;
    const res = await Api.get(url);
    return res.data;
  }
  static async createProduct(title: string): Promise<ApiResp<Product>> {
    const formData = new FormData();
    formData.set('title', title);
    const url = `/api/admin/product`;
    const res = await Api.post(url, formData);
    return res.data;
  }
  static async deleteProduct(productSeq: number): Promise<ApiResp<boolean>> {
    const url = `/api/admin/product/${productSeq}`;
    const res = await Api.delete(url);
    return res.data;
  }
}
