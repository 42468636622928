import React from 'react';

interface Props {
  onChange: (id: string) => void;
  placeholder?: string;
  value?: string;
  onBlur?: () => void;
}

class State {
  value: string = '';
}

/**
 * Input form 내 하나의 검색결과만 사용한다.
 */
export default class InputId extends React.Component<Props, State> {
  state = {
    value: this.props.value === undefined ? '' : this.props.value,
  };

  onChangeID(event: any) {
    this.setState({value: event.target.value});
    this.props.onChange(event.target.value);
  }

  render() {
    return (
      <div className="input-group">
        <span className="input-group-text" id="basic-addon3">
          @
        </span>
        <input
          placeholder={
            this.props.placeholder === undefined
              ? undefined
              : this.props.placeholder
          }
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3 basic-addon4"
          onChange={this.onChangeID.bind(this)}
          onBlur={this.props.onBlur ? this.props.onBlur.bind(this) : undefined}
        />
      </div>
    );
  }
}
