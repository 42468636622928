import React from 'react';
import {Company, SearchDict} from '../types';
import {IoCloseCircle} from 'react-icons/io5';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';

interface Props {
  onChange: (company: (Company | string)[]) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  value?: Company | string | (Company | string)[];
  single?: boolean;
  registered?: boolean;
  invalid?: boolean;
}

class State {
  selected: (Company | string)[] = [];
  searchResult?: Company[] = [];
  inputText: string = '';
  keyFocused: boolean = false;
}

/**
 * Input form 내 하나의 검색결과만 사용한다.
 */
export default class InputSearchCompany extends React.Component<Props, State> {
  state = new State();
  inputRef: any;

  initializeCompany() {
    if (Array.isArray(this.props.value)) {
      this.setState({selected: [...this.props.value]});
    } else if (this.props.value === undefined) {
      this.setState({selected: []});
    } else {
      this.setState({selected: [this.props.value]});
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const p = JSON.stringify(prevProps.value);
    const n = JSON.stringify(this.props.value);
    if (p !== n) {
      this.initializeCompany();
    }
  }

  async searchCompany(key: string) {
    const url = `/api/admin/company/-/search?text=${key}`;
    const res: AxiosResponse<ApiResp<SearchDict<number, Company>[]>> =
      await Api.get(url);
    const {data} = res;
    const body = data.body;
    const dataArray = new Array<Company>();
    for (const dict of body) {
      dataArray.push(dict.value);
    }
    this.setState({searchResult: dataArray});
  }

  onKeyPress(event: any) {
    const {selected} = this.state;
    if (event.key === 'Tab' && !this.props.registered) {
      selected.push(this.state.inputText);
      this.setState({selected: selected, keyFocused: false, inputText: ''});
      this.props.onChange(this.state.selected);
    }
  }

  onFocusInput() {
    this.setState({keyFocused: true});
  }
  onFocusBlur() {
    setTimeout(() => {
      this.setState({keyFocused: false});
    }, 500);
  }

  removeBadge(index: number) {
    const {selected} = this.state;
    if (selected === undefined || this.props.disabled) return;
    selected.splice(index, 1);
    this.setState({selected: selected});
    this.props.onChange(this.state.selected);
  }

  componentDidMount() {
    this.initializeCompany();
  }

  onChange(event: any) {
    const text = event.target.value;
    this.setState({inputText: text});
    this.searchCompany(text).catch(e =>
      console.error('ERR : ' + JSON.stringify(e)),
    );
  }

  render() {
    const {selected, searchResult, inputText, keyFocused} = this.state;

    const badge = selected?.map((value, index) => {
      let title = <></>;
      if (typeof value === 'string') {
        title = <span>{value}</span>;
      } else {
        title = <span>{value.name}</span>;
      }
      // const type =
      //   typeof value === 'string' ? 'text-bg-danger' : 'text-bg-success';
      const type = 'text-bg-primary';
      return (
        <span key={index} className={`badge ${type}`}>
          <span
            className={'sp-form-badge'}
            onClick={() => {
              this.removeBadge(index);
            }}>
            <IoCloseCircle size={15} style={{marginRight: 6}} />
          </span>
          <span>{title}</span>
        </span>
      );
    });

    const results = searchResult?.map((data, index) => {
      if (index < 5) {
        return (
          <li
            key={index}
            onClick={() => {
              const {selected} = this.state;
              selected.push(data);
              this.setState({
                searchResult: undefined,
                selected: selected,
                inputText: '',
              });
              this.props.onChange(this.state.selected);
            }}>
            <span className="dropdown-item">{`${data.name}`}</span>
          </li>
        );
      }
    });

    return (
      <>
        <div
          className={
            'form-control form-control-sm d-flex ' +
            (this.props.invalid ? 'is-invalid' : '')
          }
          style={{
            overflowX: 'auto',
            backgroundColor: this.props.disabled ? '#e9ecef' : undefined,
          }}>
          <div className={'d-flex gap-2'}>{badge}</div>
          {(badge.length === 0 || !this.props.single) && (
            <input
              ref={ref => (this.inputRef = ref)}
              type="text"
              disabled={this.props.disabled}
              className={'sp-form-hidden'}
              placeholder={this.props.placeholder}
              onChange={this.onChange.bind(this)}
              onFocus={this.onFocusInput.bind(this)}
              onBlur={this.onFocusBlur.bind(this)}
              onKeyDown={this.onKeyPress.bind(this)}
              value={inputText}
            />
          )}
        </div>
        {searchResult && keyFocused && (
          <>
            <ul
              className={'dropdown-menu show'}
              style={{zIndex: 3000, top: 32}}>
              {results}
            </ul>
          </>
        )}
      </>
    );
  }
}
