import Business, {FixedDocument} from '../../../type_doc';
import React from 'react';
import ModalContainer, {TypeModal} from '../ModalContainer';
import InputNumberWithComma from '../../../comp/InputNumberWithComma';

interface Props {
  business?: Business;
  onChanged: () => void;
}
class State {
  document?: FixedDocument;
  file?: File;
  workSupply = 0;
  workTax = 0;
}

export default class ModalBusinessProcessOrder extends React.Component<
  Props,
  State
> {
  state = new State();

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.business);
    const pp = JSON.stringify(prevProps.business);
    if (np !== pp) {
      this.updateState();
    }
  }

  updateState() {
    const {business} = this.props;
    if (business === undefined) return;
    if (business.quotation.length === 0) return;
    const supply = business.quotation[0].priceSupply;
    const tax = business.quotation[0].priceTax;
    this.setState({
      document: business.order || new FixedDocument(),
      workSupply: supply === undefined ? 0 : supply,
      workTax: tax === undefined ? 0 : tax,
    });
  }

  onChangeFile(event: any) {
    const {document} = this.state;
    if (event.target.files) {
      console.log(event.target.files);
      this.setState({file: event.target.files[0] as File});
    }
  }

  onChangeSupply(value: number) {
    this.setState({
      workSupply: value,
      workTax: Number((value * 0.1).toFixed()),
    });
  }

  onChangeTax(value: number) {
    this.setState({workTax: value});
  }

  async onEditFile() {
    if (!this.validData()) return false;

    const {file, workSupply, workTax} = this.state;
    if (this.props.business === undefined || file === undefined) return false;

    const result = await this.props.business.processOrder(
      file,
      workSupply,
      workTax,
    );
    if (result) {
      this.props.onChanged();
    }
    return result !== undefined;
  }
  cancelEdit() {
    const {business} = this.props;
    const supply = business?.quotation[0].priceSupply;
    const tax = business?.quotation[0].priceTax;
    this.setState({
      document: this.props.business?.order || new FixedDocument(),
      file: undefined,
      workSupply: supply === undefined ? 0 : supply,
      workTax: tax === undefined ? 0 : tax,
    });
  }

  validData() {
    const {file} = this.state;
    // 입력내용 누락 확인
    if (file === undefined) {
      alert('파일을 선택해주세요.');
      return false;
    }
    return true;
  }

  render() {
    const {document, workSupply, workTax} = this.state;

    const ns =
      JSON.stringify(document) !==
      JSON.stringify(this.props.business?.requirements);
    return (
      <ModalContainer
        divId={'processOrder'}
        title={'발주서'}
        onStore={this.onEditFile.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        type={TypeModal.EDIT}>
        <div>
          <div className={'container-fluid'}>
            <div className="input-group mb-1">
              <div style={{flex: 1}}>
                <input
                  type="file"
                  className={'form-control '}
                  placeholder={'파일을 등록하세요'}
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  onChange={this.onChangeFile.bind(this)}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>공급가</div>
              <div className={'col-sm-8'}>
                <InputNumberWithComma
                  className={'form-control form-control-sm'}
                  value={workSupply}
                  onChange={this.onChangeSupply.bind(this)}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>세금</div>
              <div className={'col-sm-8'}>
                <InputNumberWithComma
                  className={'form-control form-control-sm'}
                  value={workTax}
                  onChange={this.onChangeTax.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
