import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {Company, TypeUserLevel, User} from '../../types';
import ApiCompany from '../../data/ApiCompany';
import InputSearchUser from '../../comp/InputSearchUser';
import {loadUserInfo} from '../PageUtil';
import InputEmail from '../../comp/InputEmail';
import InputE164 from '../../comp/InputE164';

interface Props {
  onChanged: () => void;
}
class State {
  workSalesMan: User[] = [];
  workCompany: Company = new Company();
}

export default class ModalUserCompanyCreate extends React.Component<
  Props,
  State
> {
  state = new State();

  onChangeName(event: any) {
    const {workCompany} = this.state;
    workCompany.name = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeTel(value: string) {
    const {workCompany} = this.state;
    workCompany.tel = value;
    this.setState({workCompany: workCompany});
  }

  onChangeCorpRegNum(event: any) {
    const {workCompany} = this.state;
    workCompany.corpRegNum = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeEmail(mail: string) {
    const {workCompany} = this.state;
    workCompany.email = mail;
    this.setState({workCompany: workCompany});
  }

  onChangeLocation1(event: any) {
    const {workCompany} = this.state;
    workCompany.location1 = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeLocation2(event: any) {
    const {workCompany} = this.state;
    workCompany.location2 = event.target.value;
    this.setState({workCompany: workCompany});
  }

  onChangeHomepage(event: any) {
    const {workCompany} = this.state;
    workCompany.homepage = event.target.value;
    this.setState({workCompany: workCompany});
  }
  async onCreateCompany() {
    if (!this.validData()) return false;

    const {workCompany} = this.state;
    const result = await ApiCompany.createCompany(workCompany);
    if (result.code === 0) {
      this.props.onChanged();
    } else if (result.code === 102) {
      window.alert('이미 존재하는 사업자 번호입니다.');
    } else {
      window.alert(`에러가 발생하였습니다. ERROR CODE: ${result.code}`);
    }
    return result.code === 0;
  }
  cancelCreate() {
    this.setState({workCompany: new Company()});
  }

  validData() {
    const {workCompany} = this.state;
    // 입력내용 누락 확인
    if (workCompany.name === '') {
      alert('이름을 입력해주세요.');
      return false;
    } else if (workCompany.tel === '') {
      alert('전화번호를 입력해주세요.');
      return false;
    } else if (workCompany.corpRegNum === '') {
      alert('사업자번호를 입력해주세요.');
      return false;
    } else if (workCompany.email === '') {
      alert('이메일 주소를 입력해주세요.');
      return false;
    }
    return true;
  }
  disableConfirm() {
    const {workCompany} = this.state;
    if (workCompany.name === '') {
      return true;
    } else if (workCompany.tel === '') {
      return true;
    } else if (workCompany.corpRegNum === '') {
      return true;
    } else if (workCompany.email === '') {
      return true;
    }
    return false;
  }

  onChangeUser(user: (User | string)[]) {
    const {workCompany} = this.state;
    const uidList = [];
    for (const u of user) {
      if (typeof u !== 'string') {
        uidList.push(u.uid);
      }
    }
    workCompany.manager = uidList;
    this.setState({workCompany: workCompany});
  }

  render() {
    const myInfo = loadUserInfo();

    const {workCompany} = this.state;
    const managers = (
      <>
        <option value={'1'}>담당자1</option>
        <option value={'2'}>담당자2</option>
      </>
    );
    return (
      <ModalContainer
        divId={'companyCreate'}
        title={'파트너 회사 생성'}
        disableConfirm={this.disableConfirm()}
        onStore={this.onCreateCompany.bind(this)}
        onCancel={this.cancelCreate.bind(this)}
        type={TypeModal.CREATE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>회사 이름</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeName.bind(this)}
                  value={workCompany.name || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>전화번호</div>
              <div className={'col-sm-8'}>
                <InputE164
                  onChange={this.onChangeTel.bind(this)}
                  value={workCompany.tel}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>사업자 번호</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeCorpRegNum.bind(this)}
                  value={workCompany.corpRegNum || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>대표 이메일</div>
              <div className={'col-sm-8'}>
                <InputEmail
                  onChange={this.onChangeEmail.bind(this)}
                  value={workCompany.email}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>회사 위치 1</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeLocation1.bind(this)}
                  value={workCompany.location1 || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>회사 위치 2</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeLocation2.bind(this)}
                  value={workCompany.location2 || ''}
                />
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>홈페이지</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  onChange={this.onChangeHomepage.bind(this)}
                  value={workCompany.homepage || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
