import React from 'react';
import Business, {TypeBusinessStatus} from '../type_doc';
import {numberWithCommas} from '../data/DataUtils';
import {loadUserInfo, PAGE_URLS} from '../pages/PageUtil';
import {TypeUserLevel} from '../types';
import ModalBusinessDeliveryList from '../pages/modal/ModalBusiness/ModalBusinessDeliveryList';
import ModalBusinessProcessDelivery from '../pages/modal/ModalBusiness/ModalBusinessProcessDelivery';
import PdfViewer from '../comp/PdfViewer';

interface Props {
  business: Business;
  onChanged: () => void;
}
export default class DocDelivery extends React.Component<Props, any> {
  renderDoc(docType: string, file: string) {
    const {business} = this.props;
    switch (docType) {
      case 'image/gif':
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return (
          <img
            src={`/api/admin/biz/${business.docSeq}/order?path=${file}`}
            alt={''}
            style={{maxWidth: '100%'}}
          />
        );
      case 'application/pdf':
        return (
          <PdfViewer
            url={`/api/admin/biz/${business.docSeq}/order?path=${file}`}
          />
        );
      default:
        return <></>;
    }
  }

  render() {
    const user = loadUserInfo();
    if (user === undefined) {
      console.error('User session not exist');
      window.location.replace('/');
      return <></>;
    }
    const {business} = this.props;
    const products = business.deliveryList?.map((value, index) => {
      const title = value.title;
      return (
        <tr key={index}>
          <td
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            {title}
          </td>
          <td className={'text-end'}>{numberWithCommas(value.amount)}</td>
        </tr>
      );
    });

    let deliveryEnable = false;
    switch (user.level) {
      case TypeUserLevel.business:
        deliveryEnable = business.userBiz === user.uid;
        break;
      case TypeUserLevel.sales:
        deliveryEnable = business.userSales === user.uid;
        break;
    }
    let disableEdit = false;
    switch (business.bizStep) {
      case TypeBusinessStatus.initial:
        break;
      case TypeBusinessStatus.quoteReq:
        break;
      case TypeBusinessStatus.quoteRes:
        break;
      case TypeBusinessStatus.supply:
        break;
      case TypeBusinessStatus.calculate:
        break;
      case TypeBusinessStatus.close:
      case TypeBusinessStatus.done:
      case TypeBusinessStatus.cancel:
        disableEdit = true;
        break;
    }

    return (
      <div className={'card'}>
        <div className={'card-header d-flex justify-content-between'}>
          <span>납품 확인서</span>
          {deliveryEnable && !disableEdit && (
            <div className={'btn-group btn-group-sm'}>
              <button
                className={'btn btn-sm btn-outline-primary'}
                data-bs-toggle="modal"
                data-bs-target="#processDelivery">
                납품 확인서 등록
              </button>
              <a
                className={'btn btn-sm btn-outline-primary'}
                href={`${PAGE_URLS.HTML_VIEWER}?business=${business.docSeq}&delivery=true`}
                target={'_blank'}
                rel="noreferrer">
                출력
              </a>
              <button
                className={'btn btn-sm btn-outline-danger'}
                data-bs-toggle="modal"
                data-bs-target="#updateDeliveryList">
                목록 수정
              </button>
            </div>
          )}
        </div>
        <div className={'card-body card-quotation'}>
          <table className={'table table-sm table-bordered table-striped'}>
            <thead>
              <tr>
                <th>제품명</th>
                <th className={'text-end'} style={{minWidth: 50}}>
                  수량
                </th>
              </tr>
            </thead>
            <tbody>{products}</tbody>
          </table>
          {business.delivery === undefined ? (
            <div className={'mt-5 d-flex justify-content-center'}>
              등록된 파일이 없습니다.
            </div>
          ) : (
            this.renderDoc(business.delivery.fileType, business.delivery.file)
          )}
        </div>
        <ModalBusinessDeliveryList
          business={business}
          onChanged={this.props.onChanged.bind(this)}
        />
        <ModalBusinessProcessDelivery
          business={business}
          onChanged={this.props.onChanged.bind(this)}
        />
      </div>
    );
  }
}
