import React from 'react';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const pdfjs = pdfjsLib;

interface Props {
  url: string;
}

export default class PdfViewer extends React.Component<Props, any> {
  state = {
    canvasUrls: new Array(0),
  };

  loadingTask: any;
  curPage = 1;
  numPage = 0;
  scale = 1;

  componentDidMount() {
    console.log('url : ' + this.props.url);
    console.log(pdfjsLib.version);
    this.loadPdfFromUrl(this.props.url).catch(e => {
      // console.error(e);
    });
  }

  async loadPdfFromUrl(url: string, scale = 1) {
    this.loadingTask = await pdfjs.getDocument(url).promise;
    this.numPage = this.loadingTask.numPages;

    const page = await this.loadingTask.getPage(1);
    this.calculateScale(page);

    const viewPage = await this.loadingTask.getPage(this.curPage);
    this.handlePages(viewPage);
  }

  calculateScale(page: pdfjsLib.PDFPageProxy) {
    const element = document.getElementById('viewpdf');
    if (element === null) {
      return;
    }
    const rect = element.getBoundingClientRect();
    const nw = rect.width;

    const viewport = page.getViewport({scale: 1});
    const ow = viewport.width;

    this.scale = nw / ow - 0.15;
  }

  handlePages(page: pdfjsLib.PDFPageProxy) {
    console.log('>> ' + this.curPage);
    const element = document.getElementById('viewpdf');
    if (element === null) {
      return;
    }

    if (this.curPage !== page.pageNumber) {
      if (this.loadingTask !== null && this.curPage <= this.numPage) {
        this.loadingTask
          .getPage(this.curPage)
          .then(this.handlePages.bind(this));
      }
      return;
    }
    const viewport = page.getViewport({scale: this.scale});
    const {width, height} = viewport;
    const div = document.createElement('div');

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.setAttribute('style', 'margin: 10px;');
    const ctx = canvas.getContext('2d');
    const renderContext = {
      canvasContext: ctx!,
      viewport: viewport,
    };
    if (ctx !== null) {
      page.render(renderContext);
      div.appendChild(canvas);
      element.appendChild(div);
    }

    this.curPage++;
    if (this.loadingTask !== null && this.curPage <= this.numPage) {
      this.loadingTask.getPage(this.curPage).then(this.handlePages.bind(this));
    }
  }

  render() {
    const imgs = this.state.canvasUrls.map((url: string, index: number) => {
      console.log('index : ' + index);
      return <img key={index} src={url} alt="" />;
    });
    return (
      <div
        id="viewpdf"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: 'grey',
        }}
      />
    );
  }
}
