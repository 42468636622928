import {MenuNode} from '../types';

const allTreeData = new MenuNode('all', '전체');
// const env = new MenuNode('env', '환경산업');
// const it = new MenuNode('it', '통신기술');
// const it_sw = new MenuNode('it_sw', '소프트웨어');
// const it_hw = new MenuNode('it_hw', '하드웨어');

// it.addChild(it_sw);
// it.addChild(it_hw);
// allTreeData.addChild(env);
// allTreeData.addChild(it);

export {allTreeData};
