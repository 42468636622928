import React from 'react';
import {UrlParams} from '../types';
import Api from '../data/Api';
import {AxiosResponse} from 'axios';

export default class PageHtmlViewer extends React.Component<any, any> {
  state = {
    file: '',
    doc: '',
  };

  componentDidMount() {
    this._loadHtmlDoc();
  }

  _loadHtmlDoc() {
    const param = window.location.search;
    const urlParam = UrlParams.parse(param);
    const biz = urlParam.get('business');
    if (biz === undefined) {
      console.error('Document not specified.');
      return <></>;
    }
    const seq = Number(biz as string);
    const delivery = urlParam.get('delivery');
    let url = '';
    if (delivery !== undefined) {
      url = `/api/admin/biz/${seq}/delivery-list`;
    } else {
      const doc = urlParam.get('doc');
      if (doc === undefined) {
        console.error('Document not specified.');
        return <></>;
      }
      const file = doc as string;
      url = `/api/admin/biz/${seq}/quote?path=${file}`;
    }

    Api.get(url)
      .then((result: AxiosResponse<string>) => {
        this.setState({doc: result.data});
        setTimeout(() => {
          window.print();
        }, 3000);
      })
      .catch(e => {
        window.alert('출력 데이터 로딩에 실패 했습니다.');
      });
  }
  render() {
    return (
      <iframe
        srcDoc={this.state.doc}
        width={'100%'}
        height={'100%'}
        style={{overflow: 'hidden'}}
      />
    );
  }
}
