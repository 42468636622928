import React from 'react';
import TopBar from '../comp/TopBar';
import {Privacy} from '../types';
import {loadUserInfo, validationPassword, validationTel} from './PageUtil';
import InputE164 from '../comp/InputE164';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';
import ApiSettings from '../data/ApiSettings';
import {toast, ToastContainer} from 'react-toastify';

class State {
  user: Privacy = new Privacy();
  password: string = '';
  passwordConfirm: string = '';
  passwordBrochure: string = '';
}
export default class PageEditProfile extends React.Component<any, any> {
  state = new State();

  async loadData() {
    const user = loadUserInfo();
    console.log('user: ' + JSON.stringify(user));
    if (user === undefined) return;
    const result = await ApiSettings.getUserPrivacy(user.uid);
    if (result.code === 0) {
      this.setState({user: result.body});
    }

    const url = `/api/admin/brochure/secret/${user.uid}`;
    const pass: AxiosResponse<ApiResp<any>> = await Api.get(url);
    if (pass.data.code === 0) {
      console.log('DATA >> ' + JSON.stringify(pass.data.body));
      this.setState({passwordBrochure: pass.data.body.pass});
    } else {
      console.log('brochure password was not set.');
    }
  }
  componentDidMount() {
    this.loadData().catch(e => console.error(JSON.stringify(e)));
  }
  onChangeTel(value: string) {
    const {user} = this.state;
    user.tel = value;
    this.setState({user: user});
  }
  onUpdateTel() {
    const {user} = this.state;
    if (!validationTel(user.tel)) {
      alert('전화번호를 형식에 맞게 입력해주세요.');
      return;
    }

    const formData = new FormData();
    formData.set('tel', user.tel);
    const url = `/api/admin/privacy/${user.uid}`;
    Api.put(url, formData)
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          toast(`전화번호가 변경되었습니다.`);
        } else {
          console.error('!!!!!');
          window.alert(
            `전화번호 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }
  onChangePassword(event: any) {
    this.setState({password: event.target.value});
  }
  onChangePasswordConfirm(event: any) {
    this.setState({passwordConfirm: event.target.value});
  }
  onChangeBrochurePassword(event: any) {
    this.setState({passwordBrochure: event.target.value});
  }

  validPassword() {
    const {password, passwordConfirm} = this.state;
    // 입력내용 누락 확인
    if (password === '') {
      alert('비밀번호를 입력해주세요.');
      return false;
    } else if (passwordConfirm === '') {
      alert('비밀번호를 다시 한 번 입력해주세요.');
      return false;
    }
    // 비밀번호 일치 확인
    if (password !== passwordConfirm) {
      alert('비밀번호를 동일하게 입력해주세요.');
      return false;
    }
    // 비밀번호 복잡도 확인
    if (!validationPassword(password)) {
      alert('비밀번호는 최소 8자 이상의 숫자,영어를 조합하여 입력해주세요.');
      return false;
    }
    return true;
  }

  onUpdateBrochurePassword() {
    const userUid = loadUserInfo()?.uid;
    if (userUid === undefined) {
      window.alert('에러가 발생했습니다.');
      window.location.reload();
      return;
    }
    const check = (input: string) => {
      if (input.length !== 4) {
        return false;
      }
      for (let i = 0; i < input.length; i++) {
        if (isNaN(Number(input.charAt(i)))) {
          return false;
        }
      }
      return true;
    };

    if (!check(this.state.passwordBrochure)) {
      window.alert('4자리의 숫자만 입력 가능합니다.');
      return;
    }
    const url = `/api/admin/brochure/secret/${userUid}`;
    const form = new FormData();
    form.set('pass', this.state.passwordBrochure);
    Api.post(url, form)
      .then((result: AxiosResponse<ApiResp<void>>) => {
        if (result.data.code === 0) {
          toast(`브로셔 비밀번호가 변경되었습니다.`);
        } else {
          window.alert(
            '브로셔 비밀번호 변경에 실패 했습니다 CODE:' + result.data.code,
          );
        }
      })
      .catch(e => {
        window.alert('에러가 발생했습니다 CODE: 00');
      });
  }
  onDeleteBrochurePassword() {
    const userUid = loadUserInfo()?.uid;
    if (userUid === undefined) {
      window.alert('에러가 발생했습니다.');
      window.location.reload();
      return;
    }
    const url = `/api/admin/brochure/secret/${userUid}`;
    Api.delete(url)
      .then((result: AxiosResponse<ApiResp<void>>) => {
        if (result.data.code === 0) {
          this.setState({passwordBrochure: ''});
          toast(`브로셔가 초기화 되었습니다.`);
        } else {
          window.alert(
            '브로셔 비밀번호 제거에 실패 했습니다 CODE:' + result.data.code,
          );
        }
      })
      .catch(e => {
        window.alert('에러가 발생했습니다 CODE: 00');
      });
  }

  onUpdatePassword() {
    if (!this.validPassword()) return;
    const {passwordConfirm, user} = this.state;

    const formData = new FormData();
    formData.set('password', passwordConfirm);
    const url = `/api/admin/privacy/${user.uid}`;
    Api.put(url, formData)
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          toast(`비밀번호가 변경되었습니다.`);
        } else {
          window.alert(
            `비밀번호 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }

  render() {
    const {user, password, passwordConfirm, passwordBrochure} = this.state;
    return (
      <>
        <TopBar menu={'na'} />
        <div className={'container pt-5'} style={{marginTop: 60}}>
          <div className={'content'}>
            <div className={'row'}>
              <div className="col-lg-4">
                <h4 className="mt-0">계정 정보</h4>
                <p className={'text-muted'}>개인정보를 수정합니다. </p>
              </div>
              <div className="col-lg-8">
                <div>
                  <legend className="col-form-label pf-sub-title pt-0">
                    이메일
                  </legend>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      type="text"
                      className={'form-control '}
                      placeholder={'이메일을 입력하세요'}
                      aria-label="email address"
                      value={user.mail}
                    />
                  </div>
                </div>
                <div>
                  <legend className="col-form-label pf-sub-title pt-0">
                    전화번호
                  </legend>
                  <div className="input-group mb-3">
                    <InputE164
                      onChange={this.onChangeTel.bind(this)}
                      value={user.tel}
                      onSave={this.onUpdateTel.bind(this)}
                    />
                  </div>
                </div>
                <div>
                  <legend className="col-form-label pf-sub-title pt-0">
                    비밀번호
                  </legend>
                  <div className="input-group mb-1">
                    <input
                      type="password"
                      className={'form-control '}
                      placeholder={'비밀번호를 입력하세요'}
                      aria-label="password"
                      value={password}
                      onChange={this.onChangePassword.bind(this)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className={'form-control '}
                      placeholder={'비밀번호를 한번 더 입력하세요'}
                      aria-label="password"
                      value={passwordConfirm}
                      onChange={this.onChangePasswordConfirm.bind(this)}
                    />
                    <button
                      className={'btn btn-outline-primary'}
                      onClick={this.onUpdatePassword.bind(this)}>
                      저장
                    </button>
                  </div>
                </div>
                <div>
                  <legend className="col-form-label pf-sub-title pt-0">
                    브로셔 비밀번호
                  </legend>
                  <div className="input-group">
                    <input
                      type="text"
                      className={'form-control '}
                      placeholder={'네자리 숫자를 입력하세요'}
                      aria-label="password"
                      value={passwordBrochure}
                      onChange={this.onChangeBrochurePassword.bind(this)}
                    />
                    <button
                      className={'btn btn-outline-primary'}
                      onClick={this.onUpdateBrochurePassword.bind(this)}>
                      저장
                    </button>
                    <button
                      className={'btn btn-outline-danger'}
                      onClick={this.onDeleteBrochurePassword.bind(this)}>
                      삭제
                    </button>
                  </div>
                  <span className={'small text-muted'}>
                    비밀번호 설정을 하지 않으면 브로셔 기능을 사용할 수
                    없습니다. <br />
                    <a
                      href={`https://www.neosales.co.kr/user/${loadUserInfo()?.uid}`}
                      className={'link-primary'}
                      target={'_blank'}
                      rel={'noreferrer'}>
                      https://www.neosales.co.kr/user/{loadUserInfo()?.uid}{' '}
                    </a>
                    에서 물품조회 시 사용됩니다.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right" // 알람 위치 지정
          autoClose={3000} // 자동 off 시간
          hideProgressBar={false} // 진행시간바 숨김
          closeOnClick // 클릭으로 알람 닫기
          rtl={false} // 알림 좌우 반전
          pauseOnFocusLoss // 화면을 벗어나면 알람 정지
          draggable // 드래그 가능
          pauseOnHover // 마우스를 올리면 알람 정지
          theme="light"
        />
      </>
    );
  }
}
