import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {Company} from '../../types';
import ApiCompany from '../../data/ApiCompany';
interface Props {
  company?: Company;
  onChanged: () => void;
}
class State {
  name = '';
}

export default class ModalUserCompanyDelete extends React.Component<
  Props,
  State
> {
  state = new State();

  onChangeName(event: any) {
    this.setState({name: event.target.value});
  }
  async onDeleteCompany() {
    if (!this.validData()) return false;

    const {company} = this.props;
    if (company === undefined) return false;
    const result = await ApiCompany.deleteCompany(company.seq);
    if (result.code === 0) {
      this.props.onChanged();
      this.setState({name: ''});
    }
    return result.code === 0;
  }
  cancelDelete() {
    this.setState({name: ''});
  }

  validData() {
    const {company} = this.props;
    const {name} = this.state;
    if (company === undefined) return false;
    // 입력내용 누락 확인
    if (company?.name !== name) {
      alert('삭제할 파트너 회사의 이름을 정확하게 입력해주세요.');
      return false;
    }
    return true;
  }

  disableConfirm() {
    const {company} = this.props;
    const {name} = this.state;
    if (company === undefined) return false;
    if (company?.name !== name) {
      return true;
    }
    return false;
  }

  render() {
    const {company} = this.props;
    const {name} = this.state;
    return (
      <ModalContainer
        divId={'companyDelete'}
        title={'파트너 회사 삭제'}
        disableConfirm={this.disableConfirm()}
        onStore={this.onDeleteCompany.bind(this)}
        onCancel={this.cancelDelete.bind(this)}
        type={TypeModal.DELETE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2'}>
              <div className={'col-12 col-form-label'}>
                삭제할 파트너 회사의 이름을 입력해주세요.
              </div>
              <div className={'col-12 col-form-label'}>
                {company?.name || ''}
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>회사 이름</div>
              <div className={'col-sm-8'}>
                <input
                  type={'text'}
                  className={'form-control delete-confirm'}
                  placeholder={company?.name}
                  onChange={this.onChangeName.bind(this)}
                  value={name || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
