import React from "react";
import InputEmail from "../comp/InputEmail";
import {Link} from "react-router-dom";
import {PAGE_URLS, validationId} from "./PageUtil";
import {AxiosResponse} from "axios";
import Api, {ApiResp} from "../data/Api";

class State {
    id: string = '';
    validId?: boolean;
    mail: string = '';
    validMail?: boolean;
}

export default class PageFindPassword extends React.Component<any, any> {
    state = new State();

    onChangeEmail(value: string) {
        this.setState({mail: value});
    }

    onChangeUserId(event: any) {
        const id = event.target.value;
        const validId = id.length === 0 ? undefined : validationId(id);
        this.setState({id: id, validId: validId});
    }

    onEmailValidation(valid?: boolean) {
        this.setState({validMail: valid});
    }

    onClickCancel(event: any) {
        event.preventDefault();
        window.location.href = PAGE_URLS.SIGN_IN;
        return true;
    }

    onClickEnter(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.requestFindPassword().catch(e => {
            window.alert(`일시적으로 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.`);
        });
    }

    async requestFindPassword() {
        const {id, mail} = this.state;
        const result:AxiosResponse<ApiResp<any>> = await Api.post(`/api/admin/user/reset/password?id=${id}&email=${mail}`);
        if (result.data.code === 0) {
            window.alert('요청이 정상적으로 처리되었습니다. \n' +
                '메일을 확인해주세요.\n' +
                '메일의 재설정 링크는 10분간 유효합니다.');
            window.location.href = PAGE_URLS.SIGN_IN;
        } else if (result.data.code === 102) {
            window.alert('ID, Email 주소를 다시 확인해주세요.');
        } else if (result.data.code === 103) {
            window.alert('요청 횟수가 너무 많습니다.\n잠시 후 다시 시도해주세요.');
            window.location.href = PAGE_URLS.SIGN_IN;
        } else {
            window.alert(`일시적으로 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.`);
        }
    }

    render() {
        const {id, validId, mail, validMail} = this.state;

        return (
            <div className={'sign-container bg-light'}>
                <div className={'sign-box'}>
                    <div className={'sign-header text-center'}>
                        <h3 style={{fontWeight: 'bold'}}>NEO SALES 계정 비밀번호 재설정</h3>
                    </div>
                    <form onSubmit={this.onClickEnter.bind(this)}>
                        <div className={'sign-body'}>
                            <div className="mb-3">
                                <label className={'ps-3 pb-1'} htmlFor="floatingInput">ID</label>
                                <input
                                    type="text"
                                    className={"form-control " + (validId === undefined ? '' : validId ? 'is-valid' : 'is-invalid')}
                                    id="floatingInput"
                                    value={id}
                                    onChange={this.onChangeUserId.bind(this)}
                                />
                            </div>
                            <div className="mb-3">
                                <label className={'ps-3 pb-1'} htmlFor="floatingInput">이메일</label>
                                <InputEmail
                                    onValidationChange={this.onEmailValidation.bind(this)}
                                    onChange={this.onChangeEmail.bind(this)}
                                    value={mail}
                                />
                            </div>
                        </div>
                        <div className={'sign-footer'}>
                            <button
                                type={'submit'}
                                className={'btn btn-primary mt-2 w-100'}
                                disabled={!validId || !validMail}
                            >
                                비밀번호 재설정 메일 발송
                            </button>
                            <button
                                className={'btn btn-outline-secondary mt-2 w-100'}
                                onClick={this.onClickCancel.bind(this)}
                            >
                                취소
                            </button>
                            <div className={'mt-3 d-flex justify-content-end'}>
                                <small>
                                    <Link to={PAGE_URLS.FIND_ID} className={'sp-text-decoration-none'}>
                                        ID 확인
                                    </Link>
                                </small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
