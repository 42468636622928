import React from 'react';
import ApiUser from '../data/ApiUser';
import {PAGE_URLS, validationEmail, validationId, validationPassword, validationTel} from './PageUtil';
import {TypeUserLevel, User} from '../types';
import InputId from '../comp/InputId';
import InputE164 from '../comp/InputE164';

class State {
  user = new User();
  token = '';
  password = '';
  passwordConfirm = '';
}
export default class PageRegisterAccount extends React.Component<any, any> {
  state = new State();
  componentDidMount() {
    const body = document.getElementById('root');
    if (body !== null) {
      body.className = 'bg-light';
    }

    //http://localhost:3000/invite-register?mail=invite.test3@rimocast.com&token=NmZmODdjOWMtYzdhOC00MmU1LWI5ZTUtZGQ1ZTNlYWI2NTBj&level=operator
    const {search} = window.location;
    const searchParams = new URLSearchParams(search);
    const mail = searchParams.get('mail');
    const token = searchParams.get('token');
    const level = searchParams.get('level');
    if (mail === null || token === null || level === null) {
      window.location.replace(PAGE_URLS.NOT_FOUND);
    } else {
      const {user} = this.state;
      user.mail = mail;
      user.level = level as TypeUserLevel;
      this.setState({user: user, token: token}, () => {
        this.searchInvite(mail, level, token).catch(e => console.error(e));
      });
    }
  }
  async searchInvite(mail: string, level: string, token: string) {
    const result = await ApiUser.getInviteRegister(mail, level, token);
    if (result.code !== 0) {
      window.location.replace(PAGE_URLS.NOT_FOUND);
    }
  }
  async registerUser() {
    const {user, password, token} = this.state;
    const result = await ApiUser.createInviteRegister(user, password, token);
    if (result.code === 0) {
      window.alert('계정이 생성되었습니다.');
      window.location.replace(PAGE_URLS.SIGN_IN);
    } else {
      window.alert(
        `계정 생성 중 에러가 발생했습니다. ERROR CODE: ${result.code}`,
      );
    }
  }
  onChangeID(id: string) {
    const {user} = this.state;
    user.uid = id;
    this.setState({user: user});
  }

  onChangePassword(event: any) {
    this.setState({password: event.target.value});
  }

  onChangePasswordConfirm(event: any) {
    this.setState({passwordConfirm: event.target.value});
  }

  onChangeName(event: any) {
    const {user} = this.state;
    user.name = event.target.value;
    this.setState({user: user});
  }

  onChangeTel(tel: string) {
    const {user} = this.state;
    user.tel = tel;
    this.setState({user: user});
  }
  onClickRegister() {
    if (!this.validData()) return;
    this.registerUser().catch(e => console.error(e));
  }

  validData() {
    const {user, password, passwordConfirm} = this.state;
    // 입력내용 누락 확인
    if (user.uid === '') {
      alert('ID를 입력해주세요.');
      return false;
    } else if (password === '') {
      alert('비밀번호를 입력해주세요.');
      return false;
    } else if (passwordConfirm === '') {
      alert('비밀번호를 입력해주세요.');
      return false;
    } else if (user.name === '') {
      alert('이름을 입력해주세요.');
      return false;
    } else if (user.tel === '') {
      alert('전화번호를 입력해주세요.');
      return false;
    }
    // ID 형식 확인
    if (!validationId(user.uid)) {
      alert('ID를 확인해주세요. (영문대소문자, 숫자, _, - 만 허용됩니다)');
      return false;
    }
    // 이메일 형식 확인
    if (!validationEmail(user.mail)) {
      alert('이메일 주소를 확인해주세요.');
      return false;
    }
    // 비밀번호 일치 확인
    if (password !== passwordConfirm) {
      alert('비밀번호를 확인해주세요.');
      return false;
    }
    // 비밀번호 복잡도 확인
    if (!validationPassword(password)) {
      alert('비밀번호는 최소 8자 이상의 숫자,영어를 조합하여 입력해주세요.');
      return false;
    }
    return true;
  }

  disableConfirm() {
    const {user, password, passwordConfirm} = this.state;
    if (user.uid === '') {
      return true;
    } else if (user.name === '') {
      return true;
    } else if (user.tel === '') {
      return true;
    } else if (password === '' || password !== passwordConfirm) {
      return true;
    } else if (!validationId(user.uid)) {
      return true;
    } else if (!validationEmail(user.mail)) {
      return true;
    } else if (!validationTel(user.tel)) {
      return true;
    } else if (!validationPassword(password)) {
      return true;
    }
    return false;
  }

  render() {
    const {user, password, passwordConfirm, token} = this.state;
    const userMail = user.mail.split('@');
    let levelName = '';
    switch (user.level) {
      case TypeUserLevel.admin:
        levelName = '관리자';
        break;
      case TypeUserLevel.operator:
        levelName = '운용자';
        break;
      case TypeUserLevel.business:
        levelName = '사업담당자';
        break;
      case TypeUserLevel.purchase:
        levelName = '구매담당자';
        break;
      case TypeUserLevel.sales:
        levelName = '영업사원';
        break;
    }
    return (
      <div
        className={'container-sm pt-5 h-100 overflow-auto '}
        style={{maxWidth: 680}}>
        <div className={'row'}>
          <div className={'col text-center'}>
            <h3>NEOSALES 계정을 생성합니다.</h3>
            <div className={'p-3'}>&nbsp;</div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-sm-9'}>
            <div className="mb-3">
              <label htmlFor="basic-url" className="form-label">
                이메일
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="basic-url"
                  style={{maxWidth: 300}}
                  disabled
                  placeholder={'email'}
                  aria-describedby="basic-addon3 basic-addon4"
                  value={userMail[0]}
                />
                <span className="input-group-text" id="basic-addon3">
                  @
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="basic-url"
                  placeholder={'user-email.com'}
                  disabled
                  aria-describedby="basic-addon3 basic-addon4"
                  value={userMail[1]}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="basic-url" className="form-label">
                레벨
              </label>
              <div className="input-group">
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  value={levelName}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="basic-url" className="form-label">
                사용자 ID
              </label>
              <InputId onChange={this.onChangeID.bind(this)} />
            </div>
            <div className="mb-3">
              <label htmlFor="basic-url" className="form-label">
                비밀번호
              </label>
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  onChange={this.onChangePassword.bind(this)}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  onChange={this.onChangePasswordConfirm.bind(this)}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="basic-url" className="form-label">
                이름
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  onChange={this.onChangeName.bind(this)}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="basic-url" className="form-label">
                전화번호
              </label>
              <InputE164
                onChange={this.onChangeTel.bind(this)}
                value={user.tel}
              />
            </div>
          </div>
          <div className={'col-sm-3 pt-4'}>
            <span>
              <strong>이메일</strong> 사업진행 알림등이 이메일로 전송됩니다.
            </span>
            <br />
            <span>
              <strong>사용자 ID</strong> 로그인 할때 사용되며 는 추후
              변경할 수 없습니다. <br/>영문대소문자, 숫자, 언더바(_), 하이픈(-) 만 허용되며, 길이는 최소 5자리, 최대 24자리까지 허용됩니다.
            </span>
            <br />
            <span>
              <strong>패스워드</strong> 비밀번호는 영문소문자, 숫자를 사용해
              주세요. <br/>영문대소문자와 숫자만 허용되며, 최소 8자리 이상이어야 합니다.
            </span>
            <br />
            <span>
              <strong>이름</strong> 수수료 환급, 본인확인 등에 사용되니, 실명을
              사용해 주세요.
            </span>
            <br />
            <span>
              <strong>전화번호</strong> 휴대전화번호를 입력 해 주세요.
            </span>
            <br />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col d-grid pt-3 mb-5'}>
            <button
              className={'btn btn-primary'}
              disabled={this.disableConfirm()}
              onClick={this.onClickRegister.bind(this)}>
              계정 생성
            </button>
          </div>
        </div>
      </div>
    );
  }
}
