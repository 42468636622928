import Business from '../../../type_doc';
import React from 'react';
import ModalContainer, {TypeModal} from '../ModalContainer';

interface Props {
  business?: Business;
  onChanged: () => void;
}
class State {
  value?: string;
}

export default class ModalBusinessAbstractEdit extends React.Component<
  Props,
  State
> {
  state = {
    value: this.props.business?.bizAbstract,
  };
  componentDidMount() {
    this.updateState();
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.business);
    const pp = JSON.stringify(prevProps.business);
    if (np !== pp) {
      this.updateState();
    }
  }

  updateState() {
    this.setState({value: this.props.business?.bizAbstract});
  }

  onChangeText(event: any) {
    this.setState({value: event.target.value});
  }

  async onClickStore() {
    const {business} = this.props;
    const {value} = this.state;
    if (business === undefined || value === undefined) {
      throw Error('SYSTEM ERROR - business not set');
    }
    try {
      const result = await business.updateBizAbstract(value);
      console.log('result: ' + JSON.stringify(result));
      this.props.onChanged();
    } catch (e) {
      console.error(e);
      return false;
    }

    return true;
  }

  onClickCancel() {
    this.setState({value: this.props.business?.bizAbstract});
  }

  render() {
    return (
      <ModalContainer
        divId={'bizAbstract'}
        title={'사업 개요'}
        onStore={this.onClickStore.bind(this)}
        onCancel={this.onClickCancel.bind(this)}
        type={TypeModal.EDIT}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-3 col-form-label'}>사업개요</div>
              <div className={'col-sm-9'}>
                <textarea
                  className={'form-control'}
                  onChange={this.onChangeText.bind(this)}
                  rows={5}
                  value={this.state.value}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
