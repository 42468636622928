import React from 'react';
import {Product} from '../type_doc';
import {UrlParams} from '../types';
import {generateProductCode} from './PageUtil';
import TopBar from '../comp/TopBar';
import {FaFile} from 'react-icons/fa6';
import moment from 'moment';
import {AxiosResponse} from 'axios';
import {ApiResp} from '../data/Api';
import * as RestApi from '../data/Api';

class State {
  products: Product[] = [];
}
export default class PageProductBookmark extends React.Component<any, any> {
  state = new State();

  componentDidMount() {
    this.loadData().catch(e => {
      window.alert(`데이터 로드 중 문제가 발생했습니다.`);
      return;
    });
  }
  async loadData() {
    const result: AxiosResponse<ApiResp<Product[]>> = await RestApi.default.get(
      '/api/admin/bookmark/product/list',
    );
    if (result.data.code === 0) {
      this.setState({
        products: result.data.body,
      });
    } else {
      window.alert(`데이터 로드에 실패 했습니다 CODE(${result.data.code})`);
      return;
    }
  }
  render() {
    const {products} = this.state;

    const cards = products.map((value, index) => {
      const param: UrlParams = new UrlParams();
      param.set('product_seq', `${value.seq}`);

      const files = value.infoGuide.concat(value.contentPdf || []);
      const attachFile = files.map((value, index) => {
        return (
          <div key={index}>
            <a key={index} href={value.path} target={'__blank'}>
              <span className={'badge text-bg-secondary me-2'}>
                <div className={'sp-table-filename'}>{value.name + ' '} </div>
                <FaFile size={18} />
              </span>
            </a>
            <br />
          </div>
        );
      });
      const year = moment(value.timeCreate).year().toFixed();
      const productNo = generateProductCode(value.seq, year);

      return (
        <div className={'item'} key={index}>
          <div className="card">
            <img
              src={value.titleImage[0]?.path}
              className="card-img-top card-image"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">{value.title}</h5>
              <p className={'line-two card-text'}>
                <span className={'badge text-bg-primary me-2'}>
                  {productNo}
                </span>
                {value.titleDesc}
              </p>
              {/*<a*/}
              {/*  href={PAGE_URLS.PRODUCT_LIST + param.getUrlParam()}*/}
              {/*  className="btn btn-outline-primary">*/}
              {/*  상세보기*/}
              {/*</a>*/}
              <div>{attachFile}</div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className={'sp-product-bookmark'}>
        <TopBar menu={'product-bookmark'} />
        <div className={'sp-product-bookmark-top'}>
          <div className={'container'}>
            {/*<div className={'row'}>reserved area</div>*/}
          </div>
        </div>
        <div style={{height: 20}} />
        <div className={'container'}>
          <div className={'row mt-3'}>
            {cards.length > 0 ? (
              <div className={'col-12 item-pool'}>{cards}</div>
            ) : (
              <div className={'sp-product-bookmark-empty'}>
                <img
                  src={'/assets/noData.png'}
                  className={'sp-product-bookmark-empty-image'}
                  alt={'no data'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
