import * as React from 'react';
import ApiSign from '../data/ApiSign';
import {checkScopeInRender, PAGE_URLS, saveUserInfo} from './PageUtil';
import {TypeScope, UrlParams} from '../types';
import {Link} from "react-router-dom";

class State {
  id = '';
  pass = '';
}
export default class PageSignIn extends React.Component<any, State> {
  state = new State();

  componentDidMount() {
    const param = window.location.search;
    const urlParam = UrlParams.parse(param);
    const act = urlParam.get('act');
    if (act !== undefined) {
      const actScope = act as string;
      if (actScope === 'sales' || actScope === 'manager') {
        document.cookie = `act-scope=${actScope}`;
      } else {
        document.cookie =
          'act-scope=none;expires=Thu, 01 Jan 1970 00:00:01 GMT';
      }
    } else {
      document.cookie = 'act-scope=none;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
  }

  async requestSignIn() {
    const {id, pass} = this.state;
    const result = await ApiSign.signIn(id, pass);
    if (result.code === 0) {
      saveUserInfo(result.body);
      window.location.href = '/';
    } else if (result.code === 101) {
      window.alert('로그인 정보가 없습니다.');
    } else if (result.code === 406) {
      window.alert('아이디 또는 비밀번호를 확인해주세요.');
    } else {
      window.alert(`로그인에 실패했습니다. ERROR CODE: ${result.code}`);
    }
  }

  onChangeUserId(event: any) {
    this.setState({id: event.target.value});
  }
  onChangeUserPass(event: any) {
    this.setState({pass: event.target.value});
  }

  onClickEnter(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.requestSignIn().catch(e => {
      window.alert(`로그인 중 문제가 발생했습니다.`);
      return;
    });
  }
  render() {
    const {id, pass} = this.state;
    const scope = checkScopeInRender();
    let scopeTitle = '';
    switch (scope) {
      case TypeScope.na:
        break;
      case TypeScope.manager:
        scopeTitle = '관리자';
        break;
      case TypeScope.sales:
        scopeTitle = '영업사원';
        break;
    }
    return (
      <div className={'sign-container bg-light'}>
        <div className={'sign-box'}>
          <div className={'sign-header text-center'}>
            <h3 style={{fontWeight: 'bold'}}>NEO SALES {scopeTitle} 로그인</h3>
          </div>
          <form onSubmit={this.onClickEnter.bind(this)}>
            <div className={'sign-body'}>
              <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    value={id}
                    onChange={this.onChangeUserId.bind(this)}
                />
                <label htmlFor="floatingInput">ID</label>
              </div>
              <div className="form-floating">
                <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    value={pass}
                    onChange={this.onChangeUserPass.bind(this)}
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
            </div>
            <div className={'sign-footer'}>
              <button
                  type={'submit'}
                  className={'btn btn-primary btn-lg mt-2 w-100'}>
                Enter
              </button>
              <div className={'mt-3 d-flex justify-content-end'}>
                <small>
                  <Link to={PAGE_URLS.FIND_ID} className={'sp-text-decoration-none'}>
                    ID 확인
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={PAGE_URLS.FIND_PASSWORD} className={'sp-text-decoration-none'}>
                    비밀번호 재설정
                  </Link>
                </small>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
