import React from "react";
import InputEmail from "../comp/InputEmail";
import {UrlParams} from "../types";
import {PAGE_URLS, validationPassword} from "./PageUtil";
import {AxiosResponse} from "axios";
import Api, {ApiResp} from "../data/Api";

class State {
    token: string = '';
    mail: string = '';
    pass: string = '';
    validPass?: boolean;
    passConf: string = '';
    validPassConf?: boolean;
}

export default class PageResetPassword extends React.Component<any, State> {
    state = new State();

    componentDidMount() {
        const param = window.location.search;
        const urlParam = UrlParams.parse(param);
        const token = urlParam.get('token');
        if (typeof(token) !== 'string') {
            window.location.replace(PAGE_URLS.NOT_FOUND);
            return;
        } else {
            this.validateToken(token)
                .catch(e => console.error('Error : ' + e));
        }
        // this.setState({token: token});
    }

    async validateToken(token: string) {
        const result:AxiosResponse<ApiResp<any>> = await Api.get(`/api/admin/user/reset/password?token=${token}`);
        if (result.data.code === 0) {
            this.setState({token: token, mail: result.data.body});
        } else if (result.data.code === 102) {
            window.location.replace(PAGE_URLS.NOT_FOUND);
        } else {
            window.alert(`일시적으로 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.`);
            window.location.href = PAGE_URLS.SIGN_IN;
        }
    }

    onChangeEmail(value: string) {
        this.setState({mail: value});
    }

    onChangePass(event: any) {
        const pass = event.target.value;
        const valid = pass.length === 0 ? undefined : validationPassword(pass);
        this.setState({pass: event.target.value, validPass: valid});
    }

    onChangePassConf(event: any) {
        const pass = event.target.value;
        const valid = pass.length === 0 ? undefined : validationPassword(pass) && this.state.pass === pass;
        this.setState({passConf: pass, validPassConf: valid});
    }

    onClickEnter(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.requestResetPassword().catch(e => {
            console.log("error : " + e);
            window.alert(`일시적으로 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.`);
        });
    }

    async requestResetPassword() {
        const {token, pass} = this.state;
        const result:AxiosResponse<ApiResp<any>> = await Api.put(`/api/admin/user/reset/password-confirm?token=${token}&password=${pass}`);
        console.log(JSON.stringify(result));
        if (result.data.code === 0) {
            window.alert('비밀번호가 재설정되었습니다.');
            window.location.href = PAGE_URLS.SIGN_IN;
        } else if (result.data.code === 102) {
            window.alert('만료된 요청입니다.\n' +
                '비밀번호 재설정 요청을 다시 해주세요.\n');
            window.location.href = PAGE_URLS.SIGN_IN;
        } else {
            window.alert(`일시적으로 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.`);
        }
    }

    render() {
        const {mail, pass, passConf, validPass, validPassConf} = this.state;

        return (
            <div className={'sign-container bg-light'}>
                <div className={'sign-box'}>
                    <div className={'sign-header text-center'}>
                        <h3 style={{fontWeight: 'bold'}}>NEO SALES 계정 비밀번호 재설정</h3>
                    </div>
                    <form
                        onSubmit={this.onClickEnter.bind(this)}
                    >
                        <div className={'sign-body'}>
                            <div className="mb-3">
                                <label className={'ps-3 pb-1'} htmlFor="floatingInput">이메일</label>
                                <InputEmail
                                    disabled={true}
                                    onChange={this.onChangeEmail.bind(this)}
                                    value={mail}
                                />
                            </div>
                            <div className="mb-3">
                                <label className={'ps-3 pb-1'} htmlFor="floatingInput">비밀번호</label>
                                <input
                                    type="password"
                                    className={"form-control mb-2 " + (validPass === undefined ? '' : validPass ? 'is-valid' : 'is-invalid')}
                                    id="floatingInput"
                                    placeholder='비밀번호'
                                    value={pass}
                                    onChange={this.onChangePass.bind(this)}
                                />
                                <input
                                    type="password"
                                    className={"form-control " + (validPassConf === undefined ? '' : validPassConf ? 'is-valid' : 'is-invalid')}
                                    id="floatingInput"
                                    placeholder='비밀번호 재입력'
                                    value={passConf}
                                    onChange={this.onChangePassConf.bind(this)}
                                />
                            </div>
                        </div>
                        <div className={'sign-footer'}>
                        <button
                                type={'submit'}
                                className={'btn btn-primary mt-2 w-100'}
                                value={passConf}
                                disabled={!validPass || !validPassConf}
                            >
                            비밀번호 재설정
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
