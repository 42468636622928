import {TypeScope, UrlParams, User} from '../types';
import {TypeBusinessStatus} from '../type_doc';

export enum LOCAL_STORAGE_KEY {
  USER_INFO = 'userInfo',
  BIZ_STATUS = 'BizStatus',
}
export enum PAGE_URLS {
  USER_MANAGER = '/user/manager',
  USER_SALES = '/user/sales',
  USER_COMPANY = '/user/company',
  USER_INVITE = '/user/invite',

  PRODUCT = '/product',
  PRODUCT_BOOKMARK = '/product-bookmark',
  PRODUCT_LIST = '/product-list',
  BUSINESS = '/business',

  DASHBOARD = '/dashboard',
  HTML_VIEWER = '/viewer-html',
  REFUND_LIST = '/refund-list',

  SIGN_IN = '/signIn',
  POINT = '/profile/point',
  POINT_HISTORY = '/profile/point-history',
  PROFILE = '/profile/view',
  PROFILE_EDIT = '/profile/edit',

  REGISTER = '/invite-register',

  FIND_ID = '/find/id',
  FIND_PASSWORD = '/find/password',
  RESET_PASSWORD = '/reset/password',

  NOT_FOUND = '/404',
  ACCESS_DENY = '/403',
}

export function saveUserInfo(userInfo: User) {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

export function removeUserInfo() {
  localStorage.removeItem('userInfo');
}

export function loadUserInfo(): User | undefined {
  const savedUserInfo = localStorage.getItem('userInfo');

  if (savedUserInfo === null) {
    return undefined;
  }
  return JSON.parse(savedUserInfo);
}

export function validationEmail(mail: string): boolean {
  const expression = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+.[a-z]+$/;
  const result: boolean = expression.test(mail);
  console.log('e-mail is ' + (result ? 'correct' : 'incorrect'));
  return result;
}

export function validationId(id: string): boolean {
  const regPass = /^[A-Za-z][a-zA-Z0-9_-]{1,24}$/;
  return id.length >= 5 && id.length <= 24 && regPass.test(id);
}

export function validationPassword(pass: string): boolean {
  const regPass = /((?=.{8,})(?=.*[0-9])(?=.*[a-z]))/;
  return regPass.test(pass);
}

export function validationTel(tel: string): boolean {
  const token = tel.split('-');
  if (token.length !== 3) return false;
  if (token[1].length < 3 || token[1].length > 4) return false;
  if (token[2].length !== 4) return false;
  return true;
}

export function generateBusinessNumber(seq: number, year: string) {
  return 'NEOC' + year + '-' + 'B' + String(seq).padStart(5, '0');
}
export function generateProductCode(seq: number, year: string) {
  return 'NEOC' + year + '-' + 'P' + String(seq).padStart(5, '0');
}
export function generateShortProductCode(seq: number) {
  return 'P' + String(seq).padStart(5, '0');
}
export function parseSeq(code: string) {
  const codes = code.split('-');
  return parseInt(codes[codes.length - 1].substring(1, 6));
}

export function checkScopeInRender() {
  const domain = window.location.host;
  if (domain === 'manager.neosales.co.kr') {
    return TypeScope.manager;
  } else if (domain === 'sales.neosales.co.kr') {
    return TypeScope.sales;
  }

  const param = window.location.search;
  const urlParam = UrlParams.parse(param);
  const act = urlParam.get('act');
  if (act !== undefined) {
    const actScope = act as string;
    if (actScope === 'manager') {
      return TypeScope.manager;
    } else if (actScope === 'sales') {
      return TypeScope.sales;
    } else {
      return TypeScope.na;
    }
  }
  return TypeScope.na;
}
