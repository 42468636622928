import React from 'react';
import 'rc-tree/assets/index.css';
import Tree from 'rc-tree';
import {allTreeData} from '../data/productCode';
import {FaInfoCircle} from 'react-icons/fa';
import {Category, MenuNode} from '../types';

interface Props {
  categories: Category[];
  onSelectMenu: (category?: Category) => void;
}
class State {
  node: MenuNode = allTreeData;
}
export default class SideBarProduct extends React.Component<Props, State> {
  state = new State();

  componentDidMount() {
    this.updateCategory();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (
      prevProps.categories.length !== this.props.categories.length ||
      JSON.stringify(prevProps.categories) !==
        JSON.stringify(prevProps.categories)
    ) {
      this.updateCategory();
    }
  }

  updateCategory() {
    const root = new MenuNode('all', '전체');
    for (const category of this.props.categories) {
      const node = new MenuNode(String(category.seq), category.category);
      root.addChild(node);
    }
    this.setState({node: root});
  }

  _onSelect(value: any) {
    console.log('SE: ' + JSON.stringify(value));

    if (value.length > 1) {
      console.log('multiple key selected');
    } else if (value.length === 0) {
      this.props.onSelectMenu();
    } else {
      if (value[0] === 'all') {
        this.props.onSelectMenu();
        return;
      }
      const {categories} = this.props;
      for (const category of categories) {
        if (category.seq == value[0]) {
          this.props.onSelectMenu(category);
          return;
        }
      }
    }
  }
  render() {
    const {node} = this.state;
    return (
      <div className={'sp-left'}>
        <div
          className={'d-flex justify-content-between'}
          style={{paddingLeft: 10, paddingRight: 10}}>
          <div className={'title-wrapper'}>
            <span className={'text'}>제품 목록</span>
          </div>
          <div className={'title-wrapper'}>
            <div className="btn-group" role="group" aria-label="Basic example">
              <a
                className="btn btn-outline-primary btn-sm"
                type="button"
                href={
                  'https://tree-scar-573.notion.site/a5332331b45a4a799403f91fc1a12472?pvs=4'
                }
                target={'__blank'}>
                <FaInfoCircle size={16} className={'sp-manual'} />
              </a>
            </div>
          </div>
        </div>
        <div style={{marginTop: 20, marginLeft: 4}}>
          <Tree
            {...this.props}
            onSelect={this._onSelect.bind(this)}
            selectable={true}
            defaultExpandAll={true}
            treeData={[node]}
          />
        </div>
      </div>
    );
  }
}
