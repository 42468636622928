import React from 'react';

export default class Page404 extends React.Component {
  render() {
    return (
      <div className={'h-100 d-flex justify-content-center align-items-center'}>
        <div
          className={
            'd-flex flex-column justify-content-center align-items-center'
          }>
          <img
            src={'/assets/404.png'}
            width={'50%'}
            alt={'404'}
            style={{minWidth: 300}}
          />
          <div className={'alert alert-info d-flex justify-content-between'}>
            <span className={'h5'}>페이지가 존재하지 않습니다.</span>
            <button
              className={'btn btn-info'}
              onClick={() => {
                window.location.href = '/';
              }}>
              처음으로 가기
            </button>
          </div>
        </div>
      </div>
    );
  }
}
