import React from 'react';
import {Activity, TypeHistoryAction, TypeHistoryTarget, User} from '../../types';
import {ManagerIcon} from '../Manager';
import moment from 'moment';
import UserCache from '../../UserCache';
import {IoLinkOutline} from 'react-icons/io5';

interface Props {
  activity: Activity;
}
export default class DashboardActivityCard extends React.Component<Props, any> {
  state = {
    user: new User(),
  };

  async _loadUser() {
    const user = await UserCache.getInstance().getById(
      this.props.activity.userUid,
    );
    if (user !== undefined) {
      this.setState({user: user});
    }
  }

  componentDidMount() {
    this._loadUser().catch(e => console.error('Fail to load acts'));
  }

  render() {
    const {activity} = this.props;
    const {user} = this.state;

    const timeAction = moment(activity.getDate()).fromNow();
    let linkType = 'text-bg-success';

    let activityTypeStr = ''
    let activityTypeLink = ''
    switch (activity.type) {
      case TypeHistoryTarget.na:
        break;
      case TypeHistoryTarget.USER:
        break;
      case TypeHistoryTarget.PRODUCT:
        linkType = 'text-bg-primary';
        activityTypeStr = '제품';
        activityTypeLink = '/product?&product_selected=' + activity.targetSeq;
        break;
      case TypeHistoryTarget.BUSINESS:
        linkType = 'text-bg-danger';
        activityTypeStr = '사업';
        activityTypeLink = '/business?&business_selected=' + activity.targetSeq;
        break;
    }

    let activityDescStr = ''
    switch (activity.action) {
      case TypeHistoryAction.na:
        break;
      case TypeHistoryAction.CREATE:
        activityDescStr = `${activity.what}을(를) 생성했습니다.`;
        break;
      case TypeHistoryAction.UPDATE: {
        const from = activity.from.length < 10 ? activity.from : (activity.from.substring(0, 10) + '...');
        const to = activity.to.length < 10 ? activity.to : activity.to.substring(0, 10) + '...';
        activityDescStr = `${activity.what}을(를) ${from.length === 0 && to.length === 0 ? '' : `"${from}"에서 "${to}"(으)로`} 변경했습니다.`;
        break;
      }
      case TypeHistoryAction.DELETE:
        activityDescStr = `${activity.what}을(를) 삭제했습니다.`;
        break;
    }

    return (
      <div className={'card-business'}>
        <div className={'d-flex justify-content-between mt-2'}>
          <div className={'me-2'}>
            <ManagerIcon user={user} size={26} />
          </div>
          <div className="position-relative flex-grow-1 mb-2">
            <div className={'d-flex justify-content-between'}>
              <div>
                <span className={'me-2 fw-bold'}>{user?.name}</span>
                <span>@{user?.uid}</span>
              </div>
              <div>{timeAction}</div>
            </div>
            <div>
              <span
                className={'badge ' + linkType}
                onClick={() => (window.location.href = activityTypeLink)}>
                {activityTypeStr} : {activity.targetSeq} <IoLinkOutline />
              </span>
              의{` ${activityDescStr}`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
