import React from 'react';
import {Product} from '../../type_doc';
import {generateProductCode} from '../../pages/PageUtil';

interface Props {
  product: Product;
  onClick?: (seq: number) => void;
  selected?: boolean;
}
export default class ProductCard extends React.Component<Props, any> {
  render() {
    const {product} = this.props;

    const stateName = product.draft ? '임시저장' : '게시중';
    const badgeClass = product.draft ? 'text-bg-secondary' : 'text-bg-primary';
    const promotionBadge = product.inPromotion ? 'text-bg-warning' : '';

    const year = '2024'; //product.time.substring(0,4);
    const pCode = generateProductCode(product.seq, year);
    return (
      <div
        className={
          'card-business ' +
          (this.props.selected ? 'card-business-selected' : '')
        }
        onClick={() => this.props.onClick?.(product.seq)}>
        <div className={'d-flex justify-content-between'}>
          <div className="position-relative">
            <span>{pCode}</span>
          </div>
          <div>
            {
              product.inPromotion && (
                  <div className={'me-2 badge rounded-pill ' + promotionBadge}>
                    프로모션중
                  </div>
                )
            }
            <div className={'badge rounded-pill ' + badgeClass}>
              {stateName}
            </div>
          </div>
        </div>
        <div className={'d-flex justify-content-between'}>
          <div>{product.title}</div>
        </div>
      </div>
    );
  }
}
