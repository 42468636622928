import React from 'react';
import {SearchDict, TypeUserLevel, User} from '../types';
import {IoCloseCircle} from 'react-icons/io5';
import {ManagerIcon} from './Manager';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';

interface Props {
  onChange: (user: (User | string)[]) => void;
  placeholder?: string;
  className?: string;
  value?: User | string | (User | string)[];
  single?: boolean;
  registered?: boolean;
  level?: TypeUserLevel;
}

class State {
  selected: (User | string)[] = [];
  searchResult?: User[] = [];
  inputText: string = '';
  keyFocused: boolean = false;
}

/**
 * Input form 내 하나의 검색결과만 사용한다.
 */
export default class InputSearchUser extends React.Component<Props, State> {
  state = new State();
  inputRef: any;

  initializeUser() {
    if (Array.isArray(this.props.value)) {
      this.setState({selected: this.props.value});
    } else if (this.props.value === undefined) {
      this.setState({selected: []});
    } else {
      this.setState({selected: [this.props.value]});
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const pp = JSON.stringify(prevProps);
    const np = JSON.stringify(this.props);
    if (pp !== np) {
      this.initializeUser();
    }
  }

  async searchUser(key: string) {
    const url = `/api/admin/user/info/-/search?text=${key}`;
    let query = '';
    if (this.props.level) query += `&level=${this.props.level}`;
    const res: AxiosResponse<ApiResp<SearchDict<string, User>[]>> =
      await Api.get(url + query);
    const {data} = res;
    const body = data.body;
    const dataArray = new Array<User>();
    for (const dict of body) {
      dataArray.push(dict.value);
    }
    this.setState({searchResult: dataArray});
  }

  onKeyPress(event: any) {
    const {selected} = this.state;
    if (event.key === 'Tab' && !this.props.registered) {
      selected.push(this.state.inputText);
      this.setState({selected: selected, keyFocused: false, inputText: ''});
      this.props.onChange(this.state.selected);
    }
  }

  onFocusInput() {
    this.setState({keyFocused: true});
  }

  onFocusBlur() {
    setTimeout(() => {
      this.setState({keyFocused: false});
    }, 500);
  }

  removeBadge(index: number) {
    const {selected} = this.state;
    if (selected === undefined) return;
    selected.splice(index, 1);
    this.setState({selected: selected});
    this.props.onChange(this.state.selected);
  }

  componentDidMount() {
    this.initializeUser();
  }

  onChange(event: any) {
    const text = event.target.value;
    this.setState({inputText: text});
    this.searchUser(text).catch(e =>
      console.error('ERR : ' + JSON.stringify(e)),
    );
  }

  render() {
    const {selected, searchResult, inputText, keyFocused} = this.state;

    const badge = selected?.map((value, index) => {
      let title = <></>;
      if (typeof value === 'string') {
        title = <span>{value}</span>;
      } else {
        title = (
          <span>
            <ManagerIcon user={value} size={15} style={{marginRight: 3}} />
            {value.name}
          </span>
        );
      }
      const type =
        typeof value === 'string' ? 'text-bg-danger' : 'text-bg-success';
      return (
        <span key={index} className={`badge ${type}`}>
          <span
            className={'sp-form-badge'}
            onClick={() => {
              this.removeBadge(index);
            }}>
            <IoCloseCircle size={15} style={{marginRight: 6}} />
          </span>
          <span>{title}</span>
        </span>
      );
    });

    const results = searchResult?.map((data, index) => {
      if (index < 5) {
        return (
          <li
            key={index}
            onClick={() => {
              const {selected} = this.state;
              selected.push(data);
              this.setState({
                searchResult: undefined,
                selected: selected,
                inputText: '',
              });
              this.props.onChange(this.state.selected);
            }}>
            <span className="dropdown-item">
              {`${data.name} [${data.mail}] `}
              {`(${data.level})`}
            </span>
          </li>
        );
      }
    });

    return (
      <>
        <div className={'form-control d-flex'} style={{overflowX: 'auto'}}>
          <div className={'d-flex gap-2'}>{badge}</div>
          {(badge.length === 0 || !this.props.single) && (
            <input
              ref={ref => (this.inputRef = ref)}
              type="text"
              className={'sp-form-hidden'}
              placeholder={this.props.placeholder}
              onChange={this.onChange.bind(this)}
              onFocus={this.onFocusInput.bind(this)}
              onBlur={this.onFocusBlur.bind(this)}
              onKeyDown={this.onKeyPress.bind(this)}
              value={inputText}
            />
          )}
        </div>
        {searchResult && keyFocused && results && results?.length > 0 && (
          <>
            <ul className={'dropdown-menu show'} style={{zIndex: 3000}}>
              {results}
            </ul>
          </>
        )}
      </>
    );
  }
}
