import React from 'react';
import {SearchDict} from '../types';
import {IoCloseCircle} from 'react-icons/io5';
import Api, {ApiResp} from '../data/Api';
import {AxiosResponse} from 'axios';
import {Product, ProductTuple} from '../type_doc';
import ProductCache from '../ProductCache';

interface Props {
  onChange: (product: (ProductTuple | string)[]) => void;
  placeholder?: string;
  className?: string;
  value?: ProductTuple | string | (ProductTuple | string)[];
  single?: boolean;
  registered?: boolean;
}

class State {
  selected: (Product | string)[] = [];
  searchResult?: Product[] = [];
  inputText: string = '';
  keyFocused: boolean = false;
}

/**
 * Input form 내 하나의 검색결과만 사용한다.
 */
export default class InputSearchProduct extends React.Component<Props, State> {
  state = new State();
  inputRef: any;

  async _fillProducts(pt: (ProductTuple | string)[]) {
    const products = [];
    for (const p of pt) {
      if (typeof p === 'string') {
        products.push(p);
      } else {
        const product = await ProductCache.getInstance().getBySeq(p.ref);
        if (product === undefined) continue;
        products.push(product);
      }
    }
    this.setState({selected: products});
  }

  initializeProduct() {
    if (Array.isArray(this.props.value)) {
      this._fillProducts(this.props.value).catch(e =>
        console.error('Fail to initialze : ' + e),
      );
    } else if (this.props.value === undefined) {
      this.setState({selected: []});
    } else {
      this._fillProducts([this.props.value]).catch(e =>
        console.error('Fail to initialze : ' + e),
      );
    }
  }

  async searchProduct(key: string) {
    const url = `/api/admin/product/-/search?text=${key}`;
    const res: AxiosResponse<ApiResp<SearchDict<number, Product>[]>> =
      await Api.get(url);
    const {data} = res;
    const body = data.body;
    const dataArray = new Array<Product>();
    for (const dict of body) {
      dataArray.push(dict.value);
    }
    this.setState({searchResult: dataArray});
  }

  onKeyPress(event: any) {
    const {selected} = this.state;
    if (event.key === 'Tab') {
      if (this.props.registered) {
        this.setState({keyFocused: false, inputText: ''});
      } else {
        selected.push(this.state.inputText);
        this.setState({selected: selected, keyFocused: false, inputText: ''});
        const tups = [];
        for (const s of selected) {
          const tup = Object.assign(new Product(), s);
          tups.push(tup.getTuple());
        }
        this.props.onChange(tups);
      }
    }
  }

  onFocusInput() {
    this.setState({keyFocused: true});
  }
  onFocusBlur() {
    setTimeout(() => {
      this.setState({keyFocused: false});
    }, 500);
  }

  removeBadge(index: number) {
    const {selected} = this.state;
    if (selected === undefined) return;
    selected.splice(index, 1);
    this.setState({selected: selected});
    const tups = [];
    for (const s of selected) {
      const tup = Object.assign(new Product(), s);
      tups.push(tup.getTuple());
    }
    this.props.onChange(tups);
  }

  componentDidMount() {
    this.initializeProduct();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const pp = JSON.stringify(prevProps.value);
    const np = JSON.stringify(this.props.value);
    if (pp !== np) {
      this.initializeProduct();
    }
  }

  onChange(event: any) {
    const text = event.target.value;
    this.setState({inputText: text});
    this.searchProduct(text).catch(e =>
      console.error('ERR : ' + JSON.stringify(e)),
    );
  }

  render() {
    const {selected, searchResult, inputText, keyFocused} = this.state;

    const badge = selected?.map((value, index) => {
      let title = <></>;
      if (typeof value === 'string') {
        title = <span>{value}</span>;
      } else {
        title = <span>{value.title}</span>;
      }
      const type =
        typeof value === 'string' ? 'text-bg-danger' : 'text-bg-success';
      return (
        <span key={index} className={`badge ${type}`}>
          <span
            className={'sp-form-badge'}
            onClick={() => {
              this.removeBadge(index);
            }}>
            <IoCloseCircle size={15} style={{marginRight: 6}} />
          </span>
          <span>{title}</span>
        </span>
      );
    });

    const results = searchResult?.map((data, index) => {
      if (index < 5) {
        const disabled = data.priceUnit <= 0;
        const cDisabled = disabled
          ? ' text-muted text-decoration-line-through'
          : ' fw-bold';

        return (
          <li
            key={index}
            onClick={() => {
              if (disabled) return;
              const {selected} = this.state;
              selected.push(data);
              this.setState({
                searchResult: undefined,
                selected: selected,
                inputText: '',
              });
              const tups = [];
              for (const s of selected) {
                const tup = Object.assign(new Product(), s);
                tups.push(tup.getTuple());
              }
              this.props.onChange(tups);
            }}>
            <span className={'dropdown-item' + cDisabled}>
              {`${data.title}`}
            </span>
          </li>
        );
      }
    });

    return (
      <>
        <div
          className={'form-control form-control-sm'}
          style={{overflowX: 'auto'}}>
          <div className={'d-flex'}>
            <div className={'d-flex gap-2'}>{badge}</div>
            {(badge.length === 0 || !this.props.single) && (
              <input
                ref={ref => (this.inputRef = ref)}
                type="text"
                className={'sp-form-hidden'}
                placeholder={this.props.placeholder}
                onChange={this.onChange.bind(this)}
                onFocus={this.onFocusInput.bind(this)}
                onBlur={this.onFocusBlur.bind(this)}
                onKeyDown={this.onKeyPress.bind(this)}
                value={inputText}
              />
            )}
          </div>
        </div>
        {searchResult && keyFocused && results && results.length > 0 && (
          <>
            <ul
              className={'dropdown-menu show'}
              style={{zIndex: 3000, top: 35}}>
              {results}
            </ul>
          </>
        )}
      </>
    );
  }
}
