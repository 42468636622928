import React from 'react';

interface Props {
  onChange: (value: string) => void;
  value: string;
  onSave?: () => void;
  onBlur?: () => void;
}
export default class InputE164 extends React.Component<Props, any> {
  state = {
    workCode: '010',
    workDigitFront: '',
    workDigitBack: '',
  };

  updateState() {
    const {value} = this.props;
    const token = value.split('-');
    if (Array.isArray(token) && token.length === 3) {
      this.setState({
        workCode: token[0],
        workDigitFront: token[1],
        workDigitBack: token[2],
      });
    } else {
      this.setState({workCode: '010', workDigitFront: '', workDigitBack: ''});
    }
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.value);
    const pp = JSON.stringify(prevProps.value);
    if (np !== pp) {
      this.updateState();
    }
  }

  onClickCode(code: string) {
    const {workDigitFront, workDigitBack} = this.state;
    this.setState({workCode: code});
    this.props.onChange(`${code}-${workDigitFront}-${workDigitBack}`);
  }

  onChangeDigitFront(event: any) {
    const {workCode, workDigitBack} = this.state;
    this.setState({workDigitFront: event.target.value});
    this.props.onChange(`${workCode}-${event.target.value}-${workDigitBack}`);
  }

  onChangeDigitBack(event: any) {
    const {workCode, workDigitFront} = this.state;
    this.setState({workDigitBack: event.target.value});
    this.props.onChange(`${workCode}-${workDigitFront}-${event.target.value}`);
  }

  render() {
    const {workCode, workDigitFront, workDigitBack} = this.state;
    const codes = [
      '010',
      '02',
      '031',
      '032',
      '033',
      '041',
      '042',
      '043',
      '051',
      '052',
      '053',
      '054',
      '055',
      '061',
      '062',
      '063',
      '064',
    ];
    const codeList = codes.map((value, index) => {
      return (
        <li key={index}>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => this.onClickCode(value)}>
            {value}
          </a>
        </li>
      );
    });
    return (
      <div className="input-group">
        <button
          className="btn btn-outline-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          {workCode}
        </button>
        <ul className="dropdown-menu">{codeList}</ul>
        <span className="input-group-text">-</span>
        <input
          type="number"
          className="form-control"
          style={{maxWidth: 300}}
          placeholder={'0000'}
          value={workDigitFront}
          onChange={this.onChangeDigitFront.bind(this)}
        />
        <span className="input-group-text">-</span>
        <input
          type="number"
          className="form-control"
          placeholder={'0000'}
          value={workDigitBack}
          onChange={this.onChangeDigitBack.bind(this)}
          onBlur={this.props.onBlur ? this.props.onBlur.bind(this) : undefined}
        />
        {this.props.onSave && (
          <button
            className={'btn btn-outline-primary'}
            onClick={() => {
              if (this.props.onSave) this.props.onSave();
            }}>
            저장
          </button>
        )}
      </div>
    );
  }
}
