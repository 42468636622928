import React from 'react';
import './css/bootstrap.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {loadUserInfo, PAGE_URLS} from './pages/PageUtil';
import PageUserManager from './pages/PageUserManager';
import Page404 from './pages/Page404';

import './css/product.css';
import './css/sparta.css';
import './css/datatable-custom.css';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import './css/business.css';
import './css/sign.css';
import './css/profile.css';
import './css/loading.css';
import 'react-toastify/dist/ReactToastify.css';

import PageBusiness from './pages/PageBusiness';
import PageProduct from './pages/PageProduct';
import PageUserSales from './pages/PageUserSales';
import PageUserCompany from './pages/PageUserCompany';
import PageSignIn from './pages/PageSignIn';
import PagePoints from './pages/PagePoints';
import PageRegisterAccount from './pages/PageRegisterAccount';
import PageDashboard from './pages/PageDashboard';
import PageEditProfile from './pages/PageEditProfile';
import PageUserInvite from './pages/PageUserInvite';
import PageRefundRequests from './pages/PageRefundRequests';
import PageRefundHistory from './pages/PageRefundHistory';
import PageHtmlViewer from './pages/PageHtmlViewer';
import PageProductListView from './pages/PageProductListView';
import PageProductBookmark from './pages/PageProductBookmark';
import {TypeUserLevel} from './types';
import Page403 from './pages/Page403';
import PageFindId from "./pages/PageFindId";
import PageFindPassword from "./pages/PageFindPassword";
import PageResetPassword from "./pages/PageResetPassword";

export default class App extends React.Component<any, any> {
  render() {
    const user = loadUserInfo();
    const root = () => {
      if (user === undefined) {
        return <Route path={'/'} element={<PageSignIn />} />;
      }
      switch (user.level) {
        case TypeUserLevel.admin:
        case TypeUserLevel.operator:
          return (
            <Route
              path={'/'}
              element={<Navigate to={PAGE_URLS.USER_MANAGER} replace={true} />}
            />
          );
        case TypeUserLevel.business:
        case TypeUserLevel.sales:
          return (
            <Route
              path={'/'}
              element={<Navigate to={PAGE_URLS.DASHBOARD} replace={true} />}
            />
          );
        case TypeUserLevel.purchase:
        case TypeUserLevel.na:
          return <Route path={'/'} element={<PageSignIn />} />;
      }
    };
    return (
      <BrowserRouter>
        <Routes>
          <Route path={PAGE_URLS.SIGN_IN} element={<PageSignIn />} />
          <Route path={PAGE_URLS.FIND_ID} element={<PageFindId />} />
          <Route path={PAGE_URLS.FIND_PASSWORD} element={<PageFindPassword />} />
          <Route path={PAGE_URLS.RESET_PASSWORD} element={<PageResetPassword />} />
          <Route path={PAGE_URLS.USER_MANAGER} element={<PageUserManager />} />
          <Route path={PAGE_URLS.USER_SALES} element={<PageUserSales />} />
          <Route path={PAGE_URLS.USER_COMPANY} element={<PageUserCompany />} />
          <Route path={PAGE_URLS.USER_INVITE} element={<PageUserInvite />} />
          <Route path={PAGE_URLS.DASHBOARD} element={<PageDashboard />} />
          <Route path={PAGE_URLS.HTML_VIEWER} element={<PageHtmlViewer />} />
          <Route path={PAGE_URLS.BUSINESS} element={<PageBusiness />} />
          <Route path={PAGE_URLS.PRODUCT} element={<PageProduct />} />
          <Route
            path={PAGE_URLS.PRODUCT_LIST}
            element={<PageProductListView />}
          />
          <Route
            path={PAGE_URLS.PRODUCT_BOOKMARK}
            element={<PageProductBookmark />}
          />
          <Route path={PAGE_URLS.POINT} element={<PagePoints />} />
          <Route
            path={PAGE_URLS.POINT_HISTORY}
            element={<PageRefundHistory />}
          />
          <Route
            path={PAGE_URLS.REFUND_LIST}
            element={<PageRefundRequests />}
          />
          <Route path={PAGE_URLS.PROFILE_EDIT} element={<PageEditProfile />} />
          <Route path={PAGE_URLS.REGISTER} element={<PageRegisterAccount />} />
          <Route path={PAGE_URLS.ACCESS_DENY} element={<Page403 />} />
          {root()}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
