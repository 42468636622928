import React from 'react';
import {
    ProductCategorySelect,
    ProductFile,
    ProductInput,
    ProductKeywords, ProductPromotionForm,
    ProductText,
} from '../ProductForm';
import {Product} from '../../type_doc';
import {Category, Company} from '../../types';
import {FaTimesCircle} from 'react-icons/fa';
import Api, {ApiResp} from '../../data/Api';
import {AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
import {generateShortProductCode, PAGE_URLS} from '../../pages/PageUtil';
import ModalContainer, {TypeModal} from '../../pages/modal/ModalContainer';

interface Props {
  product: Product;
  categories: Category[];
  onUpdate: (seq: number) => void;
}

class State {
  product: Product = new Product();
  workProduct: Product = new Product();
  category?: Category;
}

export default class ProductDetail extends React.Component<Props, State> {
  state = new State();

  initState() {
    this.setState({
      product: Object.assign(new Product(), this.props.product),
      workProduct: Object.assign(new Product(), this.props.product),
    });
  }

  componentDidMount() {
    this.loadProductCategory().catch(() =>
      this.setState({category: undefined}),
    );
    this.initState();
  }

  async loadProductCategory() {
    const {product} = this.props;
    const url = `/api/admin/product/${product.seq}/category`;
    const result = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({category: result.data.body});
    } else {
      window.alert(`데이터 로드에 실패했습니다. CODE(${result.data.code})`);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.product.seq !== this.props.product.seq) {
      this.initState();
    }
  }

  updated(seq: number) {
    this.props.onUpdate(seq);
  }

  // onChangeTab(data: string) {
  //   switch (data) {
  //     case 'PDF':
  //       this.setState({contentType: 'pdf'});
  //       break;
  //     case 'TEXT':
  //       this.setState({contentType: 'markdown'});
  //       break;
  //     case 'IMAGES':
  //       this.setState({contentType: 'image'});
  //       break;
  //   }
  // }

  onPublish() {
    const {product} = this.state;
    let token = '';
    if (product.draft) {
      token = 'publish';
    } else {
      token = 'draft';
    }
    const url = `/api/admin/product/${product.seq}/${token}`;
    Api.put(url, {})
      .then((r: AxiosResponse<ApiResp<boolean>>) => {
        if (r.data.code === 0) {
          console.log('updated');
          this.updated(product.seq);
          toast('제품의 게시여부가 변경되었습니다.');
        } else {
          console.error('!!!!!');
          window.alert(
            `제품 게시여부 변경 중 에러가 발생했습니다. ERROR CODE: ${r.data.code}`,
          );
        }
      })
      .catch(e => {
        console.error('catch!!!!!! ' + JSON.stringify(e));
      });
  }
  async onClickDeleteProduct() {
    const {product} = this.state;
    const url = `/api/admin/product/${product.seq}`;
    const result = await Api.delete(url);
    if (result.data.code === 0) {
      window.alert(
        `${generateShortProductCode(product.seq)} 제품이 삭제되었습니다.`,
      );
      window.location.href = PAGE_URLS.PRODUCT;
    } else {
      window.alert(`제품 삭제에 실패 했습니다 CODE: ${result.data.code}`);
    }
    return result.data.code === 0;
  }

  onCompanyChanged(comp?: Company) {
    if (comp !== undefined) {
      const {workProduct} = this.state;
      if (workProduct !== undefined) {
        workProduct.infoHomepage = comp.homepage;
        workProduct.infoSupplyLocation = [];
        if (comp.location1 !== undefined) {
          workProduct.infoSupplyLocation.push(comp.location1);
        }
        if (comp.location2 !== undefined) {
          workProduct.infoSupplyLocation.push(comp.location2);
        }
        workProduct.infoSupplyContact = [];
        if (comp.tel !== undefined) {
          workProduct.infoSupplyContact.push(comp.tel);
        }
        if (comp.email !== undefined) {
          workProduct.infoSupplyContact.push(comp.email);
        }
        this.setState({workProduct: workProduct});
      }
    }
  }

  onSelectCategory(category: Category) {
    this.setState({category: category});
  }

  render() {
    const {categories} = this.props;
    const {product, workProduct, category} = this.state;
    const selected = workProduct === undefined ? new Product() : workProduct;
    const origin = product === undefined ? new Product() : product;

    // const content = () => {
    //   const {contentType} = this.state;
    //   switch (contentType) {
    //     case 'pdf':
    //       return (
    //         <ProductFile
    //           seq={selected.seq}
    //           keyName={'content'}
    //           value={selected.infoGuide}
    //           onUpdated={this.updated.bind(this)}
    //         />
    //       );
    //     case 'markdown':
    //       return (
    //         <ProductInput
    //           seq={selected.seq}
    //           keyName={'content'}
    //           value={selected.content}
    //           onUpdated={this.updated.bind(this)}
    //         />
    //       );
    //     case 'image':
    //       return (
    //         <ProductFile
    //           seq={selected.seq}
    //           keyName={'content'}
    //           value={selected.infoGuide}
    //           onUpdated={this.updated.bind(this)}
    //         />
    //       );
    //   }
    // };

    return (
      <div className={'p-3'}>
        <div
          className={
            'alert d-flex justify-content-between ' +
            (selected.draft ? 'alert-primary' : 'alert-danger')
          }>
          {selected.draft
            ? '제품을 게시하려면 오른쪽 스위치를 클릭하세요'
            : '제품을 수정하기 전에 해제 버튼을 누르세요'}
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={selected.draft}
              onClick={this.onPublish.bind(this)}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault">
              제품 숨김
            </label>
          </div>
        </div>
        <h4>프로모션 정보</h4>
        <hr />
        <ProductPromotionForm
            selected={selected}
            disabled={!product.draft}
            onUpdated={this.updated.bind(this)}
            maxLength={100}
        />
        <h4>기본 정보</h4>
        <hr/>
        <ProductInput
          seq={selected.seq}
          disabled={!product.draft}
          title={'제품명'}
          keyName={'title'}
          value={selected.title}
          maxLength={20}
          onUpdated={this.updated.bind(this)}
        />
        <ProductFile
          seq={selected.seq}
          disabled={!product.draft}
          title={'대표이미지'}
          maxSize={3 * 1024 * 1024}
          keyName={'titleImage'}
          value={selected.titleImage}
          onUpdated={this.updated.bind(this)}
        />
        <ProductCategorySelect
          title={'제품 카테고리'}
          disabled={!product.draft}
          product={selected}
          categories={categories}
          value={category}
          onSelect={this.onSelectCategory.bind(this)}
          onUpdated={this.updated.bind(this)}
        />
        <ProductText
          seq={selected.seq}
          disabled={!product.draft}
          title={'제품설명'}
          rows={5}
          maxLength={300}
          keyName={'titleDesc'}
          value={selected.titleDesc}
          onUpdated={this.updated.bind(this)}
        />
        <ProductInput
          seq={selected.seq}
          disabled={!product.draft}
          title={'판매가(소비자가)'}
          keyName={'priceUnit'}
          value={selected.priceUnit.toFixed()}
          onUpdated={this.updated.bind(this)}
          type={'number'}
        />
        <ProductInput
          seq={selected.seq}
          disabled={!product.draft}
          title={'매입가'}
          keyName={'pricePurchase'}
          value={selected.pricePurchase.toFixed()}
          onUpdated={this.updated.bind(this)}
          type={'number'}
        />

        <h4>제품 설명</h4>
        <hr />
        <ProductKeywords
          max={3}
          seq={selected.seq}
          disabled={!product.draft}
          title={'키워드'}
          keyName={'infoKeyword'}
          value={selected.infoKeyword}
          onUpdated={this.updated.bind(this)}
        />
        {/*<ProductInputSearchCompany*/}
        {/*  seq={selected.seq}*/}
        {/*  disabled={!product.draft}*/}
        {/*  title={'제조사(회사)'}*/}
        {/*  keyName={'infoSupply'}*/}
        {/*  value={selected.infoSupply}*/}
        {/*  onUpdated={this.updated.bind(this)}*/}
        {/*  onChangeCompany={this.onCompanyChanged.bind(this)}*/}
        {/*/>*/}
        <ProductInput
          seq={selected.seq}
          disabled={!product.draft}
          title={'구축방법'}
          maxLength={20}
          keyName={'infoInstall'}
          value={selected.infoInstall}
          onUpdated={this.updated.bind(this)}
        />
        {/*<ProductKeywords*/}
        {/*  max={3}*/}
        {/*  seq={selected.seq}*/}
        {/*  disabled={!product.draft}*/}
        {/*  title={'소재지'}*/}
        {/*  keyName={'infoSupplyLocation'}*/}
        {/*  origin={origin.infoSupplyLocation}*/}
        {/*  value={selected.infoSupplyLocation}*/}
        {/*  onUpdated={this.updated.bind(this)}*/}
        {/*/>*/}
        {/*<ProductInput*/}
        {/*  seq={selected.seq}*/}
        {/*  disabled={!product.draft}*/}
        {/*  title={'홈페이지'}*/}
        {/*  maxLength={100}*/}
        {/*  keyName={'infoHomepage'}*/}
        {/*  origin={origin.infoHomepage}*/}
        {/*  value={selected.infoHomepage}*/}
        {/*  onUpdated={this.updated.bind(this)}*/}
        {/*/>*/}
        {/*<ProductInput*/}
        {/*  seq={selected.seq}*/}
        {/*  disabled={!product.draft}*/}
        {/*  title={'적용분야'}*/}
        {/*  maxLength={20}*/}
        {/*  keyName={'infoApplication'}*/}
        {/*  value={selected.infoApplication}*/}
        {/*  onUpdated={this.updated.bind(this)}*/}
        {/*/>*/}
        {/*<ProductKeywords*/}
        {/*  max={2}*/}
        {/*  seq={selected.seq}*/}
        {/*  disabled={!product.draft}*/}
        {/*  title={'연락처'}*/}
        {/*  keyName={'infoSupplyContact'}*/}
        {/*  value={selected.infoSupplyContact}*/}
        {/*  origin={origin.infoSupplyContact}*/}
        {/*  onUpdated={this.updated.bind(this)}*/}
        {/*/>*/}
        <ProductFile
          seq={selected.seq}
          disabled={!product.draft}
          title={'첨부파일'}
          maxSize={10 * 1024 * 1024}
          keyName={'infoGuide'}
          value={selected.infoGuide}
          onUpdated={this.updated.bind(this)}
        />
        <div className={'mb-2 row'}>
          <div className={'col-sm-3 col-form-label'}>
            제품 정보
            <span>
              <small
                className={'text-muted'}
                style={{
                  fontSize: '0.75rem',
                }}>{` (최대 10MB 까지 업로드 가능합니다.)`}</small>
            </span>
          </div>
          <div className={'col-sm-9'}>
            <ProductFile
              seq={selected.seq}
              keyName={'contentPdf'}
              value={[]}
              maxSize={10 * 1024 * 1024}
              accept={'.pdf'}
              onUpdated={this.updated.bind(this)}
              disabled={selected.contentPdf !== undefined || !product.draft}
            />
            <div className={'mb-1'}>
              {selected.contentPdf && (
                <span className={'badge text-bg-secondary'}>
                  <span
                    className={'btn btn-sm'}
                    onClick={() => {
                      const url = `/api/admin/product/${selected.seq}/content/${selected.contentPdf?.token}`;
                      Api.delete(url)
                        .then((result: AxiosResponse<ApiResp<boolean>>) => {
                          if (result.data.code === 0) {
                            this.updated(selected.seq);
                            toast(`제품상세정보가 삭제되었습니다.`);
                          }
                        })
                        .catch(e => {
                          console.error('FAIL to upload : ' + e);
                        });
                    }}
                    style={{margin: 0, padding: 0, lineHeight: 0}}>
                    <FaTimesCircle className={'me-3'} color={'white'} />
                  </span>
                  {selected.contentPdf?.name}
                </span>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className={'row mb-5'}>
          <div className={'col'}>
            <div
              data-bs-toggle="modal"
              data-bs-target="#productDelete"
              className={'btn btn-danger'}>
              제품 삭제
            </div>
          </div>
        </div>
        <ModalContainer
          divId={'productDelete'}
          title={'제품 삭제'}
          type={TypeModal.DELETE}
          onStore={this.onClickDeleteProduct.bind(this)}>
          제품을 삭제하시겠습니까?
        </ModalContainer>
      </div>
    );
  }
}
