import React from 'react';
import TopBar from '../comp/TopBar';
import BusinessCard from '../comp/business/BusinessCard';
import BusinessFilter from '../comp/business/BusinessFilter';
import ModalContainer, {TypeModal} from './modal/ModalContainer';
import {UrlParams} from '../types';
import {LOCAL_STORAGE_KEY, PAGE_URLS} from './PageUtil';
import {createBrowserHistory} from 'history';
import Business, {TypeBusiness, TypeBusinessStatus} from '../type_doc';
import ModalBusinessCreate from './modal/ModalBusinessCreate';
import BusinessDetail from '../comp/business/BusinessDetail';
import {FaInfoCircle} from 'react-icons/fa';
import Api from '../data/Api';

export enum TypeView {
  empty,
  bizAbstract,
  listProduct,
  docQuotation,
  docOrder,
  docDelivery,
  docTaxPurchase,
  docTaxSales,
  listMinute,
}

const initialDate = new Date();
initialDate.setDate(1);
initialDate.setMonth(initialDate.getMonth() - 1);
function saveBizStatus(bizStatus: TypeBusinessStatus[]) {
  localStorage.setItem(LOCAL_STORAGE_KEY.BIZ_STATUS, JSON.stringify(bizStatus));
}
function loadBizStatus(): TypeBusinessStatus[] {
  const bizStatus = localStorage.getItem(LOCAL_STORAGE_KEY.BIZ_STATUS);
  if (bizStatus === null) {
    return [
      TypeBusinessStatus.initial,
      TypeBusinessStatus.quoteReq,
      TypeBusinessStatus.quoteRes,
      TypeBusinessStatus.supply,
      TypeBusinessStatus.calculate,
      TypeBusinessStatus.close,
    ];
  }
  return JSON.parse(bizStatus);
}

class State {
  type: TypeBusiness = TypeBusiness.delivery;
  doc: 'products' | 'quotation' | 'tax-purchase' | 'tax-sale' | 'proceeding' =
    'products';
  params: UrlParams = new UrlParams();
  bizStatus: TypeBusinessStatus[] = loadBizStatus();
  business: Business[] = [];
  page = 1;
  size = 20;
  total = 0;
  selected?: Business;
  loading = false;
  startDate = initialDate;
  endDate = new Date();
}
export default class PageBusiness extends React.Component<any, State> {
  state = new State();

  componentDidMount() {
    const param = window.location.search;
    const urlParam = UrlParams.parse(param);
    this.setState({params: urlParam}, () => {
      this.loadBusinessList().catch(e => console.error(e));
      const selectedBizSeq = urlParam.get('business_selected');
      if (selectedBizSeq !== undefined) {
        if (Array.isArray(selectedBizSeq)) {
          window.alert(`오류가 발생했습니다: 사업 중복`);
          return;
        }
        this.refreshSelectedBusiness(parseInt(selectedBizSeq!));
      }
    });
  }
  async loadBusinessList() {
    const {bizStatus, page, size, startDate, endDate} = this.state;
    const result = await Business.getBusinessList(
      bizStatus,
      page,
      size,
      startDate,
      endDate,
    );
    this.setState({
      business: result.body,
      page: result.page || 1,
      total: result.total || 0,
    });
  }

  refreshSelectedBusiness(seq: number) {
    this.setState({loading: true});
    Business.getBusiness(seq)
      .then(business => {
        const selectedBusiness = Object.assign(new Business(), business);
        this.setState({selected: selectedBusiness});
        console.log('selectedBusiness: ' + JSON.stringify(selectedBusiness));
      })
      .catch(e => {
        window.alert(`데이터 로드에 실패 했습니다. CODE(${e.response.status})`);
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }
  refreshData() {
    this.loadBusinessList().catch(e => console.error(e));
    if (this.state.selected) {
      this.refreshSelectedBusiness(this.state.selected.docSeq);
    }
  }

  onSelectProject(id: number) {
    const {params} = this.state;
    params.clear();
    params.set('business_selected', `${id}`);
    this.setState({params: params}, () => {
      const project = params.get('business_selected');
      if (Array.isArray(project)) {
        window.alert(`오류가 발생했습니다: 사업 중복.`);
        return;
      }
      this.refreshSelectedBusiness(parseInt(project!));
    });

    const history = createBrowserHistory();
    history.push({
      pathname: PAGE_URLS.BUSINESS,
      search: params.getUrlParam(),
    });
  }
  onClickPage(event: any) {
    const page = event.target.value;
    this.setState({page: page}, () => {
      this.loadBusinessList().catch(e => console.error(e));
    });
  }
  onClickTitle() {
    console.log('onClickTitle');
  }
  onClickEdit() {
    console.log('onClickEdit');
  }
  onClickUser() {
    console.log('onClickUser');
  }
  onChangedFilter(
    bizStatus: TypeBusinessStatus[],
    size: number,
    from: Date,
    to: Date,
  ) {
    this.setState(
      {bizStatus: bizStatus, size: size, startDate: from, endDate: to},
      () => {
        saveBizStatus(bizStatus);
        this.loadBusinessList().catch(e => console.error(e));
      },
    );
  }

  async onStopBiz() {
    console.log('onStopBiz');
    const selectedProject = this.state.selected;
    if (selectedProject?.docSeq === undefined) return false;

    const url = `/api/admin/biz/${selectedProject?.docSeq}/cancel`;
    const result = await Api.put(url);
    console.log('onStopBiz - result: ' + JSON.stringify(result));
    if (result.data.code === 0) {
      this.refreshData();
    } else {
      window.alert(
        `사업 중단 중 에러가 발생했습니다. ERROR CODE: ${result.data.code}`,
      );
    }
    return result.data.code === 0;
  }

  render() {
    const {loading, bizStatus, size, startDate, endDate} = this.state;
    const project = this.state.params.get('business_selected');
    const selectedProject = this.state.selected;
    if (Array.isArray(project)) {
      throw Error('System error');
    }
    const business = this.state.business.map((value, index) => {
      const price = parseInt('0', 10);
      return (
        <BusinessCard
          key={index}
          id={value.docSeq}
          year={value.timeCreate.substring(0, 4)}
          manager={value.userBiz || ''}
          state={value.bizStep}
          type={value.bizType}
          desc={value.bizAbstract}
          onClick={this.onSelectProject.bind(this)}
          price={price}
          title={value.getTitle()}
          selected={value.docSeq === selectedProject?.docSeq}
          timeUpdate={value.timeUpdate}
        />
      );
    });

    const pageCount = (this.state.total - 1) / this.state.size + 1;
    const pageButtons = [];
    for (let i = 1; i <= pageCount; i++) {
      pageButtons.push(
        <button
          key={i}
          type="button"
          className={`btn ${i === this.state.page ? 'btn-secondary' : 'btn-outline-secondary'}`}
          value={i}
          onClick={this.onClickPage.bind(this)}>
          {i}
        </button>,
      );
    }
    return (
      <>
        <TopBar menu={'business'} />
        <div className={'sp-body-col d-flex'}>
          <div className={'sp-business-list pt-3'}>
            <div
              className={'d-flex justify-content-between'}
              style={{paddingLeft: 10, paddingRight: 10}}>
              <h5>사업 목록</h5>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example">
                <button
                  className={'btn btn-outline-primary btn-sm'}
                  data-bs-toggle="modal"
                  data-bs-target="#newBusiness">
                  생성
                </button>
                <button
                  className="btn btn-outline-primary btn-sm"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#businessFilterCanvas"
                  aria-controls="businessFilterCanvas">
                  설정
                </button>
                <a
                  className="btn btn-outline-primary btn-sm"
                  type="button"
                  href={
                    'https://tree-scar-573.notion.site/069ece9ea1a64229b31bad7a9f776528?pvs=4'
                  }
                  target={'__blank'}>
                  <FaInfoCircle size={16} className={'sp-manual'} />
                </a>
              </div>
            </div>
            {/*<div className={'pt-1 pb-1'} style={{paddingLeft: 10, paddingRight: 10}}>*/}
            {/*    <span className={'badge text-bg-info'}>사업번호: 1</span>*/}
            {/*    &nbsp;&nbsp;*/}
            {/*    <span className={'badge text-bg-warning'}>타입: 영업대행</span>&nbsp;*/}
            {/*    <span className={'badge text-bg-warning'}>타입: 영업대행</span>*/}
            {/*    &nbsp;&nbsp;*/}
            {/*    <span className={'badge text-bg-success'}>담당자: 길동이</span>&nbsp;*/}
            {/*    &nbsp;&nbsp;*/}
            {/*    <span className={'badge text-bg-light'}>상태: 완료</span>&nbsp;*/}
            {/*    <span className={'badge text-bg-light'}>상태: 견적요청</span>&nbsp;*/}
            {/*    <span className={'badge text-bg-light'}>상태: 발주</span>&nbsp;*/}
            {/*</div>*/}
            <div className={'mb-3'}>{business}</div>
            <div className={'d-flex justify-content-center'}>
              <div className="btn-group" role="group" aria-label="First group">
                {pageButtons}
              </div>
            </div>
          </div>
          <div className={'sp-business-detail'}>
            {loading ? (
              <div
                className={'d-flex justify-content-center align-items-center'}
                style={{height: 300}}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : project && selectedProject ? (
              <BusinessDetail
                business={selectedProject}
                onChanged={this.refreshData.bind(this)}
              />
            ) : (
              <div
                className={
                  'd-flex justify-content-center align-items-center h-100'
                }>
                <img
                  src={'/assets/business.png'}
                  width={600}
                  alt={'business'}
                />
              </div>
            )}
          </div>
        </div>
        <BusinessFilter
          bizStatus={bizStatus}
          size={size}
          onChanged={this.onChangedFilter.bind(this)}
          endDate={endDate}
          startDate={startDate}
        />
        <ModalContainer
          divId={'processCancel'}
          title={'사업중단'}
          type={TypeModal.STOP}
          itemId={project}
          onStore={this.onStopBiz.bind(this)}>
          해당 사업을 중단하시겠습니까?
        </ModalContainer>
        <ModalContainer
          divId={'processDone'}
          title={'사업종료'}
          type={TypeModal.SAVE}
          itemId={project}>
          사업 종료 확인
        </ModalContainer>
        <ModalBusinessCreate onChanged={this.refreshData.bind(this)} />
      </>
    );
  }
}
