import React from 'react';
import Business, {TypeBusinessStatus} from '../type_doc';
import moment from 'moment';
import {BizMinute, TypeUserLevel} from '../types';
import {loadUserInfo} from '../pages/PageUtil';
import ModalContainer, {TypeModal} from '../pages/modal/ModalContainer';
import ReactDatePicker from 'react-datepicker';
import Api from '../data/Api';

interface Props {
  business: Business;
  bizMinutes?: BizMinute[];
  onChanged: () => void;
}

class State {
  timeMeeting: Date = new Date();
  attendee: string = '';
  content: string = '';
  selected: BizMinute = new BizMinute();
}

export default class DocBizMinute extends React.Component<Props, State> {
  state = new State();

  async onClickUploadMinute() {
    const {business} = this.props;
    const {timeMeeting, attendee, content} = this.state;
    const url = `/api/admin/biz/${business.docSeq}/minutes`;
    const formData: FormData = new FormData();
    formData.set('timeMeeting', timeMeeting.toISOString());
    formData.set('attendee', attendee);
    formData.set('minute', content);
    const result = await Api.post(url, formData);
    if (result.data.code === 0) {
      this.props.onChanged();
      return true;
    } else {
      window.alert(
        `회의록 저장중 오류가 발생했습니다. CODE(${result.data.code})`,
      );
      return false;
    }
  }

  async onClickLoadMinute(seq: number) {
    const {business} = this.props;
    const url = `/api/admin/biz/${business.docSeq}/minutes/${seq}`;
    const result = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({selected: result.data.body});
    } else {
      window.alert(`데이터 로드에 실패 했습니다. CODE(${result.data.code})`);
    }
  }

  onChangeTimeMeeting(date: Date) {
    this.setState({timeMeeting: date});
  }

  onChangeAttendee(event: any) {
    this.setState({attendee: event.target.value});
  }

  onChangeContent(event: any) {
    this.setState({content: event.target.value});
  }

  render() {
    const user = loadUserInfo();
    if (user === undefined) {
      console.error('User session not exist');
      window.location.replace('/');
      return <></>;
    }

    const {business, bizMinutes} = this.props;
    const {timeMeeting, attendee, content, selected} = this.state;
    let minuteAllow = false;
    switch (user.level) {
      case TypeUserLevel.admin:
      case TypeUserLevel.operator:
        minuteAllow = true;
        break;
      case TypeUserLevel.business:
        minuteAllow = business.userBiz === user.uid;
        break;
      case TypeUserLevel.sales:
        minuteAllow = business.userSales === user.uid;
        break;
    }
    const minuteEnable =
      business.bizStep !== TypeBusinessStatus.close &&
      business.bizStep !== TypeBusinessStatus.cancel &&
      business.bizStep !== TypeBusinessStatus.done;
    const minutes =
      !bizMinutes || bizMinutes.length === 0 ? (
        <tr>
          <td className={'text-center'} colSpan={3}>
            작성된 회의록이 없습니다.{' '}
          </td>
        </tr>
      ) : (
        bizMinutes.map((item, index) => {
          return (
            <tr key={index}>
              <td>{moment(item.timeMeeting).format('yyyy/MM/DD HH:mm')}</td>
              <td>{item.creator ? item.creator.name : item.userCreator}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.onClickLoadMinute(item.minuteSeq)}
                  data-bs-toggle="modal"
                  data-bs-target="#viewMinutes">
                  조회
                </button>
              </td>
            </tr>
          );
        })
      );
    return (
      <>
        <div className={'card mb-3'}>
          <div className={'card-header d-flex justify-content-between'}>
            <span>회의록</span>
            {minuteAllow && minuteEnable && (
              <button
                className={'btn btn-sm btn-danger'}
                data-bs-toggle="modal"
                data-bs-target="#requestBizMinute">
                회의록 작성
              </button>
            )}
          </div>
          <div className={'card-body'}>
            <table className={'table table-sm table-striped'}>
              <thead>
                <tr>
                  <th>일시</th>
                  <th>작성자</th>
                  <th style={{maxWidth: 30}}>조회</th>
                </tr>
              </thead>
              <tbody>{minutes}</tbody>
            </table>
          </div>
        </div>
        <ModalContainer
          divId={'requestBizMinute'}
          title={'회의록'}
          type={TypeModal.SAVE}
          onStore={this.onClickUploadMinute.bind(this)}>
          <div className="container-fluid">
            <div className={'row mb-3'}>
              <div className="col-8">
                <label
                  htmlFor="formControlMinuteAttendee"
                  className="form-label">
                  참석자
                </label>
                <input
                  className="form-control"
                  id="formControlMinuteAttendee"
                  placeholder={'참석자 성명 및 소속'}
                  value={attendee}
                  onChange={this.onChangeAttendee.bind(this)}
                />
              </div>
              <div className={'col-4'}>
                <div>
                  <div className={'input'}>
                    <label
                      htmlFor="formControlMinuteTime"
                      className="form-label">
                      일시
                    </label>
                    <div className={'form-control form-control-sm'}>
                      <ReactDatePicker
                        id="formControlMinuteTime"
                        className={'sp-form-hidden'}
                        showTimeSelect
                        selected={timeMeeting}
                        dateFormat={'yyyy/MM/dd HH:mm'}
                        onChange={date => {
                          if (date !== null) {
                            this.onChangeTimeMeeting(date);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label
                  htmlFor="formControlMinuteContent"
                  className="form-label">
                  회의록
                </label>
                <textarea
                  className="form-control"
                  id="formControlMinuteContent"
                  rows={20}
                  value={content}
                  onChange={this.onChangeContent.bind(this)}
                />
              </div>
            </div>
          </div>
        </ModalContainer>
        <ModalContainer
          divId={'viewMinutes'}
          title={'회의록'}
          hideCancel={true}
          type={TypeModal.CONFIRM}>
          <div className="container-fluid">
            <div className={'row mb-3'}>
              <div className="col-8">
                <label
                  htmlFor="formControlViewMinuteAttendee"
                  className="form-label">
                  참석자
                </label>
                <input
                  className="form-control"
                  id="formControlViewMinuteAttendee"
                  value={selected.attendee}
                  disabled
                />
              </div>
              <div className={'col-4'}>
                <div>
                  <div className={'input'}>
                    <label
                      htmlFor="formControlViewMinuteTime"
                      className="form-label">
                      일시
                    </label>
                    <div
                      className={'form-control form-control-sm'}
                      style={{backgroundColor: '#E9ECEF'}}>
                      {moment(selected.timeMeeting).format('yyyy/MM/DD HH:mm')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label
                  htmlFor="formControlMinuteContent"
                  className="form-label">
                  회의록
                </label>
                <textarea
                  className="form-control"
                  id="formControlMinuteContent"
                  rows={20}
                  value={selected.minutes}
                  disabled
                />
              </div>
            </div>
          </div>
        </ModalContainer>
      </>
    );
  }
}
