import React from 'react';
import {Breadcrumb} from '../BreadCrumb';
import DataTable, {Api} from 'datatables.net-dt';
import {loadUserInfo, PAGE_URLS} from '../../pages/PageUtil';
import {Category, TypeUserLevel} from '../../types';
import ModalContainer, {TypeModal} from '../../pages/modal/ModalContainer';
import * as RestApi from '../../data/Api';
import ModalCategoryCreate from '../../pages/modal/ModalProduct/ModalCategoryCreate';
import ModalCategoryUpdate from '../../pages/modal/ModalProduct/ModalCategoryUpdate';

interface Props {
  categories: Category[];
  onChanged: () => void;
}
class State {
  selected?: Category;
}
export default class CategoryDetail extends React.Component<Props, State> {
  state = new State();

  categoryTable?: Api<any>;

  onSelectTableRow(event: Event) {
    const target = event.currentTarget;
    if (target !== null) {
      const row = event.currentTarget as HTMLTableRowElement;
      if (row.classList.contains('selected')) {
        row.classList.remove('selected');
        this.setState({selected: undefined});
      } else {
        this.categoryTable
          ?.rows('.selected')
          .nodes()
          .each(row => row.classList.remove('selected'));
        row.classList.add('selected');

        const rowIdx = this.categoryTable?.row(row)[0];
        const seq = this.categoryTable?.row(rowIdx).data()[0];
        this.props.categories.filter(value => {
          if (value.seq === seq) {
            this.setState({selected: value});
          }
        });
      }
    }
  }
  componentDidMount() {
    const user = loadUserInfo();
    const access =
      user?.level === TypeUserLevel.admin ||
      user?.level === TypeUserLevel.operator;
    if (!access) {
      window.location.replace(PAGE_URLS.ACCESS_DENY);
    }
    this.initCategoryTable();
    this.loadDataTable(this.props.categories);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const pProp = JSON.stringify(prevProps.categories);
    const cProp = JSON.stringify(this.props.categories);
    if (pProp !== cProp) {
      this.loadDataTable(this.props.categories);
    }
  }

  refreshData() {
    this.props.onChanged();
  }

  initCategoryTable() {
    const element = document.getElementById('category_list');
    if (element === null) {
      throw Error('category list not exist');
    } else {
      if (this.categoryTable === undefined) {
        this.categoryTable = new DataTable(element as HTMLTableElement, {
          columnDefs: [
            {
              target: 0,
              visible: false,
              searchable: false,
            },
          ],
        });
        this.categoryTable?.on(
          'click',
          'tbody tr',
          this.onSelectTableRow.bind(this),
        );
      }
    }
  }

  loadDataTable(categories: Category[]) {
    const table = this.categoryTable;
    table?.rows().clear();
    for (const category of categories) {
      const value = category as Category;

      table?.row.add([value.seq, value.category, value.count]);
    }
    table?.draw(false);
  }

  async onClickDeleteCategory() {
    const {selected} = this.state;
    if (selected === undefined) return false;
    const url = `/api/admin/category/${selected.seq}`;
    const result = await RestApi.default.delete(url);
    if (result.data.code === 0) {
      window.alert(`카테고리 '${selected.category}'이/가 삭제되었습니다.`);
      window.location.href = PAGE_URLS.PRODUCT;
    } else {
      window.alert(`카테고리 삭제에 실패 했습니다 CODE: ${result.data.code}`);
    }
    return result.data.code === 0;
  }

  render() {
    const {categories} = this.props;
    const {selected} = this.state;
    return (
      <div className={'p-3'}>
        <Breadcrumb path={['product', 'category']} />
        <>
          <div
            className={'card-datatable-header d-flex justify-content-between'}>
            <span>{selected && `${selected.category}`}</span>
            <div className="btn-group btn-group-sm">
              <button
                type={'button'}
                data-bs-toggle="modal"
                data-bs-target="#categoryCreate"
                className={'btn btn-sm btn-outline-primary'}>
                생성
              </button>
              {selected && (
                <>
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#categoryUpdate"
                    className={'btn btn-sm btn-outline-primary'}>
                    수정
                  </button>
                  <button
                    type={'button'}
                    data-bs-toggle="modal"
                    data-bs-target="#categoryDelete"
                    className={'btn btn-sm btn-outline-primary'}>
                    삭제
                  </button>
                </>
              )}
            </div>
          </div>
          <table id={'category_list'} className={'datatable-table sp-table'}>
            <thead>
              <tr>
                <th style={{maxWidth: 100}}>SEQ</th>
                <th style={{maxWidth: 100}}>카테고리 명</th>
                <th style={{maxWidth: 100}}>제품수</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </>
        <ModalCategoryCreate
          category={categories}
          onChanged={this.refreshData.bind(this)}
        />
        {selected && (
          <ModalCategoryUpdate
            category={categories}
            selected={selected}
            onChanged={this.refreshData.bind(this)}
          />
        )}
        <ModalContainer
          divId={'categoryDelete'}
          title={'카테고리 삭제'}
          type={TypeModal.DELETE}
          onStore={this.onClickDeleteCategory.bind(this)}>
          <>
            카테고리를 삭제하시겠습니까?
            <br />
            {`${selected?.count} 개의 제품이 '전체' 카테고리로 이동됩니다.`}
          </>
        </ModalContainer>
      </div>
    );
  }
}
