import Api, {ApiResp} from "./Api";
import {Company} from "../types";

export default class ApiCompany {
    static async getCompanyList(): Promise<ApiResp<Company[]>> {
        const url = `/api/admin/company`;
        const res = await Api.get(url);
        return res.data;
    }
    static async createCompany(company: Company): Promise<ApiResp<boolean>> {
        const formData = new FormData();
        formData.set('name', company.name);
        formData.set('tel', company.tel);
        formData.set('corpRegNum', company.corpRegNum);
        formData.set('email', company.email);
        formData.set('location1', company.location1);
        formData.set('location2', company.location2);
        formData.set('homepage', company.homepage);
        if (company.manager.length > 0){
            for (const uid of company.manager) {
                formData.append('managerUid', uid);
            }
        }
        const url = `/api/admin/company`;
        const res = await Api.post(url, formData);
        return res.data;
    }
    static async editCompany(company: Company, ): Promise<ApiResp<boolean>> {
        const formData = new FormData();
        formData.set('name', company.name);
        formData.set('tel', company.tel);
        formData.set('corpRegNum', company.corpRegNum);
        formData.set('description', company.description);
        formData.set('email', company.email);
        formData.set('location1', company.location1);
        formData.set('location2', company.location2);
        formData.set('homepage', company.homepage);
        if (company.manager.length > 0){
            for (const uid of company.manager) {
                formData.append('managerUid', uid);
            }
        }
        const url = `/api/admin/company/${company.seq}`;
        const res = await Api.put(url, formData);
        return res.data;
    }
    static async deleteCompany(companySeq: number): Promise<ApiResp<boolean>> {
        const url = `/api/admin/company/${companySeq}`;
        const res = await Api.delete(url);
        return res.data;
    }
}
