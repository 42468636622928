import Business, {FixedDocument, ProductTuple} from '../../../type_doc';
import React from 'react';
import ModalContainer, {TypeModal} from '../ModalContainer';
import {FaMinusCircle} from 'react-icons/fa';
import InputSearchProduct from '../../../comp/InputSearchProduct';
import {numberWithCommas} from '../../../data/DataUtils';

interface Props {
  business?: Business;
  onChanged: () => void;
}
class State {
  products: ProductTuple[] = [new ProductTuple()];
  note = '';
  receiver = '';
  reference = '';
  commissionRate = 10;
}

export default class ModalBusinessProcessQuotation extends React.Component<
  Props,
  State
> {
  state = new State();

  fillProducts() {
    const {business} = this.props;
    const pad = 2;
    const len = business?.requirements.productList.length || 0;
    const productList: ProductTuple[] = new Array<ProductTuple>(len + pad);
    business?.requirements.productList.forEach((value, index) => {
      productList[index] = value;
    });
    for (let i = len; i < len + pad; i++) {
      productList[i] = new ProductTuple();
    }
    this.setState({products: productList});
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const np = JSON.stringify(this.props.business);
    const pp = JSON.stringify(prevProps.business);
    if (np !== pp) {
      this.fillProducts();
    }
  }
  componentDidMount() {
    this.fillProducts();
  }

  onChangeProduct(seq: number, product: (ProductTuple | string)[]) {
    const {products} = this.state;
    const p = product[0];
    if (p === undefined) {
      products[seq] = new ProductTuple();
    } else if (typeof p === 'string') {
      products[seq].title = p;
    } else {
      products[seq] = p;
    }
    this.setState({products: products});
  }
  onChangeAmount(seq: number, data: number) {
    const {products} = this.state;
    products[seq].amount = data;
    products[seq].price = data * products[seq].unitPrice;
    this.setState({products: products});
  }
  onChangeUnitPrice(seq: number, data: number) {
    const {products} = this.state;
    products[seq].unitPrice = data;
    products[seq].price = data * products[seq].amount;
    this.setState({products: products});
  }

  async onEditQuotation() {
    const {products, note, reference, receiver, commissionRate} = this.state;
    if (this.props.business === undefined) return false;

    const data: ProductTuple[] = [];
    products.forEach(product => {
      if (product.title !== '' && product.amount > 0) {
        data.push(product);
      }
    });
    const result = await this.props.business.approveQuotation(
      data,
      note,
      receiver,
      reference,
      commissionRate,
    );
    if (result) {
      this.props.onChanged();
    }
    return result !== undefined;
  }

  async onRejectQuotation() {
    if (this.props.business === undefined) return false;

    const result = await this.props.business.rejectQuotation();
    if (result) {
      this.props.onChanged();
    }
    return result !== undefined;
  }
  cancelEdit() {
    this.setState(
      {
        products: this.props.business?.requirements.productList || [],
        note: '',
        receiver: '',
        reference: '',
      },
      () => {
        this.fillProducts();
      },
    );
  }
  onChangeNote(event: any) {
    this.setState({note: event.target.value});
  }

  onChangeReceiver(event: any) {
    this.setState({receiver: event.target.value});
  }

  onChangeReference(event: any) {
    this.setState({reference: event.target.value});
  }

  render() {
    const {business} = this.props;
    const {products, note, receiver, reference} = this.state;

    const input = products.map((value, index) => {
      return (
        <div key={index} className={'row'}>
          <div className={'col'}>
            <div className={'input-group input-group-sm'}>
              <InputSearchProduct
                onChange={product => {
                  this.onChangeProduct(index, product);
                }}
                single
                value={value}
              />
              <input
                type="number"
                style={{maxWidth: 50}}
                className={'form-control form-control-sm text-end'}
                disabled
                onChange={event => {
                  this.onChangeAmount(index, event.target.valueAsNumber);
                }}
                value={value.amount}
              />
              <input
                style={{maxWidth: 100}}
                type="text"
                className={'form-control form-control-sm text-end'}
                onChange={event => {
                  this.onChangeUnitPrice(index, event.target.valueAsNumber);
                }}
                disabled
                value={numberWithCommas(value.unitPrice)}
              />
              <input
                style={{maxWidth: 100}}
                disabled
                type="text"
                className={'form-control form-control-sm text-end'}
                value={numberWithCommas(value.price)}
              />
              <div
                className={'btn btn-sm mb-1'}
                onClick={() => {
                  if (products.length !== 1) {
                    const item = products;
                    item.splice(index, 1);
                    this.setState({products: item});
                  }
                }}>
                <FaMinusCircle />
              </div>
            </div>
          </div>
        </div>
      );
    });
    const ns =
      JSON.stringify(products) !==
      JSON.stringify(this.props.business?.requirements);
    const biz = Object.assign(new Business(), business);

    const totalSales = biz.getRequirements().getSum();
    const totalPurchase = biz.getRequirements().getPurchaseSum();
    const totalIncome = totalSales - totalPurchase;
    const totalCommission = (totalIncome * this.state.commissionRate) / 100;
    const totalRevenue = totalIncome - totalCommission;

    return (
      <ModalContainer
        divId={'processQuotation'}
        title={'견적 승인/거절'}
        onStore={this.onEditQuotation.bind(this)}
        onReject={this.onRejectQuotation.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        type={TypeModal.APPROVAL}>
        <div>
          <div className={'container-fluid'}>
            <div className={'row'}>
              <div className={'col'}>
                <div className={'input-group input-group-sm'}>
                  <div
                    className={'form-control form-control-sm'}
                    style={{borderStyle: 'none'}}>
                    물품
                  </div>
                  <div
                    className={'form-control form-control-sm text-end'}
                    style={{maxWidth: 50, borderStyle: 'none'}}>
                    수량
                  </div>
                  <div
                    className={'form-control form-control-sm text-end'}
                    style={{maxWidth: 100, borderStyle: 'none'}}>
                    단가
                  </div>
                  <div
                    className={'form-control form-control-sm text-end'}
                    style={{maxWidth: 100, borderStyle: 'none'}}>
                    합계
                  </div>
                  <div className={'btn btn-sm mb-1'}>
                    <FaMinusCircle color={'white'} />
                  </div>
                </div>
              </div>
            </div>
            <div className={'mb-1'} style={{flex: 1}}>
              {input}
            </div>
            {/*<div className={'input-group input-group-sm mb-3'}>*/}
            {/*  <div className={'form-control form-control-sm'}>*/}
            {/*    <strong>합계 (VAT 별도)</strong>*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    className={'form-control form-control-sm text-end'}*/}
            {/*    style={{maxWidth: 50, backgroundColor: '#E9ECEF'}}>*/}
            {/*    -*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    className={'form-control form-control-sm text-end'}*/}
            {/*    style={{maxWidth: 100, backgroundColor: '#E9ECEF'}}>*/}
            {/*    -*/}
            {/*  </div>*/}
            {/*  <input*/}
            {/*    className={'form-control form-control-sm text-end'}*/}
            {/*    disabled*/}
            {/*    style={{maxWidth: 100}}*/}
            {/*    value={numberWithCommas(biz.getRequirements().getSum())}*/}
            {/*  />*/}
            {/*  <div className={'btn btn-sm mb-1'}>*/}
            {/*    <FaMinusCircle color={'white'} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={'input-group input-group-sm mb-3'}>*/}
            {/*  <div className={'form-control form-control-sm'}>*/}
            {/*    <strong>매입가</strong>*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    className={'form-control form-control-sm text-end'}*/}
            {/*    style={{maxWidth: 50, backgroundColor: '#E9ECEF'}}>*/}
            {/*    -*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    className={'form-control form-control-sm text-end'}*/}
            {/*    style={{maxWidth: 100, backgroundColor: '#E9ECEF'}}>*/}
            {/*    -*/}
            {/*  </div>*/}
            {/*  <input*/}
            {/*    className={'form-control form-control-sm text-end'}*/}
            {/*    disabled*/}
            {/*    style={{maxWidth: 100}}*/}
            {/*    value={numberWithCommas(biz.getRequirements().getPurchaseSum())}*/}
            {/*  />*/}
            {/*  <div className={'btn btn-sm mb-1'}>*/}
            {/*    <FaMinusCircle color={'white'} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={'input-group input-group-sm mb-3'}>
              <div className={'form-control form-control-sm'}>
                <strong>영업사원 수수료 (%)</strong>
              </div>
              <input
                type={'number'}
                max={100}
                min={0}
                onChange={event => {
                  this.setState({commissionRate: event.target.valueAsNumber});
                }}
                className={'form-control form-control-sm text-end'}
                style={{maxWidth: 250}}
                value={this.state.commissionRate}
              />
              <div className={'btn btn-sm mb-1'}>
                <FaMinusCircle color={'white'} />
              </div>
            </div>

            <div className={'row mb-3'}>
              <div className={'col-3'}>
                매출액 ₩{numberWithCommas(totalSales)}
              </div>
              <div className={'col-3'}>
                매입액 ₩{numberWithCommas(totalPurchase)}
              </div>
              <div className={'col-3'}>
                순이익 ₩{numberWithCommas(totalRevenue)}
              </div>
              <div className={'col-3'}>
                수수료 ₩{numberWithCommas(totalCommission)}
              </div>
            </div>
            <hr />

            <div className={'row'}>
              <div className={'col-6'}>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label">
                    견적 수신
                  </label>
                  <input
                    type={'text'}
                    className="form-control form-control-sm"
                    value={receiver}
                    onChange={this.onChangeReceiver.bind(this)}
                  />
                </div>
              </div>
              <div className={'col-6'}>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label">
                    견적 참조
                  </label>
                  <input
                    type={'text'}
                    className="form-control form-control-sm"
                    value={reference}
                    onChange={this.onChangeReference.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label">
                추가메모
              </label>
              <textarea
                className="form-control form-control-sm"
                id="exampleFormControlTextarea1"
                rows={2}
                value={note}
                onChange={this.onChangeNote.bind(this)}></textarea>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
