import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import Business, {TypeBusiness} from '../../type_doc';

interface Props {
  onChanged: () => void;
}
class State {
  abstract: string = '';
  type: TypeBusiness = TypeBusiness.delivery;
}

export default class ModalBusinessCreate extends React.Component<Props, State> {
  state = new State();

  onChangeAbstract(event: any) {
    this.setState({abstract: event.target.value});
  }
  onChangeType(event: any) {
    this.setState({type: event.target.value});
  }

  async onCreateBusiness() {
    if (!this.validData()) return false;
    const {abstract, type} = this.state;
    const result = await Business.createBusiness(abstract, type);
    if (result !== null) {
      this.props.onChanged();
    }
    return result !== null;
  }
  cancelCreate() {
    this.setState({abstract: '', type: TypeBusiness.delivery});
  }

  validData() {
    const {abstract} = this.state;
    // 입력내용 누락 확인
    if (abstract === '') {
      alert('사업개요를 입력해주세요.');
      return false;
    }
    return true;
  }

  render() {
    const {abstract, type} = this.state;
    return (
      <ModalContainer
        divId={'newBusiness'}
        title={'신규 사업'}
        onStore={this.onCreateBusiness.bind(this)}
        onCancel={this.cancelCreate.bind(this)}
        type={TypeModal.CREATE}>
        <div>
          <div className={'container-fluid'}>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>
                <div>타입</div>
              </div>
              <div className={'col-sm-8'}>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={this.onChangeType.bind(this)}
                  value={type}>
                  <option value={TypeBusiness.agency}>영업 대행</option>
                  <option value={TypeBusiness.delivery}>제품 중계</option>
                </select>
              </div>
            </div>
            <div className={'mb-2 row'}>
              <div className={'col-sm-4 col-form-label'}>사업개요</div>
              <div className={'col-sm-8'}>
                <textarea
                  className={'form-control'}
                  onChange={this.onChangeAbstract.bind(this)}
                  rows={5}
                  value={abstract || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
