import React from 'react';
import TopBar from '../comp/TopBar';
import ChartBiz from '../comp/dashboard/ChartBiz';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DashboardActivityCard from '../comp/profile/DashboardActivityCard';
import {Activity, ChartUnit, TypeUserLevel} from '../types';
import ApiSettings from '../data/ApiSettings';
import {IoReloadCircleOutline} from 'react-icons/io5';
import ChartSalesMan from '../comp/dashboard/ChartSalesMan';
import ChartBalance from '../comp/dashboard/ChartBalance';
import ChartProduct from '../comp/dashboard/ChartProduct';
import ChartSales from '../comp/dashboard/ChartSales';
import {AxiosResponse} from 'axios';
import Api, {ApiResp} from '../data/Api';
import {numberWithCommas} from '../data/DataUtils';
import {loadUserInfo} from './PageUtil';

interface CardProps {
  title: string;
  text?: string;
  data?: any;
  children?: any;
}
class CardText extends React.Component<CardProps, any> {
  render() {
    return (
      <div className={'card'}>
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-body-secondary">
            {this.props.title}
          </h6>
          <h3 className="card-title text-end">{this.props.text}</h3>
        </div>
      </div>
    );
  }
}

class CardChart extends React.Component<CardProps, any> {
  render() {
    return (
      <div className={'card'}>
        <div className={'card-header'}>{this.props.title}</div>
        <div className="card-body" style={{height: 300}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
const initialDate = new Date();
initialDate.setDate(initialDate.getDate() - 30);
class State {
  startDate = initialDate;
  endDate: Date = new Date();
  activities: Activity[] = [];
  salesTotal: ChartUnit = new ChartUnit();
  salesMonth: ChartUnit = new ChartUnit();
  bizTotal: ChartUnit = new ChartUnit();
  bizPending: ChartUnit = new ChartUnit();
}
export default class PageDashboard extends React.Component<any, State> {
  state = new State();

  async _loadSalesTotal() {
    const url = '/api/admin/dashboard/sales-total';
    const result: AxiosResponse<ApiResp<ChartUnit>> = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({salesTotal: result.data.body});
    }
  }
  async _loadSalesMonth() {
    const url = '/api/admin/dashboard/sales-month';
    const result: AxiosResponse<ApiResp<ChartUnit>> = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({salesMonth: result.data.body});
    }
  }
  async _loadBizTotal() {
    const url = '/api/admin/dashboard/biz-total';
    const result: AxiosResponse<ApiResp<ChartUnit>> = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({bizTotal: result.data.body});
    }
  }
  async _loadBizPending() {
    const url = '/api/admin/dashboard/biz-pending';
    const result: AxiosResponse<ApiResp<ChartUnit>> = await Api.get(url);
    if (result.data.code === 0) {
      this.setState({bizPending: result.data.body});
    }
  }
  async loadActs() {
    const acts = await ApiSettings.activityRecent();
    this.setState({activities: acts});
  }

  loadData() {
    this.loadActs().catch(e => console.error('FAIL to load acts'));
    this._loadBizTotal().catch(e => console.error('FAIL to load biz total'));
    this._loadBizPending().catch(e =>
      console.error('FAIL to load biz pending'),
    );
    this._loadSalesTotal().catch(e =>
      console.error('FAIL to load sales total'),
    );
    this._loadSalesMonth().catch(e =>
      console.error('FAIL to load sales month'),
    );
  }

  componentDidMount() {
    this.loadData();
  }

  onSelectDuration(ago: number) {
    const now = new Date();
    const before = new Date();
    before.setDate(now.getDate() - ago);
    this.setState({startDate: before, endDate: now});
  }
  onSelectStartDate(date: Date) {
    this.setState({startDate: date});
  }
  onSelectEndDate(date: Date) {
    this.setState({endDate: date});
  }
  onClickReload() {
    this.loadData();
  }

  render() {
    const user = loadUserInfo();
    if (user === undefined) {
      window.location.href = '/';
      return;
    }
    const showChart =
      user.level === TypeUserLevel.admin ||
      user.level === TypeUserLevel.operator;
    const {startDate, endDate, salesTotal, salesMonth, bizTotal, bizPending} =
      this.state;
    const activities = this.state.activities.map((value, index) => {
      return <DashboardActivityCard key={index} activity={value} />;
    });

    return (
      <>
        <TopBar menu={'dashboard'} />
        <div
          className={'container-fluid bg-light'}
          style={{paddingTop: 52, overflowY: 'scroll', height: '100%'}}>
          <div className={'row'}>
            <div className={'col-9 pt-3'}>
              <div className={'container-fluid'}>
                <div className={'row mb-3'}>
                  <div className={'col-3'}>
                    <CardText
                      text={numberWithCommas(bizTotal.v1)}
                      title={'전체 사업'}
                    />
                  </div>
                  <div className={'col-3'}>
                    <CardText
                      text={numberWithCommas(bizPending.v1)}
                      title={'대기중 업무'}
                    />
                  </div>
                  <div className={'col-3'}>
                    <CardText
                      text={numberWithCommas(salesTotal.v1 / 1000)}
                      title={'전체 매출(천원)'}
                    />
                  </div>
                  <div className={'col-3'}>
                    <CardText
                      text={numberWithCommas(salesMonth.v1 / 1000)}
                      title={'이번달 매출(천원)'}
                    />
                  </div>
                </div>
                <div className={'row mb-3'}>
                  <div className={'col d-flex justify-content-end gap-2'}>
                    <div className="btn-group btn-group-sm">
                      <button
                        type={'button'}
                        className={'btn btn-sm btn-outline-primary'}
                        onClick={() => this.onClickReload()}>
                        <IoReloadCircleOutline />
                      </button>
                      <button
                        type={'button'}
                        className={'btn btn-sm btn-outline-primary'}
                        onClick={() => this.onSelectDuration(9999)}>
                        전체
                      </button>
                      <button
                        type={'button'}
                        className={'btn btn-sm btn-outline-primary'}
                        onClick={() => this.onSelectDuration(30)}>
                        최근 1개월
                      </button>
                      <button
                        type={'button'}
                        className={'btn btn-sm btn-outline-primary'}
                        onClick={() => this.onSelectDuration(90)}>
                        최근 3개월
                      </button>
                    </div>
                    <div className={'d-flex gap-2'}>
                      <div className={'input-group'}>
                        <span className={'input-group-text'}>시작일</span>
                        <div className={'form-control'}>
                          <ReactDatePicker
                            className={'sp-form-hidden'}
                            selected={startDate}
                            onChange={date => {
                              if (date !== null) {
                                this.onSelectStartDate(date);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className={'input-group'}>
                        <span className={'input-group-text'}>종료일</span>
                        <div className={'form-control'}>
                          <ReactDatePicker
                            className={'sp-form-hidden'}
                            selected={endDate}
                            onChange={date => {
                              if (date !== null) {
                                this.onSelectEndDate(date);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'row'}>
                  <div className={'col-6 mb-3'}>
                    <CardChart text={'300'} title={'신규 사업'}>
                      <ChartBiz />
                    </CardChart>
                  </div>
                  <div className={'col-6 mb-3'}>
                    <CardChart text={'300'} title={'매출현황'}>
                      <ChartSales />
                    </CardChart>
                  </div>
                  {showChart && (
                    <div className={'col-6 mb-3'}>
                      <CardChart text={'300'} title={'제품 등록'}>
                        <ChartProduct />
                      </CardChart>
                    </div>
                  )}
                  <div className={'col-6 mb-3'}>
                    <CardChart text={'300'} title={'월별 매입. 매출'}>
                      <ChartBalance />
                    </CardChart>
                  </div>
                  {showChart && (
                    <div className={'col-6 mb-3'}>
                      <CardChart text={'300'} title={'영업사원 증가'}>
                        <ChartSalesMan />
                      </CardChart>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={'col-3 pt-3'}>
              <h3>활동이력</h3>
              {activities}
            </div>
          </div>
        </div>
      </>
    );
  }
}
