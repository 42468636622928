import React from 'react';
import {Product} from '../type_doc';
import DataTable, {Api} from 'datatables.net-dt';
import TopBar from '../comp/TopBar';
import {Breadcrumb} from '../comp/BreadCrumb';
import SideBarProduct from '../comp/SideBarProduct';
import {renderToString} from 'react-dom/server';
import {FaFile} from 'react-icons/fa6';
import {generateShortProductCode, PAGE_URLS} from './PageUtil';
import {Category, UrlParams} from '../types';
import {createBrowserHistory} from 'history';
import moment from 'moment/moment';
import * as RestApi from '../data/Api';
import {AxiosResponse} from 'axios';
import {ApiResp} from '../data/Api';
import {IoStar, IoStarOutline} from 'react-icons/io5';
import {numberWithCommas} from '../data/DataUtils';

class State {
  params: UrlParams = new UrlParams();
  selected?: Product;
  selectedProductNo?: string;
  selectedCategory?: Category;
  products: Product[] = [];
  categories: Category[] = [];
  loading: boolean = false;
}
export default class PageProductListView extends React.Component<any, any> {
  state = new State();

  productTable?: Api<any>;

  componentDidMount() {
    this.setState({loading: true});

    const param = window.location.search;
    const urlParam = UrlParams.parse(param);
    this.setState({params: urlParam});

    this._initProductTable();
    this.loadData().catch(e => {
      window.alert(`데이터 로드 중 문제가 발생했습니다.`);
      return;
    });
  }

  // searchProduct() {
  //   const {params} = this.state;
  //   const productSeq = params.get('product_seq');
  //   if (productSeq === undefined) return;
  //   if (Array.isArray(productSeq)) {
  //     window.alert(`시스템 오류: 제품 중복`);
  //     return;
  //   }
  //   const searchKey = 'P' + String(parseInt(productSeq)).padStart(5, '0');
  //   this.productTable?.search(searchKey);
  // }
  async loadData() {
    this.setState({loading: true});
    const {selectedCategory} = this.state;

    const param =
      selectedCategory !== undefined
        ? `?categorySeq=${selectedCategory.seq}`
        : '';
    const resultData: AxiosResponse<ApiResp<Product[]>> =
      await RestApi.default.get('/api/admin/product/-/list/all' + param);
    const resultBookmark: AxiosResponse<ApiResp<Product[]>> =
      await RestApi.default.get('/api/admin/bookmark/product/list?size=999999');
    const resultCategory: AxiosResponse<ApiResp<Category[]>> =
      await RestApi.default.get('/api/admin/category');
    if (resultData.data.code !== 0) {
      window.alert(
        `데이터 로드에 실패했습니다 (CODE: ${resultData.data.code})`,
      );
    } else if (resultBookmark.data.code !== 0) {
      window.alert(
        `데이터 로드에 실패 했습니다 (CODE: ${resultBookmark.data.code})`,
      );
    } else if (resultCategory.data.code !== 0) {
      window.alert(
        `데이터 로드에 실패했습니다 (CODE: ${resultCategory.data.code})`,
      );
    } else {
      this.setState(
        {products: resultData.data.body, categories: resultCategory.data.body},
        () => {
          this._fillDataToTable(resultData.data.body, resultBookmark.data.body);
        },
      );
    }
  }
  _initProductTable() {
    const element = document.getElementById('product-list');
    if (element === null) {
      window.alert(`제품 목록을 출력할 수 없습니다.`);
      return;
    } else {
      if (this.productTable === undefined) {
        this.productTable = new DataTable(element as HTMLTableElement, {});
        this.productTable?.on(
          'click',
          'button',
          this.onClickBookmark.bind(this),
        );
        this.productTable?.on('search.dt', () => {
          this.onClearSearch($('.dataTables_filter input').val());
        });
      }
    }
  }
  onClearSearch(searchKey: any) {
    const history = createBrowserHistory();
    history.replace({
      pathname: PAGE_URLS.PRODUCT_LIST,
    });
  }

  onClickBookmark(event: any) {
    this.onBookmark(event.currentTarget.id).catch(e => console.error(e));
  }

  async onBookmark(seq: number) {
    const url = `/api/admin/bookmark/product?productSeq=${seq}`;
    try {
      let result: AxiosResponse<ApiResp<boolean>> | undefined;
      let bookmarked = false;
      result = await RestApi.default.get(url);
      if (result?.data.code === 0) {
        bookmarked = result.data.body;
      } else {
        console.error('Fail to check bookmark : ' + result?.data.code);
        window.alert(
          `제품정보 확인에 실패 했습니다 CODE(${result?.data.code})`,
        );
        return;
      }
      if (!bookmarked) {
        result = await RestApi.default.post(url, {});
      } else {
        result = await RestApi.default.delete(url);
      }
      if (result?.data.code === 0) {
        await this.loadData();
      } else {
        console.error('Fail to update bookmark : ' + result?.data.code);
        window.alert(
          `즐겨찾기 업데이트에 실패 했습니다: CODE(${result?.data.code})`,
        );
      }
    } catch (err) {
      console.error('Fail to update bookmark : ' + JSON.stringify(err));
      window.alert('즐겨찾기 업데이트에 실패 했습니다.');
    }
  }

  _fillDataToTable(products: Product[], bookmark: Product[]) {
    const bmArray = new Array<number>();
    for (const p of bookmark) {
      bmArray.push(p.seq);
    }
    const table = this.productTable;
    table?.rows().clear();
    for (const value of products) {
      const b = bmArray.includes(value.seq);
      const bookmark = (
        <button id={`${value.seq}`} className={'btn'}>
          {b ? <IoStar size={18} /> : <IoStarOutline size={18} />}
        </button>
      );
      const productNo = generateShortProductCode(value.seq);
      const image =
        value.titleImage.length > 0 ? (
          <img
            style={{maxHeight: 35}}
            src={value.titleImage[0].path}
            alt={'...'}
          />
        ) : (
          <></>
        );
      const desc = (
        <div className={'sp-table-desc'}>{value.titleDesc || ''}</div>
      );
      const files = value.infoGuide.concat(value.contentPdf || []);
      const attachFile = files.map((value, index) => {
        return (
          <a key={index} href={value.path} target={'__blank'}>
            <span className={'badge text-bg-primary'}>
              <div className={'sp-table-filename'}>{value.name + ' '} </div>
              <FaFile size={18} />
            </span>
          </a>
        );
      });
      const fileIcons = renderToString(<div>{attachFile}</div>);
      const priceUnit = renderToString(
        <div style={{textAlign: 'right'}}>
          {numberWithCommas(value.priceUnit)}
        </div>,
      );

      table?.row.add([
        renderToString(bookmark),
        productNo,
        value.title,
        renderToString(image),
        renderToString(desc),
        priceUnit,
        moment(value.timeCreate).format('MM/DD'),
        value.infoSupply || '',
        fileIcons,
      ]);
    }
    table?.draw(false);
    this.setState({loading: false});
  }

  onSelectCategory(category?: Category) {
    this.setState({selectedCategory: category}, () => this.loadData());
  }

  render() {
    const {selected, selectedProductNo, categories, loading} = this.state;
    return (
      <div>
        <TopBar menu={'product-list'} />
        <>
          <SideBarProduct
            categories={categories}
            onSelectMenu={this.onSelectCategory.bind(this)}
          />
          <div className={'sp-right'}>
            <Breadcrumb path={['product', 'all']} />
            <>
              <div
                className={
                  'card-datatable-header d-flex justify-content-between'
                }>
                <span>
                  {selected && `${selected.title} (${selectedProductNo})`}
                </span>
                <div className="btn-group btn-group-sm">&nbsp;</div>
              </div>
              <table
                id={'product-list'}
                className={'datatable-table sp-table'}
                style={{position: 'static'}}>
                <thead>
                  <tr>
                    <th style={{maxWidth: 50}}>북마크</th>
                    <th style={{maxWidth: 80}}>제품번호</th>
                    <th>이름</th>
                    <th>이미지</th>
                    <th style={{width: 300}}>상세내용</th>
                    <th style={{maxWidth: 80}}>소비자가(원)</th>
                    <th>등록일</th>
                    <th>제조사</th>
                    <th>첨부파일</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </>
          </div>
          {loading && (
            <div
              className="loading-backdrop"
              onClick={() => {
                return;
              }}>
              <div className="loading">
                <div className="loading-obj"></div>
                <div className="loading-obj"></div>
                <div className="loading-obj"></div>
                <div className="loading-obj"></div>
                <div className="loading-obj"></div>
                <div className="loading-obj"></div>
                <div className="loading-obj"></div>
                <div className="loading-obj"></div>
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}
