import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {ManagerIcon} from '../../comp/Manager';
import ApiUser from '../../data/ApiUser';
import {Company, User} from '../../types';
import {PAGE_URLS} from '../PageUtil';

interface Props {
  companySeq?: number;
}
class State {
  salesList: User[] = [];
}
export default class ModalUserCompanySalesList extends React.Component<
  Props,
  any
> {
  state = new State();
  async loadData() {
    const result = await ApiUser.getUserSalesList(this.props.companySeq);
    this.setState({salesList: result.body});
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any,
  ) {
    const pSeq = prevProps.companySeq;
    const nSeq = this.props.companySeq;
    if (nSeq !== undefined && nSeq !== pSeq) {
      this.loadData().catch(e => console.error(JSON.stringify(e)));
    }
  }
  cancelCreate() {
    this.setState({salesList: []});
  }

  render() {
    const {salesList} = this.state;
    const userlist = salesList.map((value, index) => {
      return (
        <div key={index} className={'d-flex'}>
          <div className={'me-2'}>
            <ManagerIcon user={value} />
          </div>
          <div>
            {`${value.name}(${value.uid})`}
            <div className={'d-flex'}>
              {value.mail}, {value.tel}
            </div>
            <div>{value.description}</div>
          </div>
        </div>
      );
    });
    return (
      <ModalContainer
        divId={'companySales'}
        title={'영업사원 목록'}
        onCancel={this.cancelCreate.bind(this)}
        type={TypeModal.EDIT}>
        <div>{userlist.length > 0 ? userlist : '영업사원이 없습니다.'}</div>
      </ModalContainer>
    );
  }
}
