import React from 'react';
import {IoLinkOutline} from 'react-icons/io5';
import {numberWithCommas} from '../../data/DataUtils';
import {Refund, TypeRefundStatus} from '../../types';
import moment from 'moment';

interface Props {
  refund: Refund;
}
export default class RefundHistoryCard extends React.Component<Props, any> {
  render() {
    const {refund} = this.props;
    const bizList = refund.bizRel.map((value, index) => {
      return (
        <a
          key={index}
          href={`/business?business_selected=${value}`}
          target={'_blank'}
          rel={'noreferrer'}>
          <span className={'badge text-bg-secondary me-2'}>
            사업-{value} <IoLinkOutline />
          </span>
        </a>
      );
    });
    const step = () => {
      switch (refund.stepApply) {
        case TypeRefundStatus.APPLY:
          return <span className={'badge text-bg-secondary'}>요청</span>;
        case TypeRefundStatus.APPROVE:
          return <span className={'badge text-bg-primary'}>승인</span>;
        case TypeRefundStatus.REJECT:
          return <span className={'badge text-bg-danger'}>거절</span>;
        case TypeRefundStatus.na:
          return <></>;
      }
    };
    return (
      <ol className="list-group mb-2">
        <li className="list-group-item">
          <div className={'d-flex justify-content-between mb-1'}>
            <div className="ms-2 me-auto">
              <div className="fw-bold">
                <span>
                  환급요청일:{' '}
                  {moment(new Date(refund.timeCreate)).format('MM/DD HH:mm')}{' '}
                  &nbsp;
                  {step()}
                </span>
              </div>
            </div>
            <span>
              완료일:{' '}
              <span className={'fw-bold'}>
                {moment(new Date(refund.timeUpdate)).format('MM/DD HH:mm')}
              </span>
            </span>
          </div>

          <div className={'row'}>
            <div className={'col-6 d-flex justify-content-between'}>
              <ul>
                <li>
                  사업 : &nbsp;
                  <span>{bizList}</span>
                </li>
                <li>
                  환급 포인트 :{' '}
                  {numberWithCommas(refund.refundSupply + refund.refundTax)}
                </li>
                <li>지급액 :{numberWithCommas(refund.refundSupply)}</li>
              </ul>
            </div>

            <div className={'col-6'}>
              <ul>
                <li>
                  지급 계좌: {refund.bank}, {refund.account}
                </li>
                <li>
                  첨부파일 : &nbsp;
                  <span>
                    {this.props.refund.docIdentify && (
                      <a
                        href={`/api/admin/refund/request/${this.props.refund.refundUid}/attachment?path=${this.props.refund.docIdentify}`}
                        target={'_blank'}
                        rel={'noreferrer'}>
                        <span className={'badge text-bg-secondary me-2'}>
                          본인확인정보 <IoLinkOutline />
                        </span>
                      </a>
                    )}
                    {this.props.refund.docBank && (
                      <a
                        href={`/api/admin/refund/request/${this.props.refund.refundUid}/attachment?path=${this.props.refund.docBank}`}
                        target={'_blank'}
                        rel={'noreferrer'}>
                        <span className={'badge text-bg-secondary me-2'}>
                          통장사본 <IoLinkOutline />
                        </span>
                      </a>
                    )}
                    {this.props.refund.docResultTransfer && (
                      <a
                        href={`/api/admin/refund/request/${this.props.refund.refundUid}/attachment?path=${this.props.refund.docResultTransfer}`}
                        target={'_blank'}
                        rel={'noreferrer'}>
                        <span className={'badge text-bg-secondary me-2'}>
                          세금납부서 <IoLinkOutline />
                        </span>
                      </a>
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className={'row mt-1'}>
            <div className={'col'}>
              <span>{refund.refundNote}</span>
            </div>
          </div>
        </li>
      </ol>
    );
  }
}
