import * as React from 'react';
import {FaChartBar, FaGlobeAsia, FaList, FaUserFriends} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {loadUserInfo, PAGE_URLS, removeUserInfo} from '../pages/PageUtil';
import ApiSign from '../data/ApiSign';
import {TypeUserLevel} from '../types';
import {ManagerIcon} from './Manager';
import {FaListCheck, FaMoneyBill, FaStar} from 'react-icons/fa6';

const color = {
  admin: '#0a53be',
  operator: '#143b2a',
  business: '#ff6262',
  sales: '#830000',
  na: '#000',
  purchase: '#000',
};

interface Props {
  menu:
    | 'user'
    | 'product'
    | 'product-list'
    | 'product-bookmark'
    | 'business'
    | 'dashboard'
    | 'refund'
    | 'na';
}
export default class TopBar extends React.Component<Props, any> {
  state = {
    now: new Date(),
  };

  timer?: NodeJS.Timer;

  componentDidMount() {
    this.startTimer();
  }

  startTimer() {
    if (this.timer === undefined) {
      this.timer = setInterval(() => {
        this.setState({now: new Date()});
      }, 300);
    }
  }
  signOut() {
    ApiSign.signOut()
      .then(result => {
        removeUserInfo();
        window.location.href = '/';
      })
      .catch(e => console.error(e));
  }

  render() {
    const user = loadUserInfo();
    if (user === undefined) {
      window.location.href = '/';
      return;
    }
    const act = (act: string) => {
      return act === this.props.menu ? 'button-active' : '';
    };

    let level = undefined;
    switch (user?.level) {
      case TypeUserLevel.admin:
        level = '관리자';
        break;
      case TypeUserLevel.operator:
        level = '운용자';
        break;
      case TypeUserLevel.sales:
        level = '영업사원';
        break;
      case TypeUserLevel.business:
        level = '사업담당자';
        break;
      default:
        window.alert('잘못된 로그인 정보입니다.');
        this.signOut();
        break;
    }

    return (
      <div
        className={'sp-top fixed-top container-fluid'}
        style={{backgroundColor: color[user.level]}}>
        <a href={'/'} className={'brand'}>
          NEOSALES {`(@${user.uid}) `}
        </a>
        <div>
          {user.level !== TypeUserLevel.sales &&
            user.level !== TypeUserLevel.business && (
              <Link
                to={PAGE_URLS.USER_MANAGER}
                className={'btn button ' + act('user')}>
                <FaUserFriends size={25} />
              </Link>
            )}
          <Link
            to={PAGE_URLS.BUSINESS}
            className={'btn button ' + act('business')}>
            <FaGlobeAsia size={25} />
          </Link>
          {user.level !== TypeUserLevel.sales &&
            user.level !== TypeUserLevel.business && (
              <Link
                to={PAGE_URLS.PRODUCT}
                className={'btn button ' + act('product')}>
                <FaList size={25} />
              </Link>
            )}
          <Link
            to={PAGE_URLS.PRODUCT_LIST}
            className={'btn button ' + act('product-list')}>
            <FaListCheck size={25} />
          </Link>
          {user.level === TypeUserLevel.sales && (
            <Link
              to={PAGE_URLS.PRODUCT_BOOKMARK}
              className={'btn button ' + act('product-bookmark')}>
              <FaStar size={25} />
            </Link>
          )}
          <Link
            to={PAGE_URLS.DASHBOARD}
            className={'btn button ' + act('dashboard')}>
            <FaChartBar size={25} />
          </Link>
          {(user.level === TypeUserLevel.admin ||
            user.level === TypeUserLevel.operator) && (
            <Link
              to={PAGE_URLS.REFUND_LIST}
              className={'btn button ' + act('refund')}>
              <FaMoneyBill size={25} />
            </Link>
          )}
        </div>
        <div className={'clock gap-2'}>
          <div className={'text-end'} style={{marginTop: 10, width: 425}}>
            {this.state.now.toString()}
          </div>
          <div className="btn-group">
            <button
              className="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <ManagerIcon user={user} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to={PAGE_URLS.PROFILE_EDIT}>
                  개인정보 설정
                </Link>
              </li>
              {user.level === TypeUserLevel.sales && (
                <li>
                  <Link className="dropdown-item" to={PAGE_URLS.POINT}>
                    수수료 환급 신청
                  </Link>
                </li>
              )}
              {user.level === TypeUserLevel.sales && (
                <li>
                  <Link className="dropdown-item" to={PAGE_URLS.POINT_HISTORY}>
                    환급 이력
                  </Link>
                </li>
              )}
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={this.signOut.bind(this)}>
                  로그아웃
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
