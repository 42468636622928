import React from 'react';
import ModalContainer, {TypeModal} from './ModalContainer';
import {UserInvite} from '../../types';
import ApiUser from "../../data/ApiUser";

interface Props {
    user?: UserInvite;
    onChanged: () => void;
}

export default class ModalUserInviteEdit extends React.Component<Props, any> {
    async onCancelInvite() {
        const {user} = this.props;
        if (user?.mail === undefined) return false;
        const result = await ApiUser.deleteInviteUser(user?.mail);
        if (result.code === 0) {
            this.props.onChanged();
        }
        window.alert(result.code === 0 ? '초대를 취소했습니다.' : '문제가 발생했습니다.')
        return result.code === 0;
    }
    async onResendInvite(){
        const {user} = this.props;
        if (user?.mail === undefined) return false;
        const result = await ApiUser.resendInvite(user?.mail);
        if (result.code === 0) {
            this.props.onChanged();
        }
        window.alert(result.code === 0 ? '초대를 재전송했습니다.' : '문제가 발생했습니다.')
        return result.code === 0;
    }

    render() {
        const {user} = this.props;
        return (
            <ModalContainer
                divId={'inviteEdit'}
                title={'가입 대기중 사용자 수정'}
                onStore={this.onResendInvite.bind(this)}
                onReject={this.onCancelInvite.bind(this)}
                type={TypeModal.INVITE_EDIT}>
                <div>
                    <div className={'container-fluid'}>
                        <div className={'mb-2 row'}>
                            <div className={'col-sm-4 col-form-label'}>
                                이메일 주소
                            </div>
                            <div className={'col-sm-8'}>
                                <input
                                    disabled
                                    type={'text'}
                                    className={'form-control'}
                                    value={user?.mail}/>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        )
    }
}
